import { Autocomplete, TextField } from "@mui/material";
import { Maybe } from "../../../Maybe";

interface SohleGapTextProps {
    setSohle: (form: Maybe<Array<string>>) => void;
    sohle: Maybe<Array<string>>;
}

const SohleGapText = (props: SohleGapTextProps) => {
    const FORM_OPTIONS = ['horizontal', 'konvex', 'konkav', 'wellig']

    return (
        <Autocomplete
            multiple
            freeSolo
            size={'small'}
            value={props.sohle ?? []}
            onChange={(_, value) => props.setSohle(value)}
            options={FORM_OPTIONS}
            renderInput={(params) => <TextField {...params} label={'Sohle'} />}
            sx={{ width: 700 }}
        />
    );
}

export { SohleGapText };
