import { TextField } from 'formik-mui';
import { Field } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { UpdateRowProp } from '../../../common/DataGrid/addOrUpdateRow';
import { Keyword } from '../../../entities/Keyword/Keyword';
import { ServicesContext } from '../../../graphql/contexts/ServicesProvider';
import EditDialogForm from '../../EditDialogForm/EditDialogForm';
import { v4 as uuidv4 } from 'uuid';
import { KEYWORD_VALIDATION_SCHEMA } from './validationSchema';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

interface KeywordFormProps extends UpdateRowProp<Keyword> {
    keywordId?: string;
    handleClose: () => void;
    entityName: { singular: string; plural: string; };
}

function KeywordForm(props: KeywordFormProps) {
    const { keywordsService } = useContext(ServicesContext).services;

    const [initialValue, setInitialValue] = useState<Keyword>({
        id: uuidv4(),
        name: '',
        isCsvExport: false
    });

    // load data for entity if entity exists
    useEffect(() => {
        if (!!props.keywordId) {
            keywordsService.get(props.keywordId).then(keyword => setInitialValue(keyword));
        }
    }, [keywordsService, props.keywordId])

    return (
        <EditDialogForm<Keyword>
            id={props.keywordId}
            entityName={props.entityName}
            handleClose={props.handleClose}
            service={keywordsService}
            updateRow={props.updateRow}
            initialValues={initialValue}
            validationSchema={KEYWORD_VALIDATION_SCHEMA}
        >
            {({ values, errors, touched, isSubmitting }) =>
                <>
                    <Field
                        component={TextField}
                        label='Name *'
                        name='name'
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                        fullWidth
                        autoFocus
                        InputLabelProps={{ shrink: !!values.name }}
                    />
                    <Button
                        startIcon={<AddIcon />}
                        color='secondary'
                        variant='contained'
                        type='submit'
                    >
                        {props.entityName.singular} speichern
                    </Button>
                </>
            }
        </EditDialogForm>
    );
}

export default KeywordForm;
