import { gql } from '@apollo/client';

export const GET_ALL_DIGITS = gql`
    query GetAllDigits {
        allDigits {
            id
            activityId
            number
            comment
        }
    }
`;

export const GET_DIGIT = gql`
    query GetDigit($id: UUID!) {
        digit(id: $id) {
            id
            activityId
            number
            comment
        }
    }
`;

export const FILTER_DIGITS_BY_ACTIVITY_ID = gql`
    query FilterDigitsByActivityId($activityId: UUID!) {
        filterDigitsByActivityId(activityId: $activityId) {
            id
            activityId
            dateTime
            number
            comment
        }
    }
`;

export const UPDATE_DIGIT = gql`
    mutation UpdateDigit($digit: DigitInput!) {
        updateDigit(digit: $digit) {
            id
            activityId
            number
            comment
        }
    }
`;

export const ADD_DIGIT = gql`
    mutation AddDigit($digit: DigitInput!) {
        addDigit(digit: $digit) {
            id
            activityId
            number
            comment
        }
    }
`;

export const REMOVE_DIGITS = gql`
    mutation RemoveDigits($ids: [UUID!]!) {
        removeDigits(ids: $ids)
    }
`;
