import { gql } from '@apollo/client';

export const GET_ALL_ACTIVITIES = gql`
    query GetAllActivities {
        allActivities {
            id
            dateTime
            number
            operation
            comment
        }
    }
`;

export const GET_ACTIVITY = gql`
    query GetActivity($id: UUID!) {
        activity(id: $id) {
            id
            dateTime
            number
            operation
            comment
        }
    }
`;

export const UPDATE_ACTIVITY = gql`
    mutation UpdateActivity($activity: ActivityInput!) {
        updateActivity(activity: $activity) {
            id
            dateTime
            number
            operation
            comment
        }
    }
`;

export const ADD_ACTIVITY = gql`
    mutation AddActivity($activity: ActivityInput!) {
        addActivity(activity: $activity) {
            id
            dateTime
            number
            operation
            comment
        }
    }
`;

export const REMOVE_ACTIVITIES = gql`
    mutation RemoveActivities($ids: [UUID!]!) {
        removeActivities(ids: $ids)
    }
`;
