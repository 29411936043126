import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { NUMBER_FIELD_XS_WIDTH } from "../../constants";
import { Maybe } from "../../../Maybe";

interface BaubefundVerfaerbungStoerungProps {
    addToSatzteile: (values: ReadonlyArray<{ index: number; satzteil: Maybe<string>; }>) => void;
    satzteile: ReadonlyArray<Maybe<string>>;
    resetAfterIndex: (index: number) => void;
}

const BaubefundVerfaerbungStoerung = (props: BaubefundVerfaerbungStoerungProps) => {
    const [autocomplete, setAutocomplete] = useState<Maybe<string>>(props.satzteile[5]);

    useEffect(() => {
        switch (autocomplete) {
            case 'AB':
                props.addToSatzteile([{ index: 6, satzteil: null }, { index: 7, satzteil: 'Planum' }, { index: 8, satzteil: null }, { index: 9, satzteil: 'definiert.' }]);
                break;
            case 'Profil Stelle':
                props.addToSatzteile([{ index: 6, satzteil: null }, { index: 7, satzteil: null }, { index: 8, satzteil: 'in AB' }, { index: 9, satzteil: null }]);
                break;
            case 'Freitext':
                props.addToSatzteile([{ index: 6, satzteil: null }, { index: 7, satzteil: null }, { index: 8, satzteil: null }, { index: 9, satzteil: null }]);
                break;
        }
    }, [autocomplete]);

    return (
        <>
            <span>{props.satzteile[4]}</span>
            <Autocomplete
                size={'small'}
                options={['AB', 'Profil Stelle', 'Freitext']}
                renderInput={(params) => <TextField {...params} label={''} />}
                value={autocomplete || props.satzteile[5]}
                onChange={(_, value) => {
                    setAutocomplete(value);
                    props.addToSatzteile([{ index: 5, satzteil: value }])
                }}
                sx={{ width: 200, opacity: autocomplete === 'Freitext' ? .5 : 1 }}
            />

            {autocomplete === 'AB' &&
                <>
                    <TextField
                        size={'small'} multiline
                        sx={{ width: NUMBER_FIELD_XS_WIDTH }}
                        value={props.satzteile[6]}
                        onChange={e => props.addToSatzteile([{ index: 6, satzteil: e.target.value }])}
                    />
                    <span>Planum</span>
                    <TextField
                        size={'small'}
                        multiline
                        sx={{ width: 200 }}
                        value={props.satzteile[8]}
                        onChange={e => props.addToSatzteile([{ index: 8, satzteil: e.target.value }])}
                    />
                    <span>{props.satzteile[9]}</span>
                </>}

            {autocomplete === 'Profil Stelle' &&
                <>
                    <TextField
                        size={'small'}
                        multiline
                        sx={{ width: NUMBER_FIELD_XS_WIDTH }}
                        value={props.satzteile[6]}
                        onChange={e => props.addToSatzteile([{ index: 6, satzteil: e.target.value }])}
                    />
                    <TextField
                        size={'small'}
                        multiline
                        sx={{ width: 200 }}
                        value={props.satzteile[7]}
                        onChange={e => props.addToSatzteile([{ index: 7, satzteil: e.target.value }])}
                    />
                    <span>in AB</span>
                    <TextField
                        size={'small'}
                        multiline
                        sx={{ width: NUMBER_FIELD_XS_WIDTH }}
                        value={props.satzteile[9]}
                        onChange={e => props.addToSatzteile([{ index: 9, satzteil: e.target.value }])}
                    />
                </>}

            {autocomplete === 'Freitext' &&
                <TextField
                    size={'small'}
                    multiline
                    sx={{ width: 600 }}
                    value={props.satzteile[6]}
                    onChange={e => props.addToSatzteile([{ index: 6, satzteil: e.target.value }])}
                />}
        </>
    );
}

export { BaubefundVerfaerbungStoerung };