import { GridColDef, GridSelectionModel, GridSortModel } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { GERMAN } from '../../../common/lang/locales';
import { StyledDataGrid } from './DataGrid.style';

interface DataGridProps<T> {
    onSelectionModelChange: (newSelection: GridSelectionModel) => void;
    sortModel: GridSortModel;
    columns: GridColDef[];
    rows?: T[];
    rowHeight?: number;
}

function DataGrid<T>(props: DataGridProps<T>) {
    // props
    const { onSelectionModelChange, sortModel, columns, rows } = props;

    // state
    const [sort, setSort] = useState<GridSortModel>(sortModel);

    const rowHeightProp = props.rowHeight !== undefined ? { rowHeight: props.rowHeight} : undefined;

    return (
        <StyledDataGrid
            localeText={GERMAN}
            checkboxSelection={true}
            disableSelectionOnClick={true}
            onSelectionModelChange={onSelectionModelChange}
            sortModel={sort}
            onSortModelChange={(model) => setSort(model)}
            columns={columns}
            loading={!rows}
            rows={rows || []}
            {...rowHeightProp}
        />
    );
}

export default DataGrid;
