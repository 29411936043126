import { DatePicker, LocalizationProvider } from '@mui/lab';
import { GridRenderCellParams } from '@mui/x-data-grid';
import React, { useContext } from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import deDE from 'date-fns/locale/de';
import { updateEntities } from '../../../common/DataGrid/addOrUpdateRow';
import { OfflinePosition, Position } from '../../../entities/Position/Position';
import { ServicesContext } from '../../../graphql/contexts/ServicesProvider';
import { DigitalPositionCardContext } from '../contexts/DigitalPositionCardProvider';
import { StyledDateTimeInput } from './DateTimeInput.style';

function DateTimeInput(params: GridRenderCellParams) {
    // contexts
    const { positionsService } = useContext(ServicesContext).services;
    const { positions, setPositions } = useContext(DigitalPositionCardContext);

    const position = params.row as OfflinePosition;

    const onChange = (value: Date | null) => positionsService
        .update({
            ...position,
            dateTime: value ?? new Date()
        })
        .then(newPosition => !!newPosition && setPositions(updateEntities(
            { ...newPosition, offlineAvailable: position.offlineAvailable },
            positions))
        );

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={deDE}>
            <DatePicker
                mask={'__.__.____'}
                renderInput={(props) => <StyledDateTimeInput {...props} />}
                onChange={onChange}
                value={position.dateTime}
            />
        </LocalizationProvider>
    );
}

export default DateTimeInput;
