import { ActivitiesRepository } from './ActivitiesRepository/ActivitiesRepository';
import { CachedActivitiesRepository } from './ActivitiesRepository/CachedActivitiesRepository';
import { CachedDigitsRepository } from './DigitsRepository/CachedDigitsRepository';
import { DigitsRepository } from './DigitsRepository/DigitsRepository';
import DocumentaRhenaniaDB from './DocumentaRhenaniaDB';
import { CachedFreeTextsRepository } from './FreeTextsRepository/CachedFreeTextsRepository';
import { FreeTextsRepository } from './FreeTextsRepository/FreeTextsRepository';
import { InternetConnectionRepository } from './InternetConnectionRepository/InternetConnectionRepository';
import { CachedKeywordsRepository } from './KeywordsRepository/CachedKeywordsRepository';
import { KeywordsRepository } from './KeywordsRepository/KeywordsRepository';
import { CachedPositionsRepository } from './PositionsRepository/CachedPositionsRepository';
import { PositionsRepository } from './PositionsRepository/PositionsRepository';

export class RepositoriesContainer {
    public readonly activitiesRepo: ActivitiesRepository;
    public readonly cachedActivitiesRepo: CachedActivitiesRepository;
    public readonly digitsRepo: DigitsRepository;
    public readonly cachedDigitsRepo: CachedDigitsRepository;
    public readonly positionsRepo: PositionsRepository;
    public readonly cachedPositionsRepo: CachedPositionsRepository;
    public readonly keywordsRepo: KeywordsRepository;
    public readonly cachedKeywordsRepo: CachedKeywordsRepository;
    public readonly freeTextsRepo: FreeTextsRepository;
    public readonly cachedFreeTextsRepo: CachedFreeTextsRepository;
    public readonly internetConnectionRepo: InternetConnectionRepository;

    constructor() {
        const database = new DocumentaRhenaniaDB();

        const positionsRepo = new PositionsRepository(database);
        const digitsRepo = new DigitsRepository(database, positionsRepo);

        // Offline-available Entities
        this.internetConnectionRepo = new InternetConnectionRepository(database);
        this.keywordsRepo = new KeywordsRepository(database);
        this.freeTextsRepo = new FreeTextsRepository(database);
        this.activitiesRepo = new ActivitiesRepository(database, digitsRepo);
        this.digitsRepo = digitsRepo;
        this.positionsRepo = positionsRepo;

        // Cached Entities
        this.cachedActivitiesRepo = new CachedActivitiesRepository(database);
        this.cachedDigitsRepo = new CachedDigitsRepository(database);
        this.cachedPositionsRepo = new CachedPositionsRepository(database);
        this.cachedKeywordsRepo = new CachedKeywordsRepository(database);
        this.cachedFreeTextsRepo = new CachedFreeTextsRepository(database);
    }
}
