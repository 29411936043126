import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { FreeText } from '../../entities/FreeText/FreeText';
import { BaseService, GraphQLService } from '../BaseService';
import { Service } from '../Service';
import {
    ADD_FREETEXT,
    FILTER_FREETEXTS_BY_KEYWORD_ID,
    GET_ALL_FREETEXTS, GET_ALL_FREETEXTS_WHICH_HAS_KEYWORDID,
    GET_FREETEXT,
    REMOVE_FREETEXTS,
    UPDATE_FREETEXT
} from './FreeTextsSchemas';

export class FreeTextsService implements Service<FreeText> {
    constructor(private readonly client: ApolloClient<NormalizedCacheObject>) {
        this.baseClient = new BaseService(
            GET_FREETEXT,
            GET_ALL_FREETEXTS,
            ADD_FREETEXT,
            UPDATE_FREETEXT,
            REMOVE_FREETEXTS,
            client
        );
    }

    private readonly baseClient: GraphQLService<FreeText>;

    public getAll = () =>
        this.baseClient.getAll('allFreeTexts');

    public get = (id: string) =>
        this.baseClient.get(id, 'freeText');

    public filterBy = (keywordId: string) =>
        this.client
            .query<{ filterFreeTextsByKeywordId: FreeText[] }, { keywordId: string }>(
                { query: FILTER_FREETEXTS_BY_KEYWORD_ID, variables: { keywordId } }
            )
            .then(result => result.data.filterFreeTextsByKeywordId);

    public getAllKeywordFreeTexts = () =>
        this.client
            .query<{ allKeywordFreeTexts: FreeText[] }>(
                { query: GET_ALL_FREETEXTS_WHICH_HAS_KEYWORDID }
            )
            .then(result => result.data.allKeywordFreeTexts);

    public add = (freeText: FreeText) =>
        this.baseClient.add(freeText, 'addFreeText', { freeText })
            .then(freeText => freeText ?? null);

    public update = (freeText: FreeText) =>
        // TODO: Warum 2x FreeText rein???
        this.baseClient.update(freeText, 'updateFreeText', { freeText })
            .then(freeText => freeText ?? null);

    public remove = (ids: string[]) =>
        this.baseClient.remove(ids, 'removeFreeTexts');
}
