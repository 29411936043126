import BasePage from "../BasePage/BasePage";
import React from "react";
import {Stellenkarte} from "../../components/Stellenkarte/Stellenkarte";

const Digit2 = () => {
    return (
        <BasePage title={'Positionen'}>
            <Stellenkarte />
        </BasePage>
    );
};

export {Digit2};
