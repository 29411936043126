import React from 'react';
import GlobalSnackbarProvider from '../components/Snackbar/contexts/GlobalSnackbarProvider';
import ServicesProvider from '../graphql/contexts/ServicesProvider';
import InternetConnectionProvider from '../repository/contexts/InternetConnectionProvider';
import RepositoriesProvider from '../repository/contexts/RepositoriesProvider';

interface ContextProviderProps {
    children: JSX.Element | JSX.Element[];
}

function ContextProvider(props: ContextProviderProps) {
    return (
        <ServicesProvider>
            <RepositoriesProvider>
                <InternetConnectionProvider>
                    <GlobalSnackbarProvider>
                        {props.children}
                    </GlobalSnackbarProvider>
                </InternetConnectionProvider>
            </RepositoriesProvider>
        </ServicesProvider>
    );
}

export default ContextProvider;
