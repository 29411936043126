import { Autocomplete, DialogContent, TextField } from "@mui/material";
import { Maybe } from "../../../Maybe";
import { NUMBER_FIELD_XS_WIDTH } from "../../constants";
import { StyledPositionDialog } from "../../PositionDialog.style";
import { PositionDialogButtons } from "../../PositionDialogButtons";
import { useMemo } from "react";

type FotoOption = 'Planum' | 'Profil' | 'Übersicht' | 'Situation' | 'Detail' | 'Freitext';
const FOTO_OPTIONS: ReadonlyArray<FotoOption> = ['Planum', 'Profil', 'Übersicht', 'Situation', 'Detail', 'Freitext'];

interface PositionDialog {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    foto: ReadonlyArray<Maybe<string>>;
    setFoto: (foto: ReadonlyArray<Maybe<string>>) => void;
}

const FotoPositionDialog = (props: PositionDialog) => {
    const onDialogClose = () => {
        props.setIsOpen(false);
    };

    return (
        <StyledPositionDialog open={props.isOpen} onClose={onDialogClose}>
            <DialogContent>
                <Autocomplete
                    size={'small'}
                    options={FOTO_OPTIONS}
                    renderInput={(params) => <TextField {...params} label={'Foto von'} />}
                    onChange={(_, value) => props.setFoto([value])}
                    value={props.foto[0]}
                    sx={{ width: 220, opacity: props.foto[0] === 'Freitext' ? .5 : 1 }}
                />
                {FormInputRenderer(props.foto, props.setFoto)}
            </DialogContent>
            <PositionDialogButtons setIsOpen={props.setIsOpen} />
        </StyledPositionDialog>
    );
};

const FormInputRenderer = (foto: ReadonlyArray<Maybe<string>>, setFoto: (foto: ReadonlyArray<Maybe<string>>) => void) => {
    type autocompleteOption = 'mit' | 'unter' | '/' | 'Freitext';
    const autocompleteOptions: ReadonlyArray<autocompleteOption> = ['mit', 'unter', '/', 'Freitext'];
    const autocompleteJsx = (index: number) => <Autocomplete
        size={'small'}
        options={autocompleteOptions}
        renderInput={(params) => <TextField {...params} label={''} />}
        value={foto[index]}
        onChange={(_, value) => value !== '/' ? setFoto([...foto.slice(0, index), value, ...foto.slice(index + 1)]) : setFoto([...foto.slice(0, index), value])}
        sx={{ width: 200, opacity: foto[index] === 'Freitext' ? .5 : 1 }}
    />;

    switch (foto[0]) {
        case 'Planum':
            return (
                <>
                    <TextField
                        size={'small'}
                        sx={{ width: NUMBER_FIELD_XS_WIDTH }}
                        label={''}
                        value={foto[1]}
                        onChange={e => setFoto([foto[0], e.target.value, ...foto.slice(2)])}
                    />
                    <span>gg.</span>
                    <TextField
                        size={'small'}
                        sx={{ width: 200 }}
                        label={''}
                        value={foto[2]}
                        onChange={e => setFoto([foto[0], foto[1], e.target.value, ...foto.slice(3)])}
                    />

                    {autocompleteJsx(3)}

                    {foto[3] === 'mit' && <TextField size={'small'} sx={{ width: 200 }} label={''} value={foto[4]} onChange={e => setFoto([...foto.slice(0, 4), e.target.value, ...foto.slice(5)])} />}
                    {foto[3] === 'unter' && <TextField size={'small'} sx={{ width: 200 }} label={''} value={foto[4]} onChange={e => setFoto([...foto.slice(0, 4), e.target.value, ...foto.slice(5)])} />}
                    {foto[3] === 'Freitext' && <TextField size={'small'} sx={{ width: 200 }} label={''} value={foto[4]} onChange={e => setFoto([...foto.slice(0, 4), e.target.value, ...foto.slice(5)])} />}
                </>
            );
        case 'Profil':
            return (
                <>
                    <TextField
                        size={'small'}
                        sx={{ width: NUMBER_FIELD_XS_WIDTH }}
                        label={''}
                        value={foto[1]}
                        onChange={e => setFoto([foto[0], e.target.value, ...foto.slice(2)])}
                    />
                    <span>gg.</span>
                    <TextField
                        size={'small'}
                        sx={{ width: 200 }}
                        label={''}
                        value={foto[2]}
                        onChange={e => setFoto([foto[0], foto[1], e.target.value, ...foto.slice(3)])}
                    />

                    {autocompleteJsx(3)}

                    {foto[3] === 'mit' && <TextField size={'small'} sx={{ width: 200 }} label={''} value={foto[4]} onChange={e => setFoto([...foto.slice(0, 4), e.target.value, ...foto.slice(5)])} />}
                    {foto[3] === 'unter' && <TextField size={'small'} sx={{ width: 200 }} label={''} value={foto[4]} onChange={e => setFoto([...foto.slice(0, 4), e.target.value, ...foto.slice(5)])} />}
                    {foto[3] === 'Freitext' && <TextField size={'small'} sx={{ width: 200 }} label={''} value={foto[4]} onChange={e => setFoto([...foto.slice(0, 4), e.target.value, ...foto.slice(5)])} />}
                </>
            );
        case 'Übersicht':
            return (
                <>
                    <TextField
                        size={'small'}
                        sx={{ width: NUMBER_FIELD_XS_WIDTH }}
                        label={''}
                        value={foto[1]}
                        onChange={e => setFoto([foto[0], e.target.value, ...foto.slice(2)])}
                    />
                    <span>gg.</span>
                    <TextField
                        size={'small'}
                        sx={{ width: 200 }}
                        label={''}
                        value={foto[2]}
                        onChange={e => setFoto([foto[0], foto[1], e.target.value, ...foto.slice(3)])}
                    />

                    {autocompleteJsx(3)}

                    {foto[3] === 'mit' && <TextField size={'small'} sx={{ width: 200 }} label={''} value={foto[4]} onChange={e => setFoto([...foto.slice(0, 4), e.target.value, ...foto.slice(5)])} />}
                    {foto[3] === 'Freitext' && <TextField size={'small'} sx={{ width: 200 }} label={''} value={foto[4]} onChange={e => setFoto([...foto.slice(0, 4), e.target.value, ...foto.slice(5)])} />}
                </>
            );
        case 'Situation':
            return (
                <>
                    <TextField
                        size={'small'}
                        sx={{ width: NUMBER_FIELD_XS_WIDTH }}
                        label={''}
                        value={foto[1]}
                        onChange={e => setFoto([foto[0], e.target.value, ...foto.slice(2)])}
                    />
                    <span>gg.</span>
                    <TextField
                        size={'small'}
                        sx={{ width: 200 }}
                        label={''}
                        value={foto[2]}
                        onChange={e => setFoto([foto[0], foto[1], e.target.value, ...foto.slice(3)])}
                    />

                    {autocompleteJsx(3)}

                    {foto[3] === 'mit' && <TextField size={'small'} sx={{ width: 200 }} label={''} value={foto[4]} onChange={e => setFoto([...foto.slice(0, 4), e.target.value, ...foto.slice(5)])} />}
                    {foto[3] === 'Freitext' && <TextField size={'small'} sx={{ width: 200 }} label={''} value={foto[4]} onChange={e => setFoto([...foto.slice(0, 4), e.target.value, ...foto.slice(5)])} />}
                </>
            );
        case 'Detail':
            return (
                <>
                    <span>gg.</span>
                    <TextField
                        size={'small'}
                        sx={{ width: 200 }}
                        label={''}
                        value={foto[1]}
                        onChange={e => setFoto([foto[0], e.target.value, ...foto.slice(2)])}
                    />
                    {autocompleteJsx(2)}
                    {foto[2] === 'mit' && <TextField size={'small'} sx={{ width: 200 }} label={''} value={foto[3]} onChange={e => setFoto([...foto.slice(0, 3), e.target.value, ...foto.slice(4)])} />}
                    {foto[2] === 'unter' && <TextField size={'small'} sx={{ width: 200 }} label={''} value={foto[3]} onChange={e => setFoto([...foto.slice(0, 3), e.target.value, ...foto.slice(4)])} />}
                    {foto[2] === 'Freitext' && <TextField size={'small'} sx={{ width: 200 }} label={''} value={foto[3]} onChange={e => setFoto([...foto.slice(0, 3), e.target.value, ...foto.slice(4)])} />}
                </>
            );
        case 'Freitext':
            return (
                <>
                    <TextField
                        size={'small'}
                        sx={{ width: 700 }}
                        label={''}
                        value={foto[1]}
                        onChange={e => setFoto([foto[0], e.target.value, ...foto.slice(2)])}
                    />
                </>
            );
    }
}

export { FotoPositionDialog };

