import styled from 'styled-components';

export const StyledEditDialogForm = styled.form`
  > .MuiFormControl-root {
    margin-bottom: 15px;
    width: 100%;
  }

  > .MuiButton-root {
    margin-bottom: 20px;
  }
`;
