import React, {useState} from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AppRoutes from './App.routes';
import SideNavigation from './components/BottomNavigation/BottomNavigation';
import Header from './components/Header/Header';
import SyncDialog from './components/SyncDialog/SyncDialog';
import Login from './pages/Login/Login';

function App() {
    const [isNavigationOpen, setNavigationOpen] = useState(false);

    return (
        <div className="app">
            <Switch>
                <Route exact path='/login' render={() => <Login />} />
                <Route path='*'>
                    <SideNavigation isNavigationOpen={isNavigationOpen} setIsNavigationOpen={setNavigationOpen} />
                    <Header isNavigationOpen={isNavigationOpen} setNavigationOpen={setNavigationOpen} />
                    <AppRoutes />
                    <Route exact path='/' render={() => <Redirect to={'/login'} />} />
                    <SyncDialog />
                </Route>
            </Switch>
        </div>
    );
}

export default App;
