import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useLiveQuery } from 'dexie-react-hooks';
import { useContext, useState } from 'react';
import { Optional } from '../../../common/Optional/Optional';
import { OfflineActivity } from '../../../entities/Activity/Activity';
import { ServicesContext } from '../../../graphql/contexts/ServicesProvider';
import { DownloadToRepositoryService } from '../../../graphql/DownloadToRepository/DownloadToRepositoryService';
import { RepositoriesContext } from '../../../repository/contexts/RepositoriesProvider';
import { RemoveFromRepository } from '../../../repository/RemoveFromRepository/RemoveFromRepository';
import { GlobalSnackbarContext } from '../../Snackbar/contexts/GlobalSnackbarProvider';
import { StyledOfflineCheckbox } from './OfflineCheckbox.style';

interface OfflineCheckboxProps {
    activityId: string;
}

function OfflineCheckbox(props: OfflineCheckboxProps) {
    // contexts
    const { activitiesRepo, digitsRepo, positionsRepo } = useContext(RepositoriesContext);
    const { activitiesService, digitsService, positionsService } = useContext(ServicesContext).services;
    const snackbar = useContext(GlobalSnackbarContext);

    // services
    const downloadToRepositoryService = new DownloadToRepositoryService(
        activitiesService, activitiesRepo,
        digitsService, digitsRepo,
        positionsService, positionsRepo
    );
    const removeFromRepositoryService = new RemoveFromRepository(activitiesRepo);

    // states
    const activity = new Optional(useLiveQuery(
        () => activitiesRepo.get(props.activityId) as Promise<OfflineActivity>,
        [props.activityId]
    ));
    const [dialogOpen, setDialogOpen] = useState(false);

    // functions
    const downloadActivity = () => {
        downloadToRepositoryService
            .downloadAndSaveActivity(props.activityId)
            .then(() => snackbar.setSnackbar({
                open: true,
                message: 'Aktivität wurde erfolgreich heruntergeladen.',
                severity: 'info'
            }));
    };

    const removeActivity = () => {
        removeFromRepositoryService
            .removeActivity(props.activityId)
            .then(() => snackbar.setSnackbar({
                open: true,
                message: 'Die Aktivität ist nicht mehr offline verfügbar.',
                severity: 'info'
            }))
    }

    const manageLocalActivity = (offlineAvailable: boolean) => {
        if (offlineAvailable) {
            removeActivity();
        } else {
            downloadActivity();
        }
    }

    // variables
    const offlineAvailable = activity.match({
        some: activity => activity.offlineAvailable,
        none: () => false
    });

    return (
        <>
            <StyledOfflineCheckbox
                onChange={() => setDialogOpen(true)}
                checked={offlineAvailable}
            />
            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
            >
                <DialogTitle>
                    {
                        offlineAvailable
                            ? 'Offlineverfügbarkeit deaktivieren'
                            : 'Offlineverfügbarkeit aktivieren'
                    }
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {
                            offlineAvailable
                                ? 'Wenn Du diese Einstellung deaktivierst, dann wird die Aktivität nicht mehr offline' +
                                ' auf dem Endgerät verfügbar sein. Solltest Du also keine aktive Internetverbindung ' +
                                'haben, dann wird diese Aktivität nicht mehr in der Anwendung sichtbar sein.'
                                : 'Wenn Du diese Einstellung aktivierst, dann wird die Aktivität weiterhin auf dem' +
                                ' Endgerät verfügbar sein, auch wenn du keine aktive Internetverbindung hast.'
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setDialogOpen(false)}
                        color='primary'
                    >
                        Disagree
                    </Button>
                    <Button
                        onClick={() => {
                            setDialogOpen(false);
                            manageLocalActivity(offlineAvailable);
                        }}
                        color='secondary'
                        autoFocus
                    >
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export { OfflineCheckbox };
