import { StyledPositionDialog } from "../../PositionDialog.style";
import { Autocomplete, Button, DialogActions, DialogContent, TextField } from "@mui/material";
import React, { useState } from "react";
import { StandardPosition } from "../../../StandardPositionen/StandardPosition";
import { Maybe } from "../../../Maybe";
import { PositionDialogButtons } from "../../PositionDialogButtons";
import { DefaultGapText } from "../../ErstePosition/Dialog/DefaultGapText";
import { GeoprofilGapText } from "../../ErstePosition/Dialog/GeoprofilGapText";

type Planumsart = 'Baggerplanung' | 'Kellenplanum' | 'Schaufelplanum' | 'Putzen Profil' | 'Freitext';
const PLANUMSART_OPTIONS: ReadonlyArray<Planumsart> = ['Freitext', 'Baggerplanung', 'Kellenplanum', 'Schaufelplanum', 'Putzen Profil'];

interface PositionDialog {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    setAuswahl: (auswahl: Maybe<string>) => void;
    auswahl: Maybe<string>;
}

const ZweitePositionDialog = (props: PositionDialog) => {
    const [autocomplete, setAutocomplete] = useState<Maybe<string>>(PLANUMSART_OPTIONS.includes(props.auswahl as Planumsart) ? props.auswahl : 'Freitext');
    const onDialogClose = () => {
        props.setIsOpen(false);
        window.setTimeout(() => props.setAuswahl(undefined), 500);
    };

    return (
        <StyledPositionDialog open={props.isOpen} onClose={onDialogClose}>
            <DialogContent>
                <Autocomplete
                    size={'small'}
                    options={PLANUMSART_OPTIONS}
                    value={autocomplete}
                    renderInput={(params) => <TextField {...params} label={''} />}
                    onChange={(_, value) => {
                        setAutocomplete(value);
                        value === 'Freitext' ? props.setAuswahl(undefined) : props.setAuswahl(value);
                    }}
                    sx={{ width: 220, opacity: props.auswahl === 'Freitext' ? .5 : 1 }}
                />

                {autocomplete === 'Freitext' && <TextField value={props.auswahl} onChange={e => props.setAuswahl(e.target.value)} size={'small'} multiline sx={{ width: 600 }} />}
            </DialogContent>
            <DialogActions>
                <Button
                    color={'primary'}
                    variant={'text'}
                    size={'small'}
                    onClick={() => props.setIsOpen(false)}
                >
                    Schließen
                </Button>
                <Button
                    color={'secondary'}
                    variant={'contained'}
                    size={'small'}
                    onClick={() => props.setIsOpen(false)}
                >
                    Speichern
                </Button>
            </DialogActions>
        </StyledPositionDialog>
    );
};

export { ZweitePositionDialog };
