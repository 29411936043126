import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ActivitiesService } from './Activities/ActivitiesService';
import { CheckConnectionService } from './CheckConnection/CheckConnectionService';
import { DigitsService } from './Digits/DigitsService';
import { FreeTextsService } from './FreeTexts/FreeTextsService';
import { KeywordsService } from './Keywords/KeywordsService';
import { LoginService } from './Login/LoginService';
import { PositionsService } from './Positions/PositionsService';

export class ServicesContainer {
    public readonly activitiesService;
    public readonly digitsService;
    public readonly freeTextsService;
    public readonly keywordsService;
    public readonly positionsService;
    public readonly checkConnectionService;
    public readonly loginService;

    constructor(client: ApolloClient<NormalizedCacheObject>) {
        this.activitiesService = new ActivitiesService(client);
        this.digitsService = new DigitsService(client);
        this.freeTextsService = new FreeTextsService(client);
        this.keywordsService = new KeywordsService(client);
        this.positionsService = new PositionsService(client);

        this.checkConnectionService = new CheckConnectionService(client);

        this.loginService = new LoginService(client);
    }
}
