import { styled } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

export const StyledDataGrid = styled(DataGrid)`
  margin-bottom: 15px;

  .MuiDataGrid-row, .MuiDataGrid-cell {
    max-height: unset !important;
  }
`;
