import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { LOGIN_SCHEMA } from './LoginSchemas';

type ReturnType = { login: { token: string } };

export class LoginService {
    constructor(private readonly client: ApolloClient<NormalizedCacheObject>) {}

    public login = (email: string, password: string) =>
        this.client.mutate<ReturnType>({ mutation: LOGIN_SCHEMA, variables: { email, password } })
            .then(it => it.data?.login.token);
}
