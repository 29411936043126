import { DialogContent, TextField } from "@mui/material";
import { Maybe } from "../../../Maybe";
import { StyledPositionDialog } from "../../PositionDialog.style";
import { PositionDialogButtons } from "../../PositionDialogButtons";

interface PositionDialog {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    technischeBemerkung: Maybe<string>;
    setTechnischeBemerkung: (technischeBemerkung: Maybe<string>) => void;
}

const TechnischeBemerkungPositionDialog = (props: PositionDialog) => {
    const onDialogClose = () => {
        props.setIsOpen(false);
    };

    return (
        <StyledPositionDialog open={props.isOpen} onClose={onDialogClose}>
            <DialogContent>
                <TextField
                    multiline
                    size={'small'}
                    sx={{ width: 600 }}
                    label={''}
                    value={props.technischeBemerkung}
                    onChange={e => props.setTechnischeBemerkung(e.target.value)}
                />
            </DialogContent>
            <PositionDialogButtons setIsOpen={props.setIsOpen} />
        </StyledPositionDialog>
    );
};

export { TechnischeBemerkungPositionDialog };

