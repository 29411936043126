import { Optional } from '../../common/Optional/Optional';
import { ActivitiesRepository } from '../../repository/ActivitiesRepository/ActivitiesRepository';
import { DigitsRepository } from '../../repository/DigitsRepository/DigitsRepository';
import { PositionsRepository } from '../../repository/PositionsRepository/PositionsRepository';
import { ActivitiesService } from '../Activities/ActivitiesService';
import { DigitsService } from '../Digits/DigitsService';
import { PositionsService } from '../Positions/PositionsService';

export class DownloadToRepositoryService {
    constructor(
        private readonly activitiesService: ActivitiesService,
        private readonly activitiesRepository: ActivitiesRepository,
        private readonly digitsService: DigitsService,
        private readonly digitsRepository: DigitsRepository,
        private readonly positionsService: PositionsService,
        private readonly positionsRepository: PositionsRepository
    ) {}

    public downloadAndSaveActivity = (id: string) =>
        this.activitiesService
            .get(id)
            .then(maybeActivity => new Optional(maybeActivity)
                .match({
                    some: activity => this.activitiesRepository
                        .add({ ...activity, offlineAvailable: true })
                        .then(activity => activity.id),
                    none: () => this.throwError('Eine Aktivität mit der ID ' + id + ' ist nicht vorhanden.')
                })
            )
            .then(activityId => this.downloadAndSaveDigits(activityId))
            .then(digitIds => this.downloadAndSavePositions(digitIds));

    private downloadAndSaveDigits = (activityId: string) => {
        const digitIds: string[] = [];
        return this.digitsService
            .filterBy(activityId)
            .then(digits => {
                digits.forEach(digit => {
                    this.digitsRepository.add({ ...digit, offlineAvailable: true });
                    digitIds.push(digit.id);
                })
            })
            .then(() => digitIds);
    }

    private downloadAndSavePositions = (digitIds: string[]) =>
        digitIds.forEach(digitId => this.positionsService
            .filterBy(digitId)
            .then(positions => {
                positions.forEach(position => this.positionsRepository.add({ ...position, offlineAvailable: true }))
            })
        );

    private throwError = (errorMessage: string) => { throw new Error(errorMessage); }
}
