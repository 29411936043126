import React, { useContext, useEffect, useState } from 'react';
import { updateOrAddRow } from '../../common/DataGrid/addOrUpdateRow';
import { Activity } from '../../entities/Activity/Activity';
import { ServicesContext } from '../../graphql/contexts/ServicesProvider';
import { InternetConnectionContext } from '../../repository/contexts/InternetConnectionProvider';
import DataGridWithActions from '../DataGridWithActions/DataGridWithActions';
import EditDialog from '../EditDialog/EditDialog';
import ActivityForm from './ActivityForm/ActivityForm';
import { ActivityColumns } from './models/ActivityColumns';

interface ActivitiesProps {
    entityName: { singular: string; plural: string; };
}

function ActivitiesDataGrid(props: ActivitiesProps) {
    // services
    const { checkConnectionService, activitiesService } = useContext(ServicesContext).services;
    const { hasInternetConnection } = useContext(InternetConnectionContext);

    // states
    const [activities, setActivities] = useState<Activity[] | undefined>();

    // funcs
    const getAll = () => activitiesService.getAll().then(activitiesResponse => setActivities(activitiesResponse));
    const checkConnectionAndGetAll = () => checkConnectionService.get()
        .then(result => { !!result && getAll(); })
        .catch(() => { checkConnectionAndGetAll(); });

    // hooks
    useEffect(() => {
        if (hasInternetConnection) {
            checkConnectionAndGetAll();
        } else {
            getAll();
        }
    }, [activitiesService, hasInternetConnection])

    return (
        <DataGridWithActions<Activity>
            entityName={props.entityName}
            sortModel={[{ field: 'dateTime', sort: 'asc' }]}
            columns={ActivityColumns}
            rows={activities}
            setRows={setActivities}
            handleRemove={activitiesService.remove}
            redirectTo='/activities'
            disabledAdd={!hasInternetConnection}
        >
            {({ id, open, handleClose }) =>
                <EditDialog
                    id={id}
                    open={open}
                    handleClose={handleClose}
                    entityName={props.entityName.singular}
                    description={{
                        new: 'Um eine neue ' + props.entityName.singular +
                            ' anzulegen, fülle bitte alle Pflichtfelder aus und speichere Deine Eingabe.',
                        edit: 'Um eine ' + props.entityName.singular +
                            ' zu bearbeiten, bearbeite die untenstehenden Felder und speichere Deine Eingabe.'
                    }}
                >
                    <ActivityForm
                        activityId={id}
                        handleClose={handleClose}
                        updateRow={(data) => !!data && updateOrAddRow(
                            data,
                            activities,
                            setActivities
                        )}
                        entityName={props.entityName}
                    />
                </EditDialog>
            }
        </DataGridWithActions>
    );
}

export default ActivitiesDataGrid;
