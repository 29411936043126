import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ThemeProvider as ScThemeProvider } from 'styled-components';
import React from 'react';

export function ThemeProvider(props: { children: JSX.Element[] | JSX.Element }) {
    return (
        <ScThemeProvider theme={muiTheme}>
            <MuiThemeProvider theme={muiTheme}>
                {props.children}
            </MuiThemeProvider>
        </ScThemeProvider>
    );
}

export const muiTheme = createTheme({
    palette: {
        primary: {
            light: '#c1c1bd',
            main: '#a0a09b',
            dark: '#666662'
        },
        secondary: {
            light: '#f36658',
            main: '#b52e2a',
            dark: '#780000'
        }
    }
});
