import React, { useState } from "react";
import { StyledPositionDialog } from "../../PositionDialog.style";
import { Autocomplete, DialogContent, TextField } from "@mui/material";
import { PositionDialogButtons } from "../../PositionDialogButtons";
import { NUMBER_FIELD_XS_WIDTH } from "../../constants";
import { Maybe } from "../../../Maybe";

type FundeOption = 'Keramik' | 'Knochen' | 'Eisen' | 'Silex' | 'Ziegel' | 'Brandlehm' | 'Bronze' | 'Freitext';
const FUNDE_OPTIONS: ReadonlyArray<FundeOption> = ['Keramik', 'Knochen', 'Eisen', 'Silex', 'Ziegel', 'Brandlehm', 'Bronze', 'Freitext'];

interface PositionDialog {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    funde: ReadonlyArray<Maybe<string>>;
    setFunde: (funde: ReadonlyArray<Maybe<string>>) => void;
}

const FundePositionDialog = (props: PositionDialog) => {
    const onDialogClose = () => {
        props.setIsOpen(false);
    };

    return (
        <StyledPositionDialog open={props.isOpen} onClose={onDialogClose}>
            <DialogContent>
                <Autocomplete
                    size={'small'}
                    options={FUNDE_OPTIONS}
                    renderInput={(params) => <TextField {...params} label={'Fund'} />}
                    onChange={(_, value) => props.setFunde([value, ...props.funde.slice(1)])}
                    sx={{ width: 220, opacity: props.funde[0] === 'Freitext' ? .5 : 1 }}
                />
                {props.funde[0] === 'Freitext' && <TextField
                    size={'small'}
                    sx={{ width: 400 }}
                    label={''}
                    value={props.funde[1]}
                    onChange={e => props.setFunde([props.funde[0], e.target.value, ...props.funde.slice(2)])}
                />}
                <span>aus Pos.</span>
                <TextField
                    size={'small'}
                    sx={{ width: NUMBER_FIELD_XS_WIDTH }}
                    label={''}
                    value={props.funde[2]}
                    onChange={e => props.setFunde([...props.funde.slice(0, 2), e.target.value])}
                />
            </DialogContent>
            <PositionDialogButtons setIsOpen={props.setIsOpen} />
        </StyledPositionDialog>
    );
};

export { FundePositionDialog };
