import { Autocomplete, TextField } from "@mui/material";
import { Maybe } from "graphql/jsutils/Maybe";

interface FormGapTextProps {
    setForm: (form: Maybe<Array<string>>) => void;
    form: Maybe<Array<string>>;
}

const FormGapText = (props: FormGapTextProps) => {
    const FORM_OPTIONS = ['rundlich', 'oval', 'rechteckig', 'quadratisch', 'linear', 'amorph', 'mit abgerundeten Ecken']

    return (
        <Autocomplete
            multiple
            freeSolo
            size={'small'}
            value={props.form ?? []}
            onChange={(_, value) => props.setForm(value)}
            options={FORM_OPTIONS}
            renderInput={(params) => <TextField {...params} label={'Form'} />}
            sx={{ width: 700 }}
        />
    );
}

export { FormGapText };

