import {StyledPosition} from "../Position.style";

interface PositionProps {
    nummer: number;
}

const EndeDerFeldarbeitPosition = (props: PositionProps): JSX.Element => {
    return (
        <StyledPosition>
            <div className={'details'}>
                <span className={'nummer'}>{props.nummer}</span>
                <span>Ende der Feldarbeit</span>
            </div>
        </StyledPosition>
    );
};

export {EndeDerFeldarbeitPosition};
