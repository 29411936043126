import React, {useContext, useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {
    DigitalPositionCardProvider
} from '../../components/DigitalPositionCardDataGrid/contexts/DigitalPositionCardProvider';
import DigitalPositionCardDataGrid from '../../components/DigitalPositionCardDataGrid/DigitalPositionCardDataGrid';
import {Activity, OfflineActivity} from '../../entities/Activity/Activity';
import {Digit as DigitEntity} from '../../entities/Digit/Digit';
import {ServicesContext} from '../../graphql/contexts/ServicesProvider';
import {RepositoriesContext} from '../../repository/contexts/RepositoriesProvider';
import BasePage from '../BasePage/BasePage';
import {Params} from '../params/Params';
import {Button} from "@mui/material";

interface DigitProps {
}

function Digit(props: DigitProps) {
    const {activityId, digitNumber} = useParams<Params>();
    const history = useHistory();

    // services
    const {activitiesService, digitsService} = useContext(ServicesContext).services;
    const {activitiesRepo} = useContext(RepositoriesContext);

    // states
    const [activity, setActivity] = useState<Activity>();
    const [digit, setDigit] = useState<DigitEntity>();
    const [offlineAvailable, setOfflineAvailable] = useState(false);

    // hooks
    useEffect(() => {
        activitiesRepo.get(activityId).then(activity => !!activity && setOfflineAvailable(
            (activity as OfflineActivity | undefined)?.offlineAvailable ?? false)
        );
    }, [activityId])

    useEffect(() => {
        // TODO: Filtermethode einbauen in Services wie bei Position. Und dann im Backend Routen dafür einbauen!
        digitsService
            .filterBy(activityId)
            .then(digits => setDigit(digits.find(digit => digit.number === Number(digitNumber))));
        activitiesService
            .get(activityId)
            .then(activity => !!activity ? setActivity(activity) : history.push('/activities'));
    }, [activitiesService, activityId, digitsService, digitNumber, history]);

    const entityName = {singular: 'Position', plural: 'Positionen'};

    return !!activity && !!digit
        ?
        <BasePage title={entityName.plural + ` | ${activity.number}, Stelle ${digit.number}`}>
            <>
                <Button onClick={() => history.push(`/activities/${activityId}/digits2/${digit.number}`)}>Zur neuen Version</Button>
                <DigitalPositionCardProvider>
                    <DigitalPositionCardDataGrid
                        digitId={digit.id}
                        offlineAvailable={offlineAvailable}
                        entityName={entityName}
                    />
                </DigitalPositionCardProvider>
            </>
        </BasePage>
        :
        <></>
}

export default Digit;
