import SyncIcon from '@mui/icons-material/Sync';
import { DialogContent, DialogContentText } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ServicesContext } from '../../graphql/contexts/ServicesProvider';
import { UploadFromRepositoryService } from '../../graphql/UploadFromRepository/UploadFromRepositoryService';
import { InternetConnectionContext } from '../../repository/contexts/InternetConnectionProvider';
import { RepositoriesContext } from '../../repository/contexts/RepositoriesProvider';
import StyledSyncDialog from './SyncDialog.style';

function SyncDialog() {
    // contexts
    const { appNeedsSync, setAppNeedsSync } = useContext(InternetConnectionContext);
    const {
        cachedActivitiesRepo,
        cachedDigitsRepo,
        cachedPositionsRepo,
        cachedKeywordsRepo,
        cachedFreeTextsRepo
    } = useContext(RepositoriesContext);
    const {
        checkConnectionService,
        activitiesService,
        digitsService,
        positionsService,
        keywordsService,
        freeTextsService
    } = useContext(ServicesContext).services;

    // states
    const uploadFromRepositoryService = new UploadFromRepositoryService(
        activitiesService,
        cachedActivitiesRepo,
        digitsService,
        cachedDigitsRepo,
        positionsService,
        cachedPositionsRepo,
        keywordsService,
        cachedKeywordsRepo,
        freeTextsService,
        cachedFreeTextsRepo
    );

    // funcs
    const checkConnectionAndUploadCachedData = async () => checkConnectionService.get()
        .then(result => !!result && uploadFromRepositoryService.uploadCachedData())
        .catch(() => { checkConnectionAndUploadCachedData(); })

    // hooks
    useEffect(() => {
        if (appNeedsSync) {
            checkConnectionAndUploadCachedData().finally(() => setAppNeedsSync(false));
        }
    }, [appNeedsSync])

    return (
        <StyledSyncDialog
            open={appNeedsSync}
        >
            <DialogContent>
                <DialogContentText>
                    Die App wird gerade mit dem Server synchronisiert. Bitte warte, bis diese Anzeige verschwindet
                    und schließe die App nicht!

                    <div className='icon-wrapper'>
                        <SyncIcon fontSize={'large'} />
                    </div>
                </DialogContentText>
            </DialogContent>
        </StyledSyncDialog>
    );
}

export default SyncDialog;
