import { GridSelectionModel } from '@mui/x-data-grid';
import { useState } from 'react';

function useDataGridSelection() {
    // selected ids of data grid
    const [selectedIds, setSelectedIds] = useState<string[]>([]);

    // function to change the state with new selected ids
    const onSelectionModelChange = (newSelection: GridSelectionModel) =>
        setSelectedIds(newSelection.map(it => it.toString()));

    return { selectedIds, onSelectionModelChange };
}

export default useDataGridSelection;
