import {StyledPosition} from "../Position.style";
import {Checkbox} from "@mui/material";
import {useState} from "react";

interface EntnahmeRestbefundPositionProps {
    nummer: number;
}

const EntnahmeRestbefundPosition = (props: EntnahmeRestbefundPositionProps): JSX.Element => {
    const [checked, setChecked] = useState(false);

    return (
        <StyledPosition>
            <div className={'details'}>
                <span className={'nummer'}>3.</span>
                <span>Entnahme Restbefund</span>
                <span style={{width: 25}} />
                <span>keine Funde</span>
                <Checkbox checked={checked} onChange={e => setChecked(e.target.checked)}/>
            </div>
        </StyledPosition>
    );
};

export {EntnahmeRestbefundPosition};
