import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';

export const KeywordsColumns = (handleEditClick: (id?: string) => void): GridColDef[] => [
        {
            field: 'id',
            hide: true
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1
        },
        {
            field: 'isCsvExport',
            hide: true
        },
        {
            field: 'configButtons',
            headerName: ' ',
            width: 110,
            filterable: false,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params: GridCellParams) =>
                <>
                    <Tooltip title={'Bearbeiten'}>
                        <IconButton>
                            <EditIcon onClick={() => handleEditClick(params.row.id)} />
                        </IconButton>
                    </Tooltip>
                </>,
            align: 'center'
        }
    ]
;
