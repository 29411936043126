import {OfflinePosition} from "../../entities/Position/Position";
import {StandardPosition} from "./StandardPositionen/StandardPosition";

const MOCKED_POSITIONS: ReadonlyArray<OfflinePosition> = [
    {
        id: 'adedc6fe-e908-4b0f-9514-ed28fbf5b840',
        dateTime: new Date(),
        digitId: '525935a1-7e5f-4fc3-bf88-617a808b6842',
        freeTexts: [],
        number: 1,
        offlineAvailable: false,
        keyword: {id: '65236590-d3e5-4660-ba12-d160ad2223b7', name: 'Definition', isCsvExport: true}
    },
    {
        id: 'adedc6fe-e908-4b0f-9514-ed28fbf5b840',
        dateTime: new Date(),
        digitId: '525935a1-7e5f-4fc3-bf88-617a808b6842',
        freeTexts: [],
        number: 2,
        offlineAvailable: false,
        keyword: {id: 'f6b29b63-a74d-42a6-b43f-c9c3355feb30', name: 'Planumsbeschreibung', isCsvExport: true}
    },
    {
        id: 'adedc6fe-e908-4b0f-9514-ed28fbf5b841',
        dateTime: new Date(),
        digitId: '525935a1-7e5f-4fc3-bf88-617a808b6843',
        freeTexts: [],
        number: 3,
        offlineAvailable: false,
        keyword: {id: 'f6b29b63-a74d-42a6-b43f-c9c3355feb30', name: 'Einmessung', isCsvExport: true}
    },
];

export {MOCKED_POSITIONS};
