import { Activity, OfflineActivity } from '../../entities/Activity/Activity';
import { BaseRepository } from '../BaseRepository';
import { DigitsRepository } from '../DigitsRepository/DigitsRepository';
import DocumentaRhenaniaDB from '../DocumentaRhenaniaDB';

export class ActivitiesRepository extends BaseRepository<Activity | OfflineActivity> {
    readonly digitsRepo: DigitsRepository;

    constructor(context: DocumentaRhenaniaDB, digitsRepo: DigitsRepository) {
        super(context.activities);
        this.digitsRepo = digitsRepo;
    }

    public delete = (activityId: string) => this.table
        .delete(activityId)
        .then(() => this.deleteRelatedDigitsOf([activityId]));

    public deleteRange = (activityIds: string[]) => this.table
        .bulkDelete(activityIds)
        .then(() => this.deleteRelatedDigitsOf(activityIds));

    private deleteRelatedDigitsOf = async (activityIds: string[]) => {
        let relatedDigitIds = [];
        for (const activityId of activityIds) {
            const digitIds = (await this.digitsRepo.filter(digit => digit.activityId === activityId))
                .map(it => it.id);
            relatedDigitIds.push(...digitIds);
        }
        await this.digitsRepo.deleteRange(relatedDigitIds);
    }
}
