import styled from 'styled-components';

const StyledLogin = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;
  width: 100%;

  > .MuiPaper-root {
    width: 30rem;
    
    > .MuiCardContent-root {
      display: flex;
      flex-direction: column;

      > .MuiFormControl-root {
        &:first-child {
          margin-bottom: 1rem;
        }
      }
    }
  }
`;

export default StyledLogin;
