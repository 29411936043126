import { Autocomplete, Button, DialogActions, DialogContent, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { GeoprofilGapText } from "./GeoprofilGapText";
import { Maybe } from "../../../Maybe";
import { DefaultGapText } from "./DefaultGapText";
import { StyledPositionDialog } from "../../PositionDialog.style";
import { BaubefundVerfaerbungStoerung } from "./BaubefundVerfaerbungStoerung";
import { Arbeitsbereich } from "./Arbeitsbereich";
import { Geoprofil } from "./Geoprofil";

type Stellentyp = 'Freitext' | 'Verfärbung' | 'Arbeitsbereich' | 'Baubefund' | 'Geoprofil' | 'Störung';
const STELLEN_OPTIONS: ReadonlyArray<Stellentyp> = ['Verfärbung', 'Baubefund', 'Störung', 'Arbeitsbereich', 'Geoprofil', 'Freitext'];

interface PositionDialog {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    setSatzteile: (satzteile: ReadonlyArray<Maybe<string>>) => void;
}

const ErstePositionDialog = (props: PositionDialog) => {
    const initialSatzteile = [
        "Als Stelle",
        null,
        'wird ein/e',
        null,
        'in',
        null,
        null,
        null,
        null,
        null,
    ] as const;

    const [stellentyp, setStellentyp] = useState<Maybe<Stellentyp>>();
    const [satzteile, setSatzteile] = useState<ReadonlyArray<Maybe<string>>>(initialSatzteile);

    // useEffect(() => {
    //     setSatzteile(initialSatzteile);
    // }, []);

    // const addSatzteilToIndex = (indecies: Array<number>, satzteileToAdd: Array<Maybe<string>>) => {
    //     const satzteileCopy = [...satzteile];
    //     for (let i = 0; i < indecies.length; i++) {
    //         console.log(satzteileToAdd[i]);
    //         satzteileCopy[indecies[i]] = satzteileToAdd[i];
    //     }
    //     console.log('---')
    //     setSatzteile(satzteileCopy);
    // };

    const addToSatzteile = (values: ReadonlyArray<{ index: number; satzteil: Maybe<string>; }>) => {
        const satzteileCopy = [...satzteile];
        for (let i = 0; i < values.length; i++) {
            const currentValue = values[i];
            satzteileCopy[currentValue.index] = currentValue.satzteil;
        }
        setSatzteile(satzteileCopy);
    };

    const resetAfterIndex = (index: number) => {
        // const d = [...satzteile].splice(index);
        // console.log('d', d);
        // return setSatzteile(d);
    };

    const onDialogClose = () => {
        props.setIsOpen(false);
    };

    return (
        <StyledPositionDialog open={props.isOpen} onClose={onDialogClose}>
            <DialogContent>
                <span>{initialSatzteile[0]}</span>
                <TextField
                    size={'small'}
                    sx={{ width: 40 }}
                    value={satzteile[1]}
                    onChange={(e) => addToSatzteile([{ index: 1, satzteil: e.target.value }])}
                />
                <span>{initialSatzteile[2]}</span>
                <Autocomplete
                    size={'small'}
                    options={STELLEN_OPTIONS}
                    renderInput={(params) => <TextField {...params} label={''} />}
                    value={stellentyp !== 'Freitext' ? satzteile[3] as Stellentyp : 'Freitext'}
                    onChange={(_, value) => {
                        addToSatzteile([{ index: 3, satzteil: value as Maybe<string> }]);
                        setStellentyp(value);
                    }}
                    sx={{ width: 220, opacity: stellentyp === 'Freitext' ? .5 : 1 }}
                />

                {(stellentyp === 'Baubefund' || stellentyp === 'Verfärbung' || stellentyp === 'Störung') &&
                    <BaubefundVerfaerbungStoerung
                        addToSatzteile={addToSatzteile}
                        satzteile={satzteile}
                        resetAfterIndex={resetAfterIndex}
                    />}

                {stellentyp === 'Arbeitsbereich' && <Arbeitsbereich addToSatzteile={addToSatzteile} satzteile={satzteile} />}

                {stellentyp === 'Geoprofil' && <Geoprofil addToSatzteile={addToSatzteile} satzteile={satzteile} resetAfterIndex={resetAfterIndex} />}

                {stellentyp === 'Freitext' && <TextField
                    size={'small'}
                    multiline
                    sx={{ width: 600 }}
                    value={satzteile[5]}
                    onChange={e => addToSatzteile([{ index: 5, satzteil: e.target.value }])}
                />}
            </DialogContent>
            <DialogActions>
                <Button
                    color={'primary'}
                    variant={'text'}
                    size={'small'}
                    onClick={() => props.setIsOpen(false)}
                >
                    Schließen
                </Button>
                <Button
                    color={'primary'}
                    variant={'text'}
                    size={'small'}
                    onClick={() => setSatzteile(initialSatzteile)}
                >
                    Zurücksetzen
                </Button>
                <Button
                    color={'secondary'}
                    variant={'contained'}
                    size={'small'}
                    onClick={() => {
                        props.setIsOpen(false);
                        props.setSatzteile(satzteile);
                    }}
                >
                    Speichern
                </Button>
            </DialogActions>
        </StyledPositionDialog>
    );
};

const DefinitionRenderer = (props: {
    value: Maybe<Stellentyp>;
    satzteile: ReadonlyArray<Maybe<string>>;
    addSatzteilToIndex: (index: Array<number>, satzteileToAdd: Array<Maybe<string>>) => void;
}) => {
    useEffect(() => {
        switch (props.value) {
            case 'Arbeitsbereich':
                props.addSatzteilToIndex([4], ['definiert']);
                break;
        }
    }, [props.value]);

    switch (props.value) {
        case 'Verfärbung':
        case 'Baubefund':
        case 'Störung':
            return <DefaultGapText
                satzteile={props.satzteile}
                addSatzteilToIndex={props.addSatzteilToIndex}
            />;
        case 'Arbeitsbereich':
            return <span>definiert.</span>;
        case 'Geoprofil':
            return <GeoprofilGapText addSatzteilToIndex={props.addSatzteilToIndex} />;
        case 'Freitext':
            return <TextField
                size={'small'}
                multiline
                sx={{ width: 600 }}
                value={props.satzteile[4]}
                onChange={e => props.addSatzteilToIndex([4], [e.target.value])}
            />;
        default:
            return <></>;
    }
}

export { ErstePositionDialog };
