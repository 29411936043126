import { DialogContent, TextField } from "@mui/material";
import { Maybe } from "../../../Maybe";
import { StyledPositionDialog } from "../../PositionDialog.style";
import { PositionDialogButtons } from "../../PositionDialogButtons";

interface PositionDialog {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    deutung: Maybe<string>;
    setDeutung: (deutung: Maybe<string>) => void;
}

const DeutungPositionDialog = (props: PositionDialog) => {
    const onDialogClose = () => {
        props.setIsOpen(false);
    };

    return (
        <StyledPositionDialog open={props.isOpen} onClose={onDialogClose}>
            <DialogContent>
                <TextField multiline size={'small'} sx={{ width: 600 }} label={''} value={props.deutung} onChange={e => props.setDeutung(e.target.value)} />
            </DialogContent>
            <PositionDialogButtons setIsOpen={props.setIsOpen} />
        </StyledPositionDialog>
    );
};

export { DeutungPositionDialog };
