import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import { useMemo, useReducer, useState } from "react";
import { StyledPosition } from "../Position.style";
import { Beschreibung } from "./Dialog/Beschreibung";
import { BeschreibungPositionDialog } from "./Dialog/BeschreibungPositionDialog";

interface PositionProps {
    nummer: number;
    isDialogOpen: boolean;
}

const BeschreibungPosition = (props: PositionProps): JSX.Element => {
    const [isDialogOpen, setIsDialogOpen] = useState(props.isDialogOpen);
    const [beschreibung, setBeschreibung] = useReducer((state: Beschreibung, newState: Partial<Beschreibung>) =>
        ({ ...state, ...newState }),
        {}
    );

    const zusammengesetzterText = useMemo(() => {
        let text = '';
        if (beschreibung.planum) {
            text += 'Planum |';
            text += beschreibung.planum.form ? ` Form: ${beschreibung.planum.form?.join(', ') + ' |' ?? ''}` : '';
            text += beschreibung.planum.masse ? ` Maße: ${beschreibung.planum.masse + ' |'}` : '';
            text += beschreibung.planum.farbe ? ` Farbe: ${beschreibung.planum.farbe.join(', ') + ' |' ?? ''}` : '';
            text += beschreibung.planum.sediment ? ` Sediment: ${beschreibung.planum.sediment.join(', ') + ' |' ?? ''}` : '';
            text += beschreibung.planum.stratigrafie ? ` Stratigrafie: ${beschreibung.planum.stratigrafie + ' |'}` : '';
            text += beschreibung.planum.sichtbarkeit ? ` Sichtbarkeit: ${beschreibung.planum.sichtbarkeit + ' |'}` : '';
            text += beschreibung.planum.freitext ? ` ${beschreibung.planum.freitext + ' |'}` : '';
            text += beschreibung.planum.einschluesse?.hk
                ? ` HK - Brocken, ${(beschreibung.planum.einschluesse?.hk.brocken ?? '') + ' -'} Flitter, ${(beschreibung.planum.einschluesse?.hk.flitter ?? '') + ' |'}`
                : '';
            text += beschreibung.planum.einschluesse?.bl
                ? ` BL - Brocken, ${(beschreibung.planum.einschluesse?.bl.brocken ?? '') + ' -'} Flitter, ${(beschreibung.planum.einschluesse?.bl.flitter ?? '') + ' |'}`
                : '';

            const kies = beschreibung.planum.einschluesse?.kies
                ? beschreibung.planum.einschluesse?.kies.fG
                    ? `fG, ${beschreibung.planum.einschluesse?.kies.fG}`
                    : beschreibung.planum.einschluesse?.kies.gG
                        ? `gG, ${beschreibung.planum.einschluesse?.kies.gG}`
                        : beschreibung.planum.einschluesse?.kies.mG
                            ? `mG, ${beschreibung.planum.einschluesse?.kies.mG}`
                            : ''
                : '';

            text += beschreibung.planum.einschluesse?.kies ? ` Kies: ${kies} |` : '';
            text += beschreibung.planum.einschluesse?.ziegelbruch ? ` Ziegelbruch: ${beschreibung.planum.einschluesse?.ziegelbruch} |` : '';
            text += beschreibung.planum.einschluesse?.eisenausfaellungen ? ` Eisenausfällungen: ${beschreibung.planum.einschluesse?.eisenausfaellungen} |` : '';
            text += beschreibung.planum.einschluesse?.freitext ? ` ${beschreibung.planum.einschluesse?.freitext}` : '';
        }
        if (beschreibung.profil) {
            text += 'Profil |';
            text += beschreibung.profil?.form ? ` Form: ${beschreibung.profil.form?.join(', ') + ' |' ?? ''}` : '';
            text += beschreibung.profil?.sohle ? ` Sohle: ${beschreibung.profil.sohle?.join(', ') + ' |' ?? ''}` : '';
            text += beschreibung.profil.stratigrafie ? ` Stratigrafie: ${beschreibung.profil.stratigrafie + ' |'}` : '';
            text += beschreibung.profil.maxLaenge ? ` Max.-Länge: ${beschreibung.profil.maxLaenge + ' |'}` : '';
            text += beschreibung.profil.maxTiefe ? ` Max.-Tiefe: ${beschreibung.profil.maxTiefe + ' |'}` : '';
            text += beschreibung.profil.OK ? ` OK: ${beschreibung.profil.OK + ' |'}` : '';
            text += beschreibung.profil.UK ? ` UK: ${beschreibung.profil.UK + ' |'}` : '';
            text += beschreibung.profil.freitext ? ` Freitext: ${beschreibung.profil.freitext + ' |'}` : '';
        }

        return text;
    }, [beschreibung])

    return (
        <StyledPosition>
            <div className={'details'}>
                <span className={'nummer'}>{props.nummer}</span>
                <span>Beschreibung</span>
            </div>
            <div className={'text'}>
                <span>{zusammengesetzterText}</span>
            </div>
            <div className={'buttons'}>
                <IconButton color={'primary'} onClick={() => setIsDialogOpen(true)}>
                    <EditIcon />
                </IconButton>
            </div>

            <BeschreibungPositionDialog
                isOpen={isDialogOpen}
                setIsOpen={setIsDialogOpen}
                beschreibung={beschreibung}
                setBeschreibung={setBeschreibung}
            />
        </StyledPosition>
    );
};

export { BeschreibungPosition };
