import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Digit, OfflineDigit } from '../../entities/Digit/Digit';
import { BaseService, GraphQLService } from '../BaseService';
import { Service } from '../Service';
import {
    ADD_DIGIT,
    FILTER_DIGITS_BY_ACTIVITY_ID,
    GET_ALL_DIGITS,
    GET_DIGIT,
    REMOVE_DIGITS,
    UPDATE_DIGIT
} from './DigitsSchemas';

export class DigitsService implements Service<Digit | OfflineDigit> {
    constructor(private readonly client: ApolloClient<NormalizedCacheObject>) {
        this.baseClient = new BaseService(
            GET_DIGIT,
            GET_ALL_DIGITS,
            ADD_DIGIT,
            UPDATE_DIGIT,
            REMOVE_DIGITS,
            client
        );
    }

    private readonly baseClient: GraphQLService<Digit | OfflineDigit>;

    public getAll = () =>
        this.baseClient.getAll('allDigits');

    public get = (id: string) =>
        this.baseClient.get(id, 'digit');

    public filterBy = (activityId: string) =>
        this.client
            .query<{ filterDigitsByActivityId: Digit[] }, { activityId: string }>(
                { query: FILTER_DIGITS_BY_ACTIVITY_ID, variables: { activityId } }
            )
            .then(result => result.data.filterDigitsByActivityId);

    public add = (digit: Digit) =>
        this.baseClient.add(digit, 'addDigit', { digit })
            .then(digit => digit ?? null);

    public update = (digit: Digit) =>
        // TODO: Warum 2x Digit rein???
        this.baseClient.update(digit, 'updateDigit', { digit })
            .then(digit => digit ?? null);

    public remove = (ids: string[]) =>
        this.baseClient.remove(ids, 'removeDigits');
}
