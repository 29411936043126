import React from 'react';
import KeywordsDataGrid from '../../components/KeywordsDataGrid/KeywordsDataGrid';
import BasePage from '../BasePage/BasePage';

function Keywords() {
    const entityName = { singular: 'Arbeitsschritt', plural: 'Arbeitsschritte' };

    return (
        <BasePage title={entityName.plural}>
            <KeywordsDataGrid entityName={entityName} />
        </BasePage>
    );
}

export default Keywords;
