import React, { useContext, useEffect } from 'react';
import { updateOrAddRow } from '../../common/DataGrid/addOrUpdateRow';
import { OfflinePosition, Position } from '../../entities/Position/Position';
import { ServicesContext } from '../../graphql/contexts/ServicesProvider';
import DataGridWithActions from '../DataGridWithActions/DataGridWithActions';
import { GlobalSnackbarContext } from '../Snackbar/contexts/GlobalSnackbarProvider';
import { DigitalPositionCardContext } from './contexts/DigitalPositionCardProvider';
import { PositionColumns } from './models/PositionColumns';
import { v4 as uuidv4 } from 'uuid';

interface DigitalPositionCardDataGridProps {
    digitId: string;
    offlineAvailable?: boolean;
    entityName: { singular: string; plural: string; };
}

function DigitalPositionCardDataGrid(props: DigitalPositionCardDataGridProps) {
    // contexts
    const snackbar = useContext(GlobalSnackbarContext);
    const { positionsService, keywordsService, freeTextsService } = useContext(ServicesContext).services;
    const { positions, availableKeywords, setDigitalPositionCard } = useContext(DigitalPositionCardContext);

    // hooks
    useEffect(() => {
        Promise
            .all([
                keywordsService.getAll(),
                freeTextsService.getAllKeywordFreeTexts(),
                positionsService.filterBy(props.digitId)
            ])
            .then(responses => {
                const [keywordsResponse, freeTextsResponse, positionsResponse] = responses;
                setDigitalPositionCard({
                    positions: positionsResponse.map(position => ({
                        ...position,
                        offlineAvailable: props.offlineAvailable
                    } as OfflinePosition)),
                    availableKeywords: keywordsResponse,
                    availableFreeTexts: freeTextsResponse
                });
            })
    }, [positionsService, keywordsService, freeTextsService, setDigitalPositionCard, props.digitId])

    // functions
    const setPositions = (positions: OfflinePosition[]) => setDigitalPositionCard({ positions });

    const handleAdd = () => positionsService.add({
        id: uuidv4(),
        digitId: props.digitId,
        number: (positions?.length ?? 0) + 1, // TODO: Auch die letzte Zahl nehmen und nicht Anzahl inkrementieren.
        dateTime: new Date(),
        freeTexts: [],
        offlineAvailable: props.offlineAvailable ?? false
    } as OfflinePosition)
        .then(newPosition => !!newPosition && updateOrAddRow(
            { ...newPosition, offlineAvailable: props.offlineAvailable ?? false },
            positions,
            setPositions
        ))
        .then(() => snackbar.setSnackbar({
            open: true,
            severity: 'success',
            message: 'Position wurde erfolgreich angelegt.'
        }));

    return (
        <DataGridWithActions<OfflinePosition>
            entityName={props.entityName}
            sortModel={[{ field: 'number', sort: 'asc' }]}
            columns={PositionColumns}
            rows={positions}
            setRows={setPositions}
            handleRemove={positionsService.remove}
            rowHeight={80}
            handleAdd={handleAdd}
            goBackButton
        />
    );
}

export default DigitalPositionCardDataGrid;
