import { useState } from "react";
import { StyledPosition } from "../Position.style";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { StrukturenErkanntPositionDialog } from "./Dialog/StrukturenErkanntPositionDialog";
import { Maybe } from "../../Maybe";

interface FundePositionProps {
    nummer: number;
}

const StrukturenErkanntPosition = (props: FundePositionProps): JSX.Element => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [strukturen, setStrukturen] = useState<ReadonlyArray<Maybe<string>>>([]);

    return (
        <StyledPosition>
            <div className={'details'}>
                <span className={'nummer'}>{props.nummer}</span>
                <span>Strukturen</span>
            </div>
            <div className={'text'}>
                <span>
                    {!!strukturen[0] && strukturen[0] !== ''
                        ? `erkannt und St. ${strukturen[0]}`
                        : ''}
                    {!!strukturen[1] && strukturen[1] !== ''
                        ? ` in Pl. ${strukturen[1]} vergeben`
                        : ''}
                </span>
            </div>
            <div className={'buttons'}>
                <IconButton color={'primary'} onClick={() => setIsDialogOpen(true)}>
                    <EditIcon />
                </IconButton>
            </div>

            <StrukturenErkanntPositionDialog
                isOpen={isDialogOpen}
                setIsOpen={setIsDialogOpen}
                strukturen={strukturen}
                setStrukturen={setStrukturen}
            />
        </StyledPosition>
    );
};

export { StrukturenErkanntPosition };
