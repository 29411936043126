import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { TextField } from 'formik-mui';
import { LocalizationProvider } from '@mui/lab';
import { DatePicker } from 'formik-mui-lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import deDE from 'date-fns/locale/de';
import { Field } from 'formik';
import 'moment/locale/de';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UpdateRowProp } from '../../../common/DataGrid/addOrUpdateRow';
import { Activity } from '../../../entities/Activity/Activity';
import { ServicesContext } from '../../../graphql/contexts/ServicesProvider';
import EditDialogForm from '../../EditDialogForm/EditDialogForm';
import { v4 as uuidv4 } from 'uuid';
import { ACTIVITY_VALIDATION_SCHEMA } from './validationSchema';

interface ActivityFormProps extends UpdateRowProp<Activity> {
    activityId?: string;
    handleClose: () => void;
    entityName: { singular: string, plural: string };
}

function ActivityForm(props: ActivityFormProps) {
    const { activitiesService } = useContext(ServicesContext).services;
    const history = useHistory();

    const [initialValues, setInitialValues] = useState<Activity>({
        id: uuidv4(),
        dateTime: new Date(),
        number: ''
    });

    // load data for entity if entity exists
    useEffect(() => {
        if (!!props.activityId) {
            activitiesService.get(props.activityId).then(activity => !!activity
            ? setInitialValues(activity)
            : history.push('/activities')
            );
        }
    }, [activitiesService, props.activityId, history]);

    return (
        <EditDialogForm<Activity>
            id={props.activityId}
            entityName={props.entityName}
            handleClose={props.handleClose}
            service={activitiesService}
            updateRow={props.updateRow}
            initialValues={initialValues}
            validationSchema={ACTIVITY_VALIDATION_SCHEMA}
        >
            {({ values, errors, touched, isSubmitting }) =>
                <>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={deDE}>
                        <Field
                            component={DatePicker}
                            mask='__.__.____'
                            label={'Start der ' + props.entityName.singular + ' *'}
                            name='dateTime'
                            textField={{
                                helperText: touched.dateTime && errors.dateTime,
                                error: touched.dateTime && Boolean(errors.dateTime),
                                fullWidth: true
                            }}
                        />
                    </LocalizationProvider>
                    <Field
                        component={TextField}
                        label='Aktivitätsnummer *'
                        name='number'
                        error={touched.number && Boolean(errors.number)}
                        helperText={touched.number && errors.number}
                        fullWidth
                        autoFocus
                        InputLabelProps={{ shrink: !!values.number }}
                    />
                    <Field
                        component={TextField}
                        label='Durchführung'
                        name='operation'
                        error={touched.operation && Boolean(errors.operation)}
                        helperText={touched.operation && errors.operation}
                        fullWidth
                        InputLabelProps={{ shrink: !!values.operation }}
                    />
                    <Field
                        component={TextField}
                        label='Bemerkung'
                        name='comment'
                        error={touched.comment && Boolean(errors.comment)}
                        helperText={touched.comment && errors.comment}
                        fullWidth
                        InputLabelProps={{ shrink: !!values.comment }}
                    />
                    <Button
                        startIcon={<AddIcon />}
                        disabled={isSubmitting}
                        color='secondary'
                        variant='contained'
                        type='submit'
                    >
                        {props.entityName.singular} speichern
                    </Button>
                </>
            }
        </EditDialogForm>
    );
}

export default ActivityForm;
