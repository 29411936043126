import { Autocomplete, DialogContent, TextField } from "@mui/material";
import { Dispatch, useCallback, useEffect, useMemo, useState } from "react";
import { Maybe } from "../../../Maybe";
import { StyledPositionDialog } from "../../PositionDialog.style";
import { PositionDialogButtons } from "../../PositionDialogButtons";
import { EinschluesseGapText } from "./EinschluesseGapText";
import { FarbeGapText } from "./FarbeGapText";
import { FormGapText } from "./FormGapText";
import { SedimentGapText } from "./SedimentGapText";
import { SichtbarkeitGapText } from "./SichtbarkeitGapText";
import { SohleGapText } from "./SohleGapText";
import { Beschreibung } from "./Beschreibung";

type BeschreibungOptions = 'Planum' | 'Profil';
const BESCHREIBUNG_OPTIONS: ReadonlyArray<BeschreibungOptions> = ['Planum', 'Profil'];

interface PositionDialog {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    setBeschreibung: Dispatch<Partial<Beschreibung>>;
    beschreibung: Beschreibung;
}

const BeschreibungPositionDialog = (props: PositionDialog) => {
    const autocompleteValue = useMemo((): Maybe<BeschreibungOptions> => {
        if (props.beschreibung.planum) return 'Planum';
        if (props.beschreibung.profil) return 'Profil';
        return undefined;
    }, [props.beschreibung]);
    const [autocomplete, setAutocomplete] = useState<Maybe<BeschreibungOptions>>(autocompleteValue);

    useEffect(() => {
        switch (autocomplete) {
            case 'Planum':
                props.setBeschreibung({ profil: undefined })
                break;
            case 'Profil':
                props.setBeschreibung({ planum: undefined })
                break;
        }
    }, [autocomplete]);

    const onDialogClose = () => {
        props.setIsOpen(false);
    };

    return (
        <StyledPositionDialog open={props.isOpen} onClose={onDialogClose}>
            <DialogContent>
                <Autocomplete
                    size={'small'}
                    options={BESCHREIBUNG_OPTIONS}
                    renderInput={(params) => <TextField {...params} label={'Beschreibung von'} />}
                    value={autocomplete}
                    onChange={(_, value) => setAutocomplete(value)}
                    sx={{ width: 220 }}
                />

                {autocomplete === 'Planum' &&
                    <div className={'planum-beschreibung'}>
                        <FormGapText
                            form={props.beschreibung.planum?.form}
                            setForm={form => props.setBeschreibung({ planum: { ...props.beschreibung.planum!, form } })}
                        />
                        <TextField
                            size={'small'}
                            sx={{ width: 700 }}
                            label={'Maße'}
                            value={props.beschreibung.planum?.masse}
                            onChange={e => props.setBeschreibung({ planum: { ...props.beschreibung.planum!, masse: e.target.value } })}
                        />
                        <FarbeGapText
                            farbe={props.beschreibung.planum?.farbe}
                            setFarbe={farbe => props.setBeschreibung({ planum: { ...props.beschreibung.planum!, farbe } })}
                        />
                        <SedimentGapText
                            sediment={props.beschreibung.planum?.sediment}
                            setSediment={sediment => props.setBeschreibung({ planum: { ...props.beschreibung.planum!, sediment } })}
                        />
                        <TextField
                            size={'small'}
                            sx={{ width: 700 }}
                            label={'Stratigrafie'}
                            value={props.beschreibung.planum?.stratigrafie}
                            onChange={e => props.setBeschreibung({ planum: { ...props.beschreibung.planum!, stratigrafie: e.target.value } })}
                        />
                        <SichtbarkeitGapText
                            sichtbarkeit={props.beschreibung.planum?.sichtbarkeit}
                            setSichtbarkeit={sichtbarkeit => props.setBeschreibung({ planum: { ...props.beschreibung.planum!, sichtbarkeit } })}
                        />
                        <TextField
                            size={'small'}
                            sx={{ width: 700 }}
                            label={'Freitext'}
                            value={props.beschreibung.planum?.freitext}
                            onChange={e => props.setBeschreibung({ planum: { ...props.beschreibung.planum!, freitext: e.target.value } })}
                        />
                        <EinschluesseGapText
                            hk={props.beschreibung.planum?.einschluesse?.hk}
                            setHk={hk => props.setBeschreibung({ planum: { ...props.beschreibung.planum!, einschluesse: { ...props.beschreibung.planum?.einschluesse, hk } } })}
                            bl={props.beschreibung.planum?.einschluesse?.bl}
                            setBl={bl => props.setBeschreibung({ planum: { ...props.beschreibung.planum!, einschluesse: { ...props.beschreibung.planum?.einschluesse, bl } } })}
                            kies={props.beschreibung.planum?.einschluesse?.kies}
                            setKies={kies => props.setBeschreibung({ planum: { ...props.beschreibung.planum!, einschluesse: { ...props.beschreibung.planum?.einschluesse, kies } } })}
                            ziegelbruch={props.beschreibung.planum?.einschluesse?.ziegelbruch}
                            setZiegelbruch={ziegelbruch => props.setBeschreibung({ planum: { ...props.beschreibung.planum!, einschluesse: { ...props.beschreibung.planum?.einschluesse, ziegelbruch } } })}
                            eisenausfaellungen={props.beschreibung.planum?.einschluesse?.eisenausfaellungen}
                            setEisenausfaellungen={eisenausfaellungen => props.setBeschreibung({ planum: { ...props.beschreibung.planum!, einschluesse: { ...props.beschreibung.planum?.einschluesse, eisenausfaellungen } } })}
                            freitext={props.beschreibung.planum?.einschluesse?.freitext}
                            setFreitext={freitext => props.setBeschreibung({ planum: { ...props.beschreibung.planum!, einschluesse: { ...props.beschreibung.planum?.einschluesse, freitext } } })}
                        />
                    </div>}

                {autocomplete === 'Profil' &&
                    <div className={'planum-beschreibung'}>
                        <FormGapText
                            setForm={form => props.setBeschreibung({ profil: { ...props.beschreibung.profil!, form } })}
                            form={props.beschreibung.profil?.form}
                        />
                        <TextField 
                            size={'small'} 
                            sx={{ width: 700 }} 
                            label={'TODO WANDUNGEN'} 
                        />
                        <SohleGapText
                            setSohle={sohle => props.setBeschreibung({ profil: { ...props.beschreibung.profil!, sohle } })}
                            sohle={props.beschreibung.profil?.sohle}
                        />
                        <TextField 
                            size={'small'} 
                            sx={{ width: 700 }} 
                            label={'Stratigrafie'} 
                            value={props.beschreibung.profil?.stratigrafie}
                            onChange={e => props.setBeschreibung({ profil: { ...props.beschreibung.profil!, stratigrafie: e.target.value } })}
                        />
                        <TextField
                            size={'small'}
                            sx={{ width: 700 }}
                            label={'Max.-Länge'}
                            value={props.beschreibung.profil?.maxLaenge}
                            onChange={e => props.setBeschreibung({ profil: { ...props.beschreibung.profil!, maxLaenge: e.target.value } })}
                        />
                        <TextField 
                            size={'small'} 
                            sx={{ width: 700 }} 
                            label={'Max.-Tiefe'} 
                            value={props.beschreibung.profil?.maxTiefe}
                            onChange={e => props.setBeschreibung({ profil: { ...props.beschreibung.profil!, maxTiefe: e.target.value } })}
                        />
                        <TextField 
                            size={'small'} 
                            sx={{ width: 700 }} 
                            label={'OK'} 
                            value={props.beschreibung.profil?.OK}
                            onChange={e => props.setBeschreibung({ profil: { ...props.beschreibung.profil!, OK: e.target.value } })}
                        />
                        <TextField 
                            size={'small'} 
                            sx={{ width: 700 }} 
                            label={'UK'} 
                            value={props.beschreibung.profil?.UK}
                            onChange={e => props.setBeschreibung({ profil: { ...props.beschreibung.profil!, UK: e.target.value } })}
                        />
                        <TextField 
                            size={'small'} 
                            sx={{ width: 700 }}
                            label={'Freitext'}
                            value={props.beschreibung.profil?.freitext}
                            onChange={e => props.setBeschreibung({ profil: { ...props.beschreibung.profil!, freitext: e.target.value } })}
                        />
                    </div>}
            </DialogContent>
            <PositionDialogButtons setIsOpen={props.setIsOpen} />
        </StyledPositionDialog>
    );
};

export { BeschreibungPositionDialog };

