import React, { useContext, useEffect, useState } from 'react';
import { updateOrAddRow } from '../../common/DataGrid/addOrUpdateRow';
import { Activity, OfflineActivity } from '../../entities/Activity/Activity';
import { Digit } from '../../entities/Digit/Digit';
import { ServicesContext } from '../../graphql/contexts/ServicesProvider';
import { InternetConnectionContext } from '../../repository/contexts/InternetConnectionProvider';
import { RepositoriesContext } from '../../repository/contexts/RepositoriesProvider';
import DataGridWithActions from '../DataGridWithActions/DataGridWithActions';
import DigitForm from './DigitForm/DigitForm';
import EditDialog from '../EditDialog/EditDialog';
import { DigitColumns } from './models/DigitColumns';

interface DigitsGridDataProps {
    activityId: string;
    entityName: { singular: string; plural: string };
}

function DigitsDataGrid(props: DigitsGridDataProps) {
    // services
    const { checkConnectionService, digitsService } = useContext(ServicesContext).services;
    const { activitiesRepo } = useContext(RepositoriesContext);
    const { hasInternetConnection } = useContext(InternetConnectionContext);

    // states
    const [digits, setDigits] = useState<Digit[] | undefined>();
    const [nextDigitNumber, setNextDigitNumber] = useState(1);
    const [offlineAvailable, setOfflineAvailable] = useState(false);

    // funcs
    const getAll = () => digitsService.filterBy(props.activityId).then(digitsResponse => setDigits(digitsResponse));
    const checkConnectionAndGetAll = () => checkConnectionService.get()
        .then(result => { !!result && getAll(); })
        .catch(() => { checkConnectionAndGetAll(); });
    // hooks

    useEffect(() => {
        if (hasInternetConnection) {
            checkConnectionAndGetAll();
        } else {
            getAll();
        }
    }, [digitsService, hasInternetConnection])

    useEffect(() => {
        activitiesRepo
            .get(props.activityId)
            .then(activity => setOfflineAvailable(
                (activity as OfflineActivity | undefined)?.offlineAvailable ?? false)
            );
    }, [props.activityId])

    useEffect(() => {
        const sortedRows = digits
            ?.slice()
            .sort((d1, d2) => (d1.number > d2.number) ? 1 : (d2.number > d1.number) ? -1 : 0);
        let nextDigitNumber = 1;
        if (!!sortedRows && sortedRows.length > 0) {
            nextDigitNumber = sortedRows[sortedRows.length - 1].number + 1;
        }
        setNextDigitNumber(nextDigitNumber)
    }, [digits]);

    return (
        <DataGridWithActions<Digit>
            entityName={props.entityName}
            sortModel={[{ field: 'number', sort: 'asc' }]}
            columns={DigitColumns}
            rows={digits}
            setRows={setDigits}
            handleRemove={digitsService.remove}
            redirectTo={'/activities/' + props.activityId + '/digits'}
            goBackButton
        >
            {({ id, open, handleClose }) =>
                <EditDialog
                    open={open}
                    handleClose={handleClose}
                    entityName={props.entityName.singular}
                    description={{
                        new: 'Um eine neue ' + props.entityName.singular +
                            ' anzulegen, fülle bitte alle Pflichtfelder aus und speichere Deine Eingabe.',
                        edit: 'Um eine ' + props.entityName.singular +
                            ' zu bearbeiten, bearbeite die untenstehenden Felder und speichere Deine Eingabe.'
                    }}
                >
                    <DigitForm
                        id={id}
                        activityId={props.activityId}
                        offlineAvailable={offlineAvailable}
                        handleClose={handleClose}
                        updateRow={(data) => !!data && updateOrAddRow(
                            data,
                            digits,
                            setDigits
                        )}
                        entityName={props.entityName}
                        nextDigitNumber={nextDigitNumber}
                    />
                </EditDialog>
            }
        </DataGridWithActions>
    );
}

export default DigitsDataGrid;
