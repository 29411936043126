import { Button, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Select, TextField } from 'formik-mui';
import { Field } from 'formik';
import 'moment/locale/de';
import React, { useContext, useEffect, useState } from 'react';
import { UpdateRowProp } from '../../../common/DataGrid/addOrUpdateRow';
import { FreeText } from '../../../entities/FreeText/FreeText';
import { Keyword } from '../../../entities/Keyword/Keyword';
import { ServicesContext } from '../../../graphql/contexts/ServicesProvider';
import EditDialogForm from '../../EditDialogForm/EditDialogForm';
import { v4 as uuidv4 } from 'uuid';
import { FREE_TEXT_VALIDATION_SCHEMA } from './validationSchema';

interface FreeTextFormProps extends UpdateRowProp<FreeText> {
    freeText?: FreeText;
    keywords: Keyword[];
    handleClose: () => void;
    entityName: { singular: string; plural: string; };
}

function FreeTextForm(props: FreeTextFormProps) {
    const { freeTextsService } = useContext(ServicesContext).services;
    const [initialValues, setInitialValues] = useState<FreeText>({ id: uuidv4(), text: '', keywordId: '' });

    // load data for entity if entity exists
    useEffect(() => {
        if (!!props.freeText) {
            setInitialValues({
                ...props.freeText,
                keywordId: props.freeText.keywordId || ''
            })
        }
    }, [props.freeText]);

    return (
        <EditDialogForm<FreeText>
            id={props.freeText?.id}
            entityName={props.entityName}
            handleClose={props.handleClose}
            service={freeTextsService}
            updateRow={props.updateRow}
            initialValues={initialValues}
            validationSchema={FREE_TEXT_VALIDATION_SCHEMA}
        >
            {({ values, errors, touched, isSubmitting }) =>
                <>
                    <Field
                        component={TextField}
                        label={props.entityName.singular + ' *'}
                        name='text'
                        error={touched.text && Boolean(errors.text)}
                        helperText={touched.text && errors.text}
                        fullWidth
                        autoFocus
                        InputLabelProps={{ shrink: !!values.text }}
                    />
                    <Field
                        fullWidth
                        component={Select}
                        id='keywordId'
                        name='keywordId'
                        labelId='keywordId-label'
                        label='Arbeitsschritt'
                        InputLabelProps={{ shrink: !!values.keywordId }}
                    >
                        {props.keywords.map(it => <MenuItem value={it.id}>{it.name}</MenuItem>)}
                    </Field>
                    <Button
                        startIcon={<AddIcon />}
                        disabled={isSubmitting}
                        color='secondary'
                        variant='contained'
                        type='submit'
                    >
                        {props.entityName.singular} speichern
                    </Button>
                </>
            }
        </EditDialogForm>
    );
}

export default FreeTextForm;
