import { Autocomplete, TextField } from "@mui/material";
import { Maybe } from "../../../Maybe";

interface SichtbarkeitGapTextProps {
    setSichtbarkeit: (sichtbarkeit: Maybe<string>) => void;
    sichtbarkeit: Maybe<string>;
}

const SichtbarkeitGapText = (props: SichtbarkeitGapTextProps) => {
    const SICHTBARKEIT_OPTIONS = ['sehr gut', 'gut', 'mäßig', 'schlecht'];

    return (
        <Autocomplete
            size={'small'}
            options={SICHTBARKEIT_OPTIONS}
            renderInput={(params) => <TextField {...params} label={'Sichtbarkeit'} />}
            sx={{ width: 700 }}
            value={props.sichtbarkeit}
            onChange={(_, value) => props.setSichtbarkeit(value)}
        />
    );
}

export { SichtbarkeitGapText };

