import React, { createContext, useReducer } from 'react';

export interface GlobalSnackbarProps {
    open: boolean;
    severity?: 'error' | 'warning' | 'info' | 'success';
    message?: string;
}

export interface WritableGlobalSnackbarProps extends GlobalSnackbarProps {
    setSnackbar: (snackbar: GlobalSnackbarProps) => void;
}

export const GlobalSnackbarContext = createContext<WritableGlobalSnackbarProps>(null!);

interface GlobalSnackbarProviderProps {
    children: JSX.Element | JSX.Element[];
}

function GlobalSnackbarProvider(props: GlobalSnackbarProviderProps) {
    const [snackbar, setSnackbar] = useReducer(
        (state: GlobalSnackbarProps, newState: Partial<GlobalSnackbarProps>) => ({ ...state, ...newState }),
        { open: false }
    );

    return (
        <GlobalSnackbarContext.Provider value={{ ...snackbar, setSnackbar: snackbar => setSnackbar(snackbar) }}>
            {props.children}
        </GlobalSnackbarContext.Provider>
    );
}

export default GlobalSnackbarProvider;
