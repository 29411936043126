import CloseIcon from '@mui/icons-material/Close';
import { DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import React from 'react';
import { StyledEditDialog } from './EditDialog.style';

export interface EditDialogProps {
    open: boolean;
    handleClose: () => void;
    id?: string;
    entityName: string;
    description: {
        new: string;
        edit: string;
    }
    children: JSX.Element;
}

function EditDialog(props: EditDialogProps) {
    const { open, handleClose, id, entityName, description, children } = props;

    return (
        <StyledEditDialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {entityName + (!!id ? ' bearbeiten' : ' anlegen')}
                    <IconButton>
                        <CloseIcon onClick={handleClose} />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {!!props.id ? description.edit : description.new}
                </DialogContentText>
                {children}
            </DialogContent>
        </StyledEditDialog>
    );
}

export default EditDialog;
