import {Drawer, styled} from "@mui/material";

export const StyledBottomNavigation = styled(Drawer)`    
    > .MuiPaper-root {
        > .close-item {
            display: flex;
            justify-content: flex-end;
            
            > .MuiButtonBase-root {
                flex-direction: row-reverse;
                
                > .MuiListItemIcon-root {
                    min-width: auto;
                }
            }
        }
    }
`;
