import EditIcon from '@mui/icons-material/Edit';
import FolderIcon from '@mui/icons-material/Folder';
import { IconButton, Tooltip } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { Activity } from '../../../entities/Activity/Activity';
import { OfflineCheckbox } from '../OfflineCheckbox/OfflineCheckbox';
import '../../../common/lang/dates';

export const ActivityColumns = (
    handleEditClick: (id?: string) => void,
    redirect?: (route: string) => void
): GridColumns =>
    [
        {
            field: 'id',
            hide: true,
        },
        {
            field: 'dateTime',
            headerName: 'Datum',
            flex: 1,
            valueFormatter: ({ value }) => new Date(value as string).toGermanLocaleString()
        },
        {
            field: 'number',
            headerName: 'Aktivitätsnummer',
            flex: 3
        },
        {
            field: 'operation',
            headerName: 'Durchführung',
            flex: 3
        },
        {
            field: 'comment',
            headerName: 'Bemerkung',
            flex: 3,
            sortable: false
        },
        {
            field: 'offlineAvailable',
            type: 'actions',
            headerName: 'Offline verfügbar',
            flex: 0.75,
            align: 'center',
            getActions: (params) => {
                const activityId = (params.row as Activity).id;
                return [<OfflineCheckbox activityId={activityId} />]
            }
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            width: 110,
            disableColumnMenu: true,
            sortable: false,
            align: 'center',
            getActions: (params) => {
                const openActivity = redirect
                    ? <Tooltip title={'Aktivität öffnen'}>
                        <IconButton>
                            <FolderIcon onClick={() => redirect!(params.row.id)} />
                        </IconButton>
                    </Tooltip>
                    : <></>;

                return [
                    openActivity,
                    <Tooltip title={'Bearbeiten'}>
                        <IconButton>
                            <EditIcon onClick={() => handleEditClick(params.row.id)} />
                        </IconButton>
                    </Tooltip>
                ];
            }
        }
    ];
