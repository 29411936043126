import styled from 'styled-components';

export const StyledActivity = styled.div`
  height: 100%;
  display: flex;
  
  > .description {
    margin-right: 15px;
    padding: 15px;
    width: 400px;
  }
`;
