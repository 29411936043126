import {StyledPosition} from "../Position.style";
import {OfflinePosition} from "../../../../entities/Position/Position";
import {IconButton} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {useState} from "react";
import {ErstePositionDialog} from "../ErstePosition/Dialog/ErstePositionDialog";
import {ZweitePositionDialog} from "./Dialog/ZweitePositionDialog";
import { Maybe } from "graphql/jsutils/Maybe";

interface PositionProps extends OfflinePosition {
}

const ZweitePosition = (props: PositionProps): JSX.Element => {
    const [auswahl, setAuswahl] = useState<Maybe<string>>();
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    return (
        <StyledPosition>
            <div className={'details'}>
                <span className={'nummer'}>2.</span>
                <span>Planumsart</span>
            </div>
            <div className={'text'}>
                <span>{auswahl}</span>
            </div>
            <div className={'buttons'}>
                <IconButton color={'primary'} onClick={() => setIsDialogOpen(true)}>
                    <EditIcon/>
                </IconButton>
            </div>

            <ZweitePositionDialog
                isOpen={isDialogOpen}
                setIsOpen={setIsDialogOpen}
                setAuswahl={setAuswahl}
                auswahl={auswahl}
            />
        </StyledPosition>
    );
};

export {ZweitePosition};
