import {useState} from "react";
import {StyledPosition} from "../Position.style";
import {IconButton} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {FundePositionDialog} from "./Dialog/FundePositionDialog";
import { Maybe } from "../../Maybe";

interface FundePositionProps {
    nummer: number;
}

const FundePosition = (props: FundePositionProps): JSX.Element => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [funde, setFunde] = useState<ReadonlyArray<Maybe<string>>>([]);

    return (
        <StyledPosition>
            <div className={'details'}>
                <span className={'nummer'}>{props.nummer}</span>
                <span>Funde</span>
            </div>
            <div className={'text'}>
                <span>
                    {funde[0] === 'Freitext' 
                        ? `${funde[1]} aus Pos. ${funde[2]}` 
                        : `${funde[0]} aus Pos. ${funde[2]}`
                    }
                </span>
            </div>
            <div className={'buttons'}>
                <IconButton color={'primary'} onClick={() => setIsDialogOpen(true)}>
                    <EditIcon/>
                </IconButton>
            </div>

            <FundePositionDialog
                isOpen={isDialogOpen}
                setIsOpen={setIsDialogOpen}
                funde={funde}
                setFunde={setFunde}
            />
        </StyledPosition>
    );
};

export {FundePosition};
