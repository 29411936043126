import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { OfflinePosition, Position } from '../../entities/Position/Position';
import { BaseService, GraphQLService } from '../BaseService';
import { Service } from '../Service';
import {
    ADD_POSITION,
    FILTER_POSITIONS_BY_DIGIT_ID,
    GET_ALL_POSITIONS,
    GET_POSITION,
    REMOVE_POSITION,
    UPDATE_POSITION
} from './PositionsSchemas';

export class PositionsService implements Service<Position | OfflinePosition> {
    constructor(private readonly client: ApolloClient<NormalizedCacheObject>) {
        this.baseClient = new BaseService(
            GET_POSITION,
            GET_ALL_POSITIONS,
            ADD_POSITION,
            UPDATE_POSITION,
            REMOVE_POSITION,
            client
        );
    }

    private readonly baseClient: GraphQLService<Position | OfflinePosition>;

    public getAll = () =>
        this.baseClient.getAll('allPositions');

    public get = (id: string) =>
        this.baseClient.get(id, 'position');

    public filterBy = (digitId: string) =>
        this.client
            .query<{ filterPositionsByDigitId: Position[] }, { digitId: string }>(
                { query: FILTER_POSITIONS_BY_DIGIT_ID, variables: { digitId } }
            )
            .then(result => result.data.filterPositionsByDigitId);

    public add = (position: Position) =>
        this.baseClient.add(position, 'addPosition', { position })
            .then(position => position ?? null);

    public update = (position: Position) =>
        // TODO: Warum 2x Position rein???
        this.baseClient.update(position, 'updatePosition', { position })
            .then(position => position ?? null);

    public remove = (ids: string[]) =>
        this.baseClient.remove(ids, 'removePositions');
}
