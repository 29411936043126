import { Autocomplete, TextField } from "@mui/material";
import { Maybe } from "graphql/jsutils/Maybe";

interface SedimentGapTextProps {
    setSediment: (sediment: Maybe<Array<string>>) => void;
    sediment: Maybe<Array<string>>;
}

const SedimentGapText = (props: SedimentGapTextProps) => {
    const SEDIMENT_OPTIONS = ['schluffig', 'lehmig', 'sandig', 'vergleyt'];

    return (
        <Autocomplete
            multiple
            freeSolo
            size={'small'}
            options={SEDIMENT_OPTIONS}
            renderInput={(params) => <TextField {...params} label={'Sediment'}/>}
            value={props.sediment ?? []}
            onChange={(_, value) => props.setSediment(value)}
            sx={{width: 700}}
        />
    );
}

export { SedimentGapText };

