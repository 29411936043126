import React, { useState } from "react";
import { StyledPositionDialog } from "../../PositionDialog.style";
import { Autocomplete, DialogContent, TextField } from "@mui/material";
import { PositionDialogButtons } from "../../PositionDialogButtons";
import { NUMBER_FIELD_XS_WIDTH } from "../../constants";
import { Maybe } from "../../../Maybe";

interface PositionDialog {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    erweiterungNach: Maybe<string>;
    setErweiterungNach: (erweiterungNach: Maybe<string>) => void;
}

const ErweiterungNachPositionDialog = (props: PositionDialog) => {
    const onDialogClose = () => {
        props.setIsOpen(false);
    };

    return (
        <StyledPositionDialog open={props.isOpen} onClose={onDialogClose}>
            <DialogContent>
                <span>Erweiterung nach</span>
                <TextField multiline size={'small'} sx={{ width: 200 }} label={''} value={props.erweiterungNach} onChange={e => props.setErweiterungNach(e.target.value)} />
            </DialogContent>
            <PositionDialogButtons setIsOpen={props.setIsOpen} />
        </StyledPositionDialog>
    );
};

export { ErweiterungNachPositionDialog };
