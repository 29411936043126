import React, { useState } from "react";
import { StyledPositionDialog } from "../../PositionDialog.style";
import { Autocomplete, DialogContent, TextField } from "@mui/material";
import { PositionDialogButtons } from "../../PositionDialogButtons";
import { NUMBER_FIELD_XS_WIDTH } from "../../constants";
import { Maybe } from "../../../Maybe";

type ErneutAufgedecktOption = 'AB' | 'Profil' | 'Freitext';
const ERNEUT_AUFGEDECKT_OPTIONS: ReadonlyArray<ErneutAufgedecktOption> = ['AB', 'Profil', 'Freitext'];

interface PositionDialog {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    erneutAufgedeckt: ReadonlyArray<Maybe<string>>;
    setErneutAufgedeckt: (erneutAufgedeckt: ReadonlyArray<Maybe<string>>) => void;
}

const ErneutAufgedecktPositionDialog = (props: PositionDialog) => {
    // const [aufgedecktIn, setAufgedecktIn] = useState<ErneutAufgedecktOption | null>(props.erneutAufgedeckt[0] as ErneutAufgedecktOption);
    const onDialogClose = () => {
        props.setIsOpen(false);
    };

    return (
        <StyledPositionDialog open={props.isOpen} onClose={onDialogClose}>
            <DialogContent>
                <span>Erneut aufgedeckt in</span>
                <Autocomplete
                    size={'small'}
                    options={ERNEUT_AUFGEDECKT_OPTIONS}
                    renderInput={(params) => <TextField {...params} label={''} />}
                    value={props.erneutAufgedeckt[0]}
                    onChange={(_, value) => props.setErneutAufgedeckt([value, props.erneutAufgedeckt[1]])}
                    sx={{ width: 220, opacity: props.erneutAufgedeckt[0] === 'Freitext' ? .5 : 1 }}
                />
                <TextField size={'small'} sx={{ width: 200 }} label={''} value={props.erneutAufgedeckt[1]} onChange={e => props.setErneutAufgedeckt([props.erneutAufgedeckt[0], e.target.value])} />
            </DialogContent>
            <PositionDialogButtons setIsOpen={props.setIsOpen} />
        </StyledPositionDialog>
    );
};

export { ErneutAufgedecktPositionDialog };
