import { Digit, OfflineDigit } from '../../entities/Digit/Digit';
import { BaseRepository } from '../BaseRepository';
import DocumentaRhenaniaDB from '../DocumentaRhenaniaDB';
import { PositionsRepository } from '../PositionsRepository/PositionsRepository';

export class DigitsRepository extends BaseRepository<Digit | OfflineDigit> {
    readonly positionsRepo: PositionsRepository;

    constructor(context: DocumentaRhenaniaDB, positionsRepo: PositionsRepository) {
        super(context.digits);
        this.positionsRepo = positionsRepo;
    }

    public delete = (digitId: string) => this.table
        .delete(digitId)
        .then(() => this.deleteRelatedPositionsOf([digitId]));

    public deleteRange = (digitIds: string[]) => this.table
        .bulkDelete(digitIds)
        .then(() => this.deleteRelatedPositionsOf(digitIds));

    private deleteRelatedPositionsOf = async (digitIds: string[]) => {
        let relatedPositionIds = [];
        for (const digitId of digitIds) {
            const positionIds = (await this.positionsRepo.filter(position => position.digitId === digitId))
                .map(it => it.id);
            relatedPositionIds.push(...positionIds);
        }
        await this.positionsRepo.deleteRange(relatedPositionIds);
    }
}
