import {Dialog, styled} from "@mui/material";

const StyledPositionDialog = styled(Dialog)`
  .MuiPaper-root {
    min-width: 600px;
    max-width: 1400px;
    
    > .MuiDialogContent-root {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: .5rem;
        
        > .planum-beschreibung {
            display: flex;
            flex-direction: column;
            gap: .5rem;
        }
        
        > .zeichnung {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            
            > div {
                display: flex;
                align-items: center;
                gap: .5rem;
            }
        }
    }
  }
`;

export {StyledPositionDialog};
