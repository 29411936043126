import { Card, styled } from '@mui/material';

export const StyledDataGridWithActions = styled(Card)`
  background: none;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  
  > .MuiCardActions-root {
    > .separator {
      width: 1px;
      height: 120%;
      background: lightgray;
      margin-left: 8px;
    }
  }
`;
