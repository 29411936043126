import { useState } from 'react';

function useRemoveDialog() {
    // state
    const [isRemoveDialogOpen, setOpen] = useState(false);

    // functions to open or close the remove dialog
    const openRemoveDialog = () => setOpen(true);
    const closeRemoveDialog = () => setOpen(false);

    return { isRemoveDialogOpen, openRemoveDialog, closeRemoveDialog };
}

export default useRemoveDialog;
