import { ApolloProvider } from '@apollo/client';
import React, { createContext, useEffect, useState } from 'react';
import { GraphQLClient } from '../GraphQLClient';
import { ServicesContainer } from '../ServicesContainer';

export const ServicesContext = createContext<{ services: ServicesContainer, setToken: (token: string) => void }>(null!);

interface ServicesProviderProps {
    children: JSX.Element | JSX.Element[];
}

function ServicesProvider(props: ServicesProviderProps) {
    const [token, setToken] = useLocalStorage({ key: 'token', value: '' });

    const [client, setClient] = useState(GraphQLClient(token));
    useEffect(() => {
        setClient(GraphQLClient(token));
    }, [token]);

    const services = new ServicesContainer(client);

    return (
        <ApolloProvider client={client}>
            <ServicesContext.Provider value={{ services, setToken }}>
                {props.children}
            </ServicesContext.Provider>
        </ApolloProvider>
    );
}

const useLocalStorage = (props: { key: string; value: string; }) => {
    const [storedValue, setStoredValue] = useState(() => {
        if (typeof window === "undefined") {
            return props.value;
        }
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(props.key);
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : props.value;
        } catch (error) {
            // If error also return initialValue
            console.log(error);
            return props.value;
        }
    });

    const setValue = (value: string) => {
        try {
            // Save state
            setStoredValue(value);
            // Save to local storage
            if (typeof window !== "undefined") {
                window.localStorage.setItem(props.key, JSON.stringify(value));
            }
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    };
    return [storedValue, setValue];
}

export default ServicesProvider;
