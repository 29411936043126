import { useMemo, useState } from "react";
import { StyledPosition } from "../Position.style";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { AnlageProfilPositionDialog } from "./Dialog/AnlageProfilPositionDialog";
import { Maybe } from "graphql/jsutils/Maybe";

interface AnlageProfilPositionProps {
    nummer: number;
    isDialogOpen?: boolean;
}

const AnlageProfilPosition = (props: AnlageProfilPositionProps): JSX.Element => {
    const [isDialogOpen, setIsDialogOpen] = useState(!!props.isDialogOpen);
    const [anlageProfilPosition, setAnlangeProfilPosition] = useState<Maybe<[Maybe<string>, Maybe<string>]>>(['', '']);

    const zusammengesetzterText = useMemo(() => anlageProfilPosition?.join(', '), [anlageProfilPosition]);

    return (
        <StyledPosition>
            <div className={'details'}>
                <span className={'nummer'}>{props.nummer}</span>
                <span>Anlage Profil</span>
            </div>
            <div className={'text'}>
                <span>{zusammengesetzterText}</span>
            </div>
            <div className={'buttons'}>
                <IconButton color={'primary'} onClick={() => setIsDialogOpen(true)}>
                    <EditIcon />
                </IconButton>
            </div>

            <AnlageProfilPositionDialog
                isOpen={isDialogOpen}
                setIsOpen={setIsDialogOpen}
                anlageProfilPosition={anlageProfilPosition}
                setAnlageProfilPosition={setAnlangeProfilPosition}
            />
        </StyledPosition>
    );
};

export { AnlageProfilPosition };
