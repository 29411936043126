import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    background-color: #e8e8e8;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }

  #root, .app {
    width: 100%;
    height: 100%;
  }

  .app {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;

    .MuiButtonBase-root.Mui-disabled {
      cursor: not-allowed;
      pointer-events: unset;
    }
  }

  // Animations
  @keyframes spin {
    100% {
      transform: rotate(-360deg);
    }
  }
`;
