import { createContext } from 'react';
import { RepositoriesContainer } from '../RepositoriesContainer';

export const RepositoriesContext = createContext<RepositoriesContainer>(null!);

interface RepositoriesProviderProps {
    children: JSX.Element | JSX.Element[];
}

function RepositoriesProvider(props: RepositoriesProviderProps) {
    const repositories = new RepositoriesContainer();

    return (
        <RepositoriesContext.Provider value={repositories}>
            {props.children}
        </RepositoriesContext.Provider>
    );
}

export default RepositoriesProvider;
