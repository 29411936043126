import React from "react";
import { StyledPositionDialog } from "../../PositionDialog.style";
import { DialogContent, TextField } from "@mui/material";
import { PositionDialogButtons } from "../../PositionDialogButtons";
import { Maybe } from "../../../Maybe";

interface PositionDialog {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    aushubkasten: Maybe<string>;
    setAushubkasten: (aushubkasten: Maybe<string>) => void;
}

const AushubKastenPositionDialog = (props: PositionDialog) => {
    const onDialogClose = () => {
        props.setIsOpen(false);
    };

    return (
        <StyledPositionDialog open={props.isOpen} onClose={onDialogClose}>
            <DialogContent>
                <TextField size={'small'} label={''} value={props.aushubkasten} onChange={e => props.setAushubkasten(e.target.value)} />
            </DialogContent>
            <PositionDialogButtons setIsOpen={props.setIsOpen} />
        </StyledPositionDialog>
    );
};

export { AushubKastenPositionDialog };
