import { useLiveQuery } from 'dexie-react-hooks';
import { createContext, useContext, useEffect, useState } from 'react';
import { InternetConnection } from '../../entities/InternetConnection/InternetConnection';
import { RepositoriesContext } from './RepositoriesProvider';

interface InternetConnectionContextProps {
    hasInternetConnection: boolean;
    appNeedsSync: boolean;
    setAppNeedsSync: (appNeedsSync: boolean) => void;
}

interface InternetConnectionProviderProps {
    children: JSX.Element | JSX.Element[];
}

export const InternetConnectionContext = createContext<InternetConnectionContextProps>({
    hasInternetConnection: false,
    appNeedsSync: true,
    setAppNeedsSync: (appNeedsSync: boolean) => undefined
});

function RepositoriesProvider(props: InternetConnectionProviderProps) {
    // contexts
    const { internetConnectionRepo } = useContext(RepositoriesContext);

    // states
    const [hasInternetConnection, setHasInternetConnection] = useState(false);
    const [appNeedsSync, setAppNeedsUpdateSync] = useState(false);

    // functions
    const updateConnectionInfo = (ic: InternetConnection) => {
        const oldHasInternetConnection = hasInternetConnection;
        const newHasInternetConnection = ic.status === 'online';
        setHasInternetConnection(newHasInternetConnection);
        if (oldHasInternetConnection === false && newHasInternetConnection === true) {
            setAppNeedsUpdateSync(true);
        }
    }

    // hooks
    const internetConnection = useLiveQuery(
        () => internetConnectionRepo.getLastConnection(),
        [internetConnectionRepo]
    );

    // when service worker changes internet connection state
    useEffect(() => {
        internetConnection?.match({
            some: updateConnectionInfo,
            none: () => setHasInternetConnection(false)
        })
    }, [internetConnection]);

    // for first load of app
    useEffect(() => {
        internetConnectionRepo.getLastConnection().then(it => it.match({
            some: updateConnectionInfo,
            none: () => setHasInternetConnection(false)
        }));
    }, []);

    return (
        <InternetConnectionContext.Provider value={{
            hasInternetConnection,
            appNeedsSync,
            setAppNeedsSync: (appNeedsSync: boolean) => setAppNeedsUpdateSync(appNeedsSync)
        }}>
            {props.children}
        </InternetConnectionContext.Provider>
    );
}

export default RepositoriesProvider;
