import {Position} from "./Positionen/Position";
import React, {useEffect, useRef, useState} from "react";
import {StyledStellenkarte} from "./Stellenkarte.style";
import {MOCKED_POSITIONS} from "./data";
import {ErstePosition} from "./Positionen/ErstePosition/ErstePosition";
import {ZweitePosition} from "./Positionen/ZweitePosition/ZweitePosition";
import {DrittePosition} from "./Positionen/DrittePosition/DrittePosition";
import {BeschreibungPosition} from "./Positionen/Beschreibung/BeschreibungPosition";
import {Autocomplete, Button, Dialog, DialogActions, DialogContent, TextField} from "@mui/material";
import {FotoPosition} from "./Positionen/Foto/FotoPosition";
import {ZeichnungPositionDialog} from "./Positionen/Zeichnung/Dialog/ZeichnungPositionDialog";
import {ZeichnungPosition} from "./Positionen/Zeichnung/ZeichnungPosition";
import {AnlageProfilPosition} from "./Positionen/AnlageProfil/AnlageProfilPosition";
import {AushubKastenPosition} from "./Positionen/AushubKasten/AushubKastenPosition";
import {FundePosition} from "./Positionen/Funde/FundePosition";
import {TechnischeBemerkungPosition} from "./Positionen/TechnischeBemerkung/TechnischeBemerkungPosition";
import {EntnahmeRestbefundPosition} from "./Positionen/EntnahmeRestbefund/EntnahmeRestbefundPosition";
import {ErneutAufgedecktPosition} from "./Positionen/ErneutAufgedeckt/ErneutAufgedecktPosition";
import {DeutungPosition} from "./Positionen/Deutung/DeutungPosition";
import {EndeDerFeldarbeitPosition} from "./Positionen/EndeDerFeldarbeit/EndeDerFeldarbeitPosition";
import {StrukturenErkanntPosition} from "./Positionen/StrukturenErkannt/StrukturenErkanntPosition";
import {ErweiterungNachPosition} from "./Positionen/ErweiterungNach/ErweiterungNachPosition";
import {PositionDialogButtons} from "./Positionen/PositionDialogButtons";

const Stellenkarte = () => {
    const [stellenkarteHeight, setStellenkarteHeight] = useState<string>('100%');
    const stellenkarteRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const currentStellenkarte = stellenkarteRef.current;
        if (!!currentStellenkarte) {
            setStellenkarteHeight(`${currentStellenkarte.clientHeight}px`);
        }
    }, [stellenkarteRef])

    const [openDialog, setOpenDialog] = useState(false);
    const [dynamicPositionen, setDynamicPositionen] = useState<ReadonlyArray<JSX.Element>>([
        <ErstePosition {...MOCKED_POSITIONS[0]} />,
        <ZweitePosition {...MOCKED_POSITIONS[1]} />,
        <DrittePosition {...MOCKED_POSITIONS[2]} />,
    ]);

    return (
        <StyledStellenkarte height={stellenkarteHeight} ref={stellenkarteRef}>
            {dynamicPositionen}
            {/*Ab hier wird dynamisch entschieden, welche Position als nächstes kommt.*/}
            <Button
                variant={'contained'} color={'secondary'}
                onClick={() => setOpenDialog(true)}
            >
                Neue Position hinzufügen
            </Button>
            <Dialog open={openDialog}>
                <DialogContent>
                    <Autocomplete
                        size={'small'}
                        options={POSITION_OPTIONS}
                        renderInput={(params) => <TextField {...params} label={'Position auswählen'}/>}
                        onChange={(_, value) => {
                            setDynamicPositionen(prev => [...prev, selectPosition(value, dynamicPositionen.length + 1)]);
                            setOpenDialog(false);
                        }}
                        sx={{width: 240}}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        color={'primary'}
                        variant={'text'}
                        size={'small'}
                        onClick={() => setOpenDialog(false)}
                    >
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>
        </StyledStellenkarte>
    );
}

const POSITION_OPTIONS = [
    'Anlage Profil',
    'Aushub Kasten',
    'Beschreibung',
    'Deutung',
    'Ende der Feldarbeit',
    'Entnahme Restbefund',
    'Erneut aufgedeckt',
    'Erweiterung nach',
    'Foto',
    'Funde',
    'Strukturen erkannt',
    'Technische Bemerkung',
    'Zeichnung'
];

const selectPosition = (position: string | null, nummer: number) => {
    switch (position) {
        case 'Anlage Profil':
            return <AnlageProfilPosition nummer={nummer} isDialogOpen />;
        case 'Aushub Kasten':
            return <AushubKastenPosition nummer={nummer}/>;
        case 'Beschreibung': return <BeschreibungPosition nummer={nummer} isDialogOpen />;
        case 'Deutung':
            return <DeutungPosition nummer={nummer}/>;
        case 'Ende der Feldarbeit':
            return <EndeDerFeldarbeitPosition nummer={nummer}/>;
        case 'Entnahme Restbefund':
            return <EntnahmeRestbefundPosition nummer={nummer}/>;
        case 'Erneut aufgedeckt':
            return <ErneutAufgedecktPosition nummer={nummer}/>;
        case 'Erweiterung nach':
            return <ErweiterungNachPosition nummer={nummer}/>;
        case 'Foto':
            return <FotoPosition nummer={nummer}/>;
        case 'Funde':
            return <FundePosition nummer={nummer}/>;
        case 'Strukturen erkannt':
            return <StrukturenErkanntPosition nummer={nummer}/>
        case 'Technische Bemerkung':
            return <TechnischeBemerkungPosition nummer={nummer}/>;
        case 'Zeichnung':
            return <ZeichnungPosition nummer={nummer}/>;
        default:
            return <EndeDerFeldarbeitPosition nummer={nummer}/>;
    }
}

export {Stellenkarte};
