import Dexie, { DexieError } from 'dexie';
import { Formik, FormikErrors, FormikTouched } from 'formik';
import React, { useContext} from 'react';
import { OptionalObjectSchema } from 'yup/lib/object';
import { BaseEntity } from '../../entities/BaseEntity';
import { Service } from '../../graphql/Service';
import { GlobalSnackbarContext } from '../Snackbar/contexts/GlobalSnackbarProvider';
import { StyledEditDialogForm } from './EditDialogForm.style';

interface EditDialogFormProps<T extends BaseEntity> {
    id: string | undefined;
    entityName: { singular: string; plural: string };
    handleClose: () => void;
    service: Service<T>;
    updateRow: (entity: T | null, method: 'update' | 'add') => void;
    initialValues: T;
    validationSchema: OptionalObjectSchema<any>;
    children: (formProps: {
        values: T,
        errors: FormikErrors<T>,
        touched: FormikTouched<T>,
        isSubmitting: boolean
    }) => JSX.Element | JSX.Element[];
}

function EditDialogForm<T extends BaseEntity>(props: EditDialogFormProps<T>) {
    // props
    const { id, entityName, service, updateRow, initialValues, children } = props;

    // state & context
    const { setSnackbar } = useContext(GlobalSnackbarContext);

    // functions
    const handleCloseWhenSuccess = (message: string) => {
        props.handleClose();
        setSnackbar({ open: true, severity: 'success', message });
    }

    const catchConstraintError = (error: Error, name: string, handleSubmit: (submitting: boolean) => void) => {
        // TODO: Das Ding muss komplett umgeschrieben werden, da wir nun fully mit GraphQL Errors arbeiten. Das heißt,
        //       Dexie Errors müssen wir im Service Worker bearbeiten und dann für das Frontend zu einem GraphQL Error
        //       machen.
        let message = 'Ein unbekannter Fehler ist aufgetreten. Kontaktiere bitte Nikolas. 😁';
        if (error instanceof Dexie.ModifyError || (error as DexieError).name === 'ConstraintError') {
            message = 'Beim Speichern ist ein Fehler aufgetreten.';
        }
        setSnackbar({
            open: true,
            severity: 'error',
            message
        });
        handleSubmit(false);
    };

    const save = (entity: T, handleSubmit: (submitting: boolean) => void) => {
        let successMessage = entityName.singular;
        let handleSave;
        let method: 'update' | 'add';

        if (!!id) {
            successMessage += ' wurde erfolgreich aktualisiert.';
            method = 'update';
            handleSave = service.update(entity);
        } else {
            successMessage += ' wurde erfolgreich angelegt.';
            method = 'add';
            handleSave = service.add(entity);
        }

        handleSave
            .then(it => {
                updateRow(it, method);
                handleCloseWhenSuccess(successMessage);
            })
            .catch((error) => catchConstraintError(error, '', handleSubmit)); // TODO: Hier muss mit GraphQL errors gearbeitet werden.
    }

    return (
        <Formik<T>
            enableReinitialize
            initialValues={initialValues}
            // validationSchema={props.validationSchema} TODO: Warum klappt das nicht?
            onSubmit={(values, formikHelpers) => save(values, formikHelpers.setSubmitting)}
        >
            {({ values, errors, touched, handleSubmit, isSubmitting }) =>
                <StyledEditDialogForm onSubmit={handleSubmit}>
                    {children({ values, errors, touched, isSubmitting })}
                </StyledEditDialogForm>
            }
        </Formik>
    );
}

export default EditDialogForm;
