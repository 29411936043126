import React from 'react';
import FreeTextsDataGrid from '../../components/FreeTextsDataGrid/FreeTextsDataGrid';
import BasePage from '../BasePage/BasePage';

function FreeTexts() {
    const entityName = { singular: 'Freitext', plural: 'Freitexte' };

    return (
        <BasePage title={entityName.plural}>
            <FreeTextsDataGrid entityName={entityName} />
        </BasePage>
    );
}

export default FreeTexts;
