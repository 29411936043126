import { TextField } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import React, { useContext, useEffect, useState } from 'react';
import { updateEntities } from '../../../common/DataGrid/addOrUpdateRow';
import { FreeText } from '../../../entities/FreeText/FreeText';
import { OfflinePosition } from '../../../entities/Position/Position';
import { ServicesContext } from '../../../graphql/contexts/ServicesProvider';
import { DigitalPositionCardContext } from '../contexts/DigitalPositionCardProvider';
import { StyledFreeTextInput } from './FreeTextInput.style';
import { v4 as uuidv4 } from 'uuid';

function FreeTextInput(params: GridRenderCellParams) {
    // states
    const [freeTexts, setFreeTexts] = useState<FreeText[]>([]);

    // contexts
    const { positions, setPositions, availableFreeTexts } = useContext(DigitalPositionCardContext);

    // services
    const { positionsService } = useContext(ServicesContext).services;

    // vars
    const currentPosition = params.row as OfflinePosition;

    useEffect(() => {
        const filteredFreeTexts = !!currentPosition.keyword ? availableFreeTexts.filter(it => it.keywordId === currentPosition.keyword!.id) : [];
        setFreeTexts(filteredFreeTexts);
    }, [currentPosition, availableFreeTexts])

    // functions
    const getOrCreateFreeTexts = (freeTextsAsStrings: string[]): FreeText[] => {
        // INFO: Im Backend suchen wir mittlerweile nicht nach den IDs, sondern ob es solch einen Text schon gibt.
        // Vereinfacht vieles und macht auch Sinn. Jetzt ist aber die Frage, ob wir hier die IDs dann überhaupt
        // benötigen, wenn wir die gar nicht zum Identifizieren nutzen. Vielleicht brauchen wir dann nur im Backend
        // IDs? Trotzdem lassen wir das mal so, dass der Freitexte, die einem Keyword zugeordnet sind, raussucht
        // und wiederverwendet. Für alle anderen Freitexte wird immer eine neue ID angelegt, auch wenn es das in
        // der DB gibt. Aber die ID wird dann einfach nicht verwendet, weil wie erwähnt, wir im Backend nach dem Text
        // durchsuchen und nicht nach der ID. Deshalb ist das an dieser Stelle wie gesagt unnötig.
        let newFreeTexts: FreeText[] = [];
        for (const freeTextAsString of freeTextsAsStrings) {
            const aft = freeTexts.find(freeText => freeText.text === freeTextAsString);
            if (!!aft) {
                newFreeTexts.push(aft);
            } else {
                newFreeTexts.push({ id: uuidv4(), text: freeTextAsString, keywordId: null });
            }
        }
        return newFreeTexts;
    }

    return (
        <StyledFreeTextInput
            freeSolo
            multiple
            options={freeTexts.map(it => it.text)}
            defaultValue={currentPosition.freeTexts.map(it => it.text) ?? ''}
            renderInput={(params) => <TextField {...params} />}
            onChange={(_, value) => positionsService
                .update({
                    ...currentPosition,
                    freeTexts: getOrCreateFreeTexts(value as string[])
                })
                .then(newPosition => !!newPosition && setPositions(updateEntities(
                    { ...newPosition, offlineAvailable: currentPosition.offlineAvailable },
                    positions))
                )
            }
        />
    );
}

export default FreeTextInput;
