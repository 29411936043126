import { GridColDef } from '@mui/x-data-grid';
import DateTimeInput from '../DateTimeInput/DateTimeInput';
import FreeTextInput from '../FreeTextInput/FreeTextInput';
import KeywordInput from '../KeywordInput/KeywordInput';
import NumberInput from '../NumberInput/NumberInput';

export const PositionColumns = (): GridColDef[] =>
    [
        {
            field: 'id',
            hide: true
        },
        {
            field: 'digitId',
            hide: true
        },
        {
            field: 'number',
            headerName: 'Nummer',
            headerAlign: 'left',
            width: 150,
            renderCell: NumberInput
        },
        {
            field: 'datetime',
            headerName: 'Datum',
            width: 300,
            renderCell: DateTimeInput
        },
        {
            field: 'keyword',
            headerName: 'Arbeitsschritt',
            width: 300,
            renderCell: KeywordInput
        },
        {
            field: 'freeTexts',
            headerName: 'Beschreibung',
            flex: 1,
            minWidth: 300,
            renderCell: FreeTextInput
        },
        {
            field: 'offlineAvailable',
            hide: true
        }
    ];
