import {
    Autocomplete,
    Checkbox, FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Radio,
    RadioGroup,
    TextField
} from "@mui/material";
import { useState } from "react";
import { StyledEinschluesseGapText } from "./EinschluesseGapText.style";
import { Maybe } from "../../../Maybe";

interface EinschluesseGapTextProps {
    hk: Maybe<{ brocken: Maybe<string>; flitter: Maybe<string>; }>;
    setHk: (hk?: Maybe<{ brocken: Maybe<string>; flitter: Maybe<string>; }>) => void;
    bl: Maybe<{ brocken: Maybe<string>; flitter: Maybe<string>; }>;
    setBl: (bl?: Maybe<{ brocken: Maybe<string>; flitter: Maybe<string>; }>) => void;
    kies: Maybe<{ fG?: Maybe<string>; mG?: Maybe<string>; gG?: Maybe<string>; }>;
    setKies: (kies?: Maybe<{ fG?: Maybe<string>; mG?: Maybe<string>; gG?: Maybe<string>; }>) => void;
    ziegelbruch?: Maybe<string>;
    setZiegelbruch: (ziegelbruch?: Maybe<string>) => void;
    eisenausfaellungen?: Maybe<string>;
    setEisenausfaellungen: (eisenausfaellungen?: Maybe<string>) => void;
    freitext?: Maybe<string>;
    setFreitext: (freitext?: Maybe<string>) => void;
}

const EinschluesseGapText = (props: EinschluesseGapTextProps) => {
    const EINSCHLUESSE_OPTIONS = ['HK', 'BL', 'Kies', 'Ziegelbruch', 'Eisenausfällungen', 'Freitext']

    const [hkChecked, setHkChecked] = useState(!!props.hk);
    const [blChecked, setBlChecked] = useState(!!props.bl);
    const [kiesChecked, setKiesChecked] = useState(!!props.kies);
    const [ziegelbruchChecked, setZiegelbruchChecked] = useState(!!props.ziegelbruch);
    const [eisenausfaellungenChecked, setEisenausfaellungenChecked] = useState(!!props.eisenausfaellungen);

    return (
        <StyledEinschluesseGapText>
            <FormLabel component={'legend'}>Einschlüsse</FormLabel>
            <FormGroup>
                <div className={'checkbox-wrapper'}>
                    <FormControlLabel
                        checked={hkChecked}
                        control={<Checkbox onChange={e => setHkChecked(e.target.checked)} />}
                        label={'HK'}
                    />
                    {hkChecked && <HkInputRenderer hk={props.hk} setHk={props.setHk} />}
                </div>
                <div className={'checkbox-wrapper'}>
                    <FormControlLabel
                        checked={blChecked}
                        control={<Checkbox onChange={e => setBlChecked(e.target.checked)} />}
                        label={'BL'}
                    />
                    {blChecked && <BlInputRenderer bl={props.bl} setBl={props.setBl} />}
                </div>
                <div className={'checkbox-wrapper'}>
                    <FormControlLabel
                        checked={kiesChecked}
                        control={<Checkbox onChange={e => setKiesChecked(e.target.checked)} />}
                        label={'Kies'}
                    />
                    {kiesChecked && <KiesInputRenderer kies={props.kies} setKies={props.setKies} />}
                </div>
                <div className={'checkbox-wrapper'}>
                    <FormControlLabel
                        checked={ziegelbruchChecked}
                        control={<Checkbox onChange={e => setZiegelbruchChecked(e.target.checked)} />}
                        label={'Ziegelbruch'}
                    />
                    {ziegelbruchChecked && <ZiegelbruchInputRenderer ziegelbruch={props.ziegelbruch} setZiegelbruch={props.setZiegelbruch} />}
                </div>
                <div className={'checkbox-wrapper'}>
                    <FormControlLabel
                        checked={eisenausfaellungenChecked}
                        control={<Checkbox onChange={e => setEisenausfaellungenChecked(e.target.checked)} />}
                        label={'Eisenausfällungen'}
                    />
                    {eisenausfaellungenChecked && <EisenausfaellungenInputRenderer eisenausfaellungen={props.eisenausfaellungen} setEisenausfaellungen={props.setEisenausfaellungen} />}
                </div>
                <TextField value={props.freitext} onChange={e => props.setFreitext(e.target.value)} size={'small'} label={'Freitext'} />
            </FormGroup>
        </StyledEinschluesseGapText>
    );
}

interface HkInputRendererProps {
    hk: Maybe<{ brocken: Maybe<string>; flitter: Maybe<string>; }>;
    setHk: (hk: { brocken: Maybe<string>; flitter: Maybe<string>; }) => void;
}

const HkInputRenderer = (props: HkInputRendererProps) => {
    const [flitterChecked, setFlitterChecked] = useState(!!props.hk?.flitter);
    const [brockenChecked, setBrockenChecked] = useState(!!props.hk?.brocken);

    return (
        <FormGroup>
            <div className={'wrapper'}>
                <FormControlLabel
                    checked={flitterChecked}
                    control={<Checkbox onChange={e => setFlitterChecked(e.target.checked)} />}
                    label={'Flitter'}
                />
                {flitterChecked && <Autocomplete
                    freeSolo
                    size={'small'}
                    renderInput={params => <TextField {...params} label={''} />}
                    options={['vereinzelt', 'vermehrt']}
                    value={props.hk?.flitter}
                    onChange={(_, value) => props.setHk({ brocken: props.hk?.brocken, flitter: value })}
                />}
            </div>
            <div className={'wrapper'}>
                <FormControlLabel
                    checked={brockenChecked}
                    control={<Checkbox onChange={e => setBrockenChecked(e.target.checked)} />}
                    label={'Brocken'}
                />
                {brockenChecked && <Autocomplete
                    freeSolo
                    size={'small'}
                    renderInput={params => <TextField {...params} label={''} />}
                    options={['vereinzelt', 'vermehrt']}
                    value={props.hk?.brocken}
                    onChange={(_, value) => props.setHk({ flitter: props.hk?.flitter, brocken: value })}
                />}
            </div>
        </FormGroup>
    );
}

interface BlInputRendererProps {
    bl: Maybe<{ brocken: Maybe<string>; flitter: Maybe<string>; }>;
    setBl: (bl: { brocken: Maybe<string>; flitter: Maybe<string>; }) => void;
}

const BlInputRenderer = (props: BlInputRendererProps) => {
    const [flitterChecked, setFlitterChecked] = useState(!!props.bl?.flitter);
    const [brockenChecked, setBrockenChecked] = useState(!!props.bl?.brocken);

    return (
        <FormGroup>
            <div className={'wrapper'}>
                <FormControlLabel
                    checked={flitterChecked}
                    control={<Checkbox onChange={e => setFlitterChecked(e.target.checked)} />}
                    label={'Flitter'}
                />
                {flitterChecked && <Autocomplete
                    freeSolo
                    size={'small'}
                    renderInput={params => <TextField {...params} label={''} />}
                    options={['vereinzelt', 'vermehrt']}
                    value={props.bl?.flitter}
                    onChange={(_, value) => props.setBl({ brocken: props.bl?.brocken, flitter: value })}
                />}
            </div>
            <div className={'wrapper'}>
                <FormControlLabel
                    checked={brockenChecked}
                    control={<Checkbox onChange={e => setBrockenChecked(e.target.checked)} />}
                    label={'Brocken'}
                />
                {brockenChecked && <Autocomplete
                    freeSolo
                    size={'small'}
                    renderInput={params => <TextField {...params} label={''} />}
                    options={['vereinzelt', 'vermehrt']}
                    value={props.bl?.brocken}
                    onChange={(_, value) => props.setBl({ flitter: props.bl?.flitter, brocken: value })}
                />}
            </div>
        </FormGroup>
    );
}

interface KiesInputRendererProps {
    kies: Maybe<{ fG?: Maybe<string>; mG?: Maybe<string>; gG?: Maybe<string>; }>;
    setKies: (kies?: Maybe<{ fG?: Maybe<string>; mG?: Maybe<string>; gG?: Maybe<string>; }>) => void;
}

const KiesInputRenderer = (props: KiesInputRendererProps) => {
    const [kiesOption, setKiesOption] = useState<Maybe<string>>(
        props.kies?.fG && 'fG' ||
        props.kies?.gG && 'gG' ||
        props.kies?.mG && 'mG'
    );

    return (
        <FormControl>
            <RadioGroup onChange={v => setKiesOption(v.target.value)}>
                <div className={'wrapper'}>
                    <FormControlLabel
                        control={<Radio />}
                        value={'fG'}
                        label={'fG'}
                        checked={!!props.kies?.fG || kiesOption === 'fG'}
                        onChange={() => props.setKies({ fG: '', gG: undefined, mG: undefined })}
                    />
                    {kiesOption === 'fG' && <TextField
                        size={'small'}
                        sx={{ width: 700 }}
                        label={'Freitext'}
                        value={props.kies?.fG}
                        onChange={(e) => props.setKies({ fG: e.target.value, gG: undefined, mG: undefined })}
                    />}
                </div>
                <div className={'wrapper'}>
                    <FormControlLabel
                        control={<Radio />}
                        value={'mG'}
                        label={'mG'}
                        checked={!!props.kies?.mG || kiesOption === 'mG'}
                        onChange={() => props.setKies({ fG: undefined, gG: undefined, mG: '' })}
                    />
                    {kiesOption === 'mG' && <TextField
                        size={'small'}
                        sx={{ width: 700 }}
                        label={'Freitext'}
                        value={props.kies?.mG}
                        onChange={(e) => props.setKies({ fG: undefined, gG: undefined, mG: e.target.value })}
                    />}
                </div>
                <div className={'wrapper'}>
                    <FormControlLabel
                        control={<Radio />}
                        value={'gG'}
                        label={'gG'}
                        checked={!!props.kies?.gG || kiesOption === 'gG'}
                        onChange={(e) => props.setKies({ fG: undefined, gG: '', mG: undefined })}
                    />
                    {kiesOption === 'gG' && <TextField
                        size={'small'}
                        sx={{ width: 700 }}
                        label={'Freitext'}
                        value={props.kies?.gG}
                        onChange={(e) => props.setKies({ fG: undefined, gG: e.target.value, mG: undefined })}
                    />}
                </div>
            </RadioGroup>
        </FormControl>
    );
}

interface ZiegelbruchInputRendererProps {
    ziegelbruch?: Maybe<string>;
    setZiegelbruch: (ziegelbruch?: Maybe<string>) => void;
}

const ZiegelbruchInputRenderer = (props: ZiegelbruchInputRendererProps) => {
    return (
        <Autocomplete
            freeSolo
            size={'small'}
            renderInput={params => <TextField {...params} label={''} />}
            options={['vereinzelt', 'vermehrt']}
            value={props.ziegelbruch}
            onChange={(_, value) => props.setZiegelbruch(value)}
        />
    );
}

interface EisenausfaellungenInputRendererProps {
    eisenausfaellungen?: Maybe<string>;
    setEisenausfaellungen: (eisenausfaellungen?: Maybe<string>) => void;
}

const EisenausfaellungenInputRenderer = (props: EisenausfaellungenInputRendererProps) => {
    return <TextField
        value={props.eisenausfaellungen}
        onChange={(e) => props.setEisenausfaellungen(e.target.value)}
        size={'small'}
        label={'Freitext'}
    />;
}

export { EinschluesseGapText };
