import { BaseEntity } from '../../entities/BaseEntity';
import { updateElementById } from '../arrays/updateElementById';

/**
 * @deprecated Use updateEntities.
 * @param entity
 * @param rows
 * @param setRows
 */
export function updateOrAddRow<T extends BaseEntity>(
    entity: T,
    rows: T[] | undefined,
    setRows: (row: T[]) => void
) {
    if (!entity) return;
    setRows(updateElementById(rows, entity));
}

export interface UpdateRowProp<T> {
    updateRow: (entity: T | null, method: 'update' | 'add') => void;
}

export const updateEntities = <T extends BaseEntity>(newEntity: T, oldEntities: Array<T>) => {
    if (!newEntity) return oldEntities;
    return updateElementById(oldEntities, newEntity);
}
