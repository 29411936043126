import { ApolloConsumer, ApolloProvider } from '@apollo/client';
import { Button, Card, CardActions, CardContent, CardHeader, TextField } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GlobalSnackbarContext } from '../../components/Snackbar/contexts/GlobalSnackbarProvider';
import { ServicesContext } from '../../graphql/contexts/ServicesProvider';
import { LoginService } from '../../graphql/Login/LoginService';
import StyledLogin from './Login.style';

function Login() {
    // contexts
    const { services: { loginService }, setToken } = useContext(ServicesContext);
    const { setSnackbar } = useContext(GlobalSnackbarContext);
    // states
    const [formData, setFormData] = useState<{ email: string; password: string; }>();
    // hooks
    const history = useHistory();

    // funcs
    const setEmail = (value: string) => setFormData(oldState => ({ email: value, password: oldState?.password ?? '' }));
    const setPassword = (value: string) => setFormData(oldState => ({ email: oldState?.email ?? '', password: value }));

    const sendLoginFormData = () =>
        loginService
            .login(formData?.email ?? '', formData?.password ?? '')
            .then(token => !!token && setToken(token))
            .then(() => history.push('/activities'))
            .catch(error => setSnackbar({ open: true, severity: 'error', message: error.message }));

    return (
        <StyledLogin>
            <Card>
                <CardHeader title={'Anmeldung'} />
                <CardContent>
                    <TextField
                        label={'Benutzername'}
                        type={'text'}
                        onChange={event => setEmail(event.target.value)}
                    />
                    <TextField
                        label={'Passwort'}
                        type={'password'}
                        onChange={event => setPassword(event.target.value)}
                    />
                </CardContent>
                <CardActions>
                    <Button
                        variant={'contained'}
                        color={'secondary'}
                        onClick={sendLoginFormData}
                    >
                        Anmelden
                    </Button>
                </CardActions>
            </Card>
        </StyledLogin>
    );
}

export default Login;
