import {Button, DialogActions} from "@mui/material";

interface PositionDialogButtonsProps {
    setIsOpen: (open: boolean) => void;
}

const PositionDialogButtons = (props: PositionDialogButtonsProps) => {
    return (
        <DialogActions>
            <Button
                color={'primary'}
                variant={'text'}
                size={'small'}
                onClick={() => props.setIsOpen(false)}
            >
                Schließen
            </Button>
            <Button
                color={'secondary'}
                variant={'contained'}
                size={'small'}
                onClick={() => props.setIsOpen(false)} // Bei Speichern zum Server schicken 
            >
                Speichern
            </Button>
        </DialogActions>
    );
}

export {PositionDialogButtons};
