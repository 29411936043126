import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, CardActions } from '@mui/material';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { removeRows } from '../../common/DataGrid/removeRows';
import { BaseEntity } from '../../entities/BaseEntity';
import DeleteDialog from '../DeleteDialog/DeleteDialog';
import { GlobalSnackbarContext } from '../Snackbar/contexts/GlobalSnackbarProvider';
import DataGrid from './DataGrid/DataGrid';
import { StyledDataGridWithActions } from './DataGridWithActions.style';
import useDataGridSelection from './hooks/useDataGridSelection';
import useEditDialog from './hooks/useEditDialog';
import useRemoveDialog from './hooks/useRemoveDialog';

interface DataGridWithActionsProps<T extends BaseEntity> {
    entityName: {
        singular: string;
        plural: string;
    };
    redirectTo?: string;
    sortModel: GridSortModel;
    columns: (handleEdit: (id?: string) => void, redirect?: (route: string) => void) => GridColDef[];
    handleRemove: (selectedIds: string[]) => Promise<boolean>;
    rows: T[] | undefined;
    setRows: (rows: T[]) => void;
    children?: (props: { id?: string, open: boolean, handleClose: () => void }) => JSX.Element;
    rowHeight?: number;
    handleAdd?: () => void;
    disabledAdd?: boolean;
    goBackButton?: boolean;
}

function DataGridWithActions<T extends BaseEntity>(props: DataGridWithActionsProps<T>) {
    // props
    const { entityName, redirectTo, sortModel, columns, rows, setRows, handleRemove } = props;

    // state
    const history = useHistory();
    const snackbar = useContext(GlobalSnackbarContext);
    const { selectedIds, onSelectionModelChange } = useDataGridSelection();
    const { isRemoveDialogOpen, openRemoveDialog, closeRemoveDialog } = useRemoveDialog();
    const { editDialogData, handleEditDialogOpen, handleEditDialogClose } = useEditDialog();

    // functions
    const entityString = selectedIds.length > 1 ? entityName.plural : entityName.singular;
    const redirect = redirectTo ? (urlRoute?: string) => history.push(redirectTo + '/' + urlRoute) : undefined;

    const handleRemoveDialogConfirm = () => {
        handleRemove(selectedIds).then(response => {
            if (!response) {
                closeRemoveDialog();
                snackbar.setSnackbar({
                    open: true,
                    severity: 'error',
                    message: 'Löschung konnte nicht erfolgreich ausgeführt werden.'
                });
                return;
            }

            setRows(removeRows(rows ?? [], selectedIds));
            closeRemoveDialog();
            snackbar.setSnackbar({ open: true, severity: 'success', message: 'Löschung erfolgreich ausgeführt.' })
        });
    };

    return (
        <StyledDataGridWithActions>
            <DataGrid
                onSelectionModelChange={onSelectionModelChange}
                sortModel={sortModel}
                columns={columns(handleEditDialogOpen, redirect)}
                rows={rows}
                rowHeight={props.rowHeight}
            />
            <CardActions>
                <Button
                    variant='contained'
                    color='secondary'
                    startIcon={<AddIcon />}
                    disabled={selectedIds.length !== 0 || props.disabledAdd}
                    onClick={!!props.handleAdd ? () => props.handleAdd!() : () => handleEditDialogOpen()}
                >
                    {props.entityName.singular} anlegen
                </Button>
                <Button
                    variant='contained'
                    color='primary'
                    startIcon={<DeleteIcon />}
                    disabled={selectedIds.length < 1}
                    onClick={openRemoveDialog}
                >
                    {entityString} löschen
                </Button>
                {!!props.goBackButton &&
                    <>
                        <div className={'separator'} />
                        <Button
                            variant='contained'
                            color='primary'
                            startIcon={<ArrowBackIcon />}
                            onClick={() => history.goBack()}
                        >
                            Zurück
                        </Button>
                    </>}
            </CardActions>
            <DeleteDialog
                open={isRemoveDialogOpen}
                handleConfirm={handleRemoveDialogConfirm}
                handleDecline={closeRemoveDialog}
                title={entityString + ' löschen?'}
                content={
                    'Möchtest Du die ' + entityString +
                    ' wirklich löschen? Bitte bedenke, dass auch alle zugehörigen Daten mitgelöscht werden.'
                }
                confirmText={'Ja'}
                declineText={'Nein'}
            />
            {!!props.children && props.children({
                id: editDialogData.id,
                open: editDialogData.open,
                handleClose: handleEditDialogClose
            })}
        </StyledDataGridWithActions>
    );
}

export default DataGridWithActions;
