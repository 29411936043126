import React from 'react';
import ActivitiesDataGrid from '../../components/ActivitiesDataGrid/ActivitiesDataGrid';
import BasePage from '../BasePage/BasePage';

function Activities() {
    const entityName = { singular: 'Aktivität', plural: 'Aktivitäten' };

    return (
        <BasePage title={entityName.plural}>
            <ActivitiesDataGrid entityName={entityName} />
        </BasePage>
    );
}

export default Activities;
