import { useReducer } from 'react';

interface EditDialogState {
    open: boolean;
    id?: string;
}

function useEditDialog() {
    // state
    const [editDialogData, setEditDialogData] = useReducer(
        (state: EditDialogState, newState: Partial<EditDialogState>) => ({ ...state, ...newState }),
        { open: false }
    );

    // functions to open or close the edit dialog
    const handleEditDialogOpen = (id?: string) => setEditDialogData({ open: true, id: id });
    const handleEditDialogClose = () => setEditDialogData({ open: false });

    return { editDialogData, handleEditDialogOpen, handleEditDialogClose };
}

export default useEditDialog;
