import { Activity, OfflineActivity } from '../../entities/Activity/Activity';
import { BaseEntity } from '../../entities/BaseEntity';
import { BaseEntityWithSyncOperation } from '../../entities/CachedEntity';
import { Digit } from '../../entities/Digit/Digit';
import { FreeText } from '../../entities/FreeText/FreeText';
import { Keyword } from '../../entities/Keyword/Keyword';
import { Position } from '../../entities/Position/Position';
import { CachedActivitiesRepository } from '../../repository/ActivitiesRepository/CachedActivitiesRepository';
import { CachedBaseRepository } from '../../repository/CachedBaseRepository';
import { CachedDigitsRepository } from '../../repository/DigitsRepository/CachedDigitsRepository';
import { CachedFreeTextsRepository } from '../../repository/FreeTextsRepository/CachedFreeTextsRepository';
import { CachedKeywordsRepository } from '../../repository/KeywordsRepository/CachedKeywordsRepository';
import { CachedPositionsRepository } from '../../repository/PositionsRepository/CachedPositionsRepository';
import { ActivitiesService } from '../Activities/ActivitiesService';
import { DigitsService } from '../Digits/DigitsService';
import { FreeTextsService } from '../FreeTexts/FreeTextsService';
import { KeywordsService } from '../Keywords/KeywordsService';
import { PositionsService } from '../Positions/PositionsService';
import { Service } from '../Service';
import { SyncOperation } from '../SyncOperations';

export class UploadFromRepositoryService {
    constructor(
        private readonly activitiesService: ActivitiesService,
        private readonly cachedActivitiesRepository: CachedActivitiesRepository,
        private readonly digitsService: DigitsService,
        private readonly cachedDigitsRepository: CachedDigitsRepository,
        private readonly positionsService: PositionsService,
        private readonly cachedPositionsRepository: CachedPositionsRepository,
        private readonly keywordsService: KeywordsService,
        private readonly cachedKeywordsRepository: CachedKeywordsRepository,
        private readonly freeTextsService: FreeTextsService,
        private readonly cachedFreeTextsRepository: CachedFreeTextsRepository
    ) {}

    public uploadCachedData = async (): Promise<boolean> => {
        // Maybe hier try catch und erst wenn alles erfolgreich war, aus CachedRepo löschen? Wäre auf jeden Fall
        // sicherer, um Datenverlust zu vermeiden bei Fehlern.
        try {
            await this.uploadCachedActivities();
            await this.uploadCachedDigits();
            await this.uploadCachedPositions();
            await this.uploadCachedKeywords();
            await this.uploadCachedFreeTexts();

            return true;
        } catch (e) {
            throw e;
        }
    }

    private uploadCachedActivities = async () => {
        const toActivity = (activity: BaseEntityWithSyncOperation<Activity>): Activity => ({
            id: activity.id,
            number: activity.number,
            dateTime: activity.dateTime,
            operation: activity.operation,
            comment: activity.comment
        });

        const allCachedActivities = await this.cachedActivitiesRepository.all();
        for (const cachedActivityOperations of allCachedActivities) {
            for (const activity of cachedActivityOperations.entityOperations) {
                await this.syncOperationsOfEntity(activity.syncOperation, toActivity(activity), this.activitiesService);
            }
            this.cachedActivitiesRepository.delete(cachedActivityOperations.id);
        }
    }

    private uploadCachedDigits = async () => {
        const toDigit = (digit: BaseEntityWithSyncOperation<Digit>): Digit => ({
            id: digit.id,
            activityId: digit.activityId,
            dateTime: digit.dateTime,
            number: digit.number,
            comment: digit.comment
        });

        const allCachedDigits = await this.cachedDigitsRepository.all();
        for (const cachedDigitOperations of allCachedDigits) {
            for (const digit of cachedDigitOperations.entityOperations) {
                await this.syncOperationsOfEntity(digit.syncOperation, toDigit(digit), this.digitsService);
            }
            this.cachedDigitsRepository.delete(cachedDigitOperations.id);
        }
    }

    private uploadCachedPositions = async () => {
        const toPosition = (position: BaseEntityWithSyncOperation<Position>): Position => ({
            id: position.id,
            digitId: position.digitId,
            dateTime: position.dateTime,
            number: position.number,
            keyword: position.keyword,
            freeTexts: position.freeTexts
        });

        const allCachedPositions = await this.cachedPositionsRepository.all();
        for (const cachedPositionOperations of allCachedPositions) {
            for (const position of cachedPositionOperations.entityOperations) {
                await this.syncOperationsOfEntity(position.syncOperation, toPosition(position), this.positionsService);
            }
            this.cachedPositionsRepository.delete(cachedPositionOperations.id);
        }
    }

    private uploadCachedKeywords = async () => {
        const toKeyword = (keyword: BaseEntityWithSyncOperation<Keyword>): Keyword => ({
            id: keyword.id,
            name: keyword.name,
            isCsvExport: keyword.isCsvExport
        });

        const allCachedKeywords = await this.cachedKeywordsRepository.all();
        for (const cachedKeywordOperations of allCachedKeywords) {
            for (const keyword of cachedKeywordOperations.entityOperations) {
                await this.syncOperationsOfEntity(keyword.syncOperation, toKeyword(keyword), this.keywordsService);
            }
            this.cachedKeywordsRepository.delete(cachedKeywordOperations.id);
        }
    }

    private uploadCachedFreeTexts = async () => {
        const toFreeText = (freeText: BaseEntityWithSyncOperation<FreeText>): FreeText => ({
            id: freeText.id,
            keywordId: freeText.keywordId,
            text: freeText.text
        });

        const allCachedFreeTexts = await this.cachedFreeTextsRepository.all();
        for (const cachedFreeTextOperations of allCachedFreeTexts) {
            for (const freeText of cachedFreeTextOperations.entityOperations) {
                await this.syncOperationsOfEntity(freeText.syncOperation, toFreeText(freeText), this.freeTextsService);
            }
            this.cachedFreeTextsRepository.delete(cachedFreeTextOperations.id);
        }
    }

    // allCachedActivities.forEach(cachedActivityOperations => {
    //     cachedActivityOperations.entityOperations.forEach(activity => {
    //         this.syncOperationsOfEntity(
    //             activity.syncOperation,
    //             toActivity(activity),
    //             this.activitiesService
    //         );
    //     });
    //
    //     this.cachedActivitiesRepository.delete(cachedActivityOperations.id);
    // });

    // private uploadCachedDigits = async () => {
    //     const allCachedDigits = await this.cachedDigitsRepository.all();
    //     allCachedDigits.forEach(cachedDigitsOperations => {
    //         cachedDigitsOperations.entityOperations.forEach(async digit => {
    //             await this.syncOperationsOfEntity(digit, this.digitsService);
    //         })
    //     })
    // }

    // private uploadCachedEntities = async <T extends BaseEntity>(
    //     cachedRepository: CachedBaseRepository<T>,
    //     service: Service<T>
    // ) => {
    //     const allCachedEntities = await cachedRepository.all();
    //     // console.log('allCachedEntities', allCachedEntities);
    //     // if (allCachedEntities[0]?.entityOperations[0]) {
    //     //     console.log('jupp');
    //     //     await this.syncOperationsOfEntity(allCachedEntities[0].entityOperations[0], service);
    //     // }
    //     // const allRequests = [];
    //     try {
    //         console.log(allCachedEntities.length);
    //         for (const cachedOperationsOfEntity of allCachedEntities) {
    //             console.log('fetch start mit update')
    //             for (const entity of cachedOperationsOfEntity.entityOperations) {
    //                 await this.syncOperationsOfEntity(entity, service)
    //             }
    //
    //             console.log('fetch ende')
    //
    //             cachedRepository.delete(cachedOperationsOfEntity.id);
    //         }
    //
    //         // console.log('Alle Promises', allRequests);
    //
    //         // await new Promise(r => setTimeout(r, 10000));
    //
    //         // await Promise.all(allRequests.map(request => request()));
    //
    //         // console.log('Requests ausgeführt.4');
    //
    //         //     await allCachedEntities.forEach(async cachedOperationsOfEntity => {
    //         //         await cachedOperationsOfEntity.entityOperations.forEach(async entity => {
    //         //             await this.syncOperationsOfEntity<T>(entity, service);
    //         //         });
    //         //         await cachedRepository.delete(cachedOperationsOfEntity.id);
    //         // });
    //
    //     } catch (e) {
    //         throw e;
    //     }
    // }

    private syncOperationsOfEntity = async <T extends BaseEntity>(
        syncOperation: SyncOperation,
        baseEntity: T,
        service: Service<T>
    ): Promise<T | null | boolean> => {
        switch (syncOperation) {
            case SyncOperation.Add:
                return service.add(baseEntity);
            case SyncOperation.Update:
                return service.update(baseEntity);
            case SyncOperation.Delete:
                return service.remove([baseEntity.id]);
        }
    }
}
