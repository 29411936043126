import { gql } from '@apollo/client';

export const GET_ALL_FREETEXTS = gql`
    query GetAll {
        allFreeTexts {
            id
            text
            keywordId
        }
    }
`;

export const GET_ALL_FREETEXTS_WHICH_HAS_KEYWORDID = gql`
    query AllKeywordFreeTexts {
        allKeywordFreeTexts {
            id
            text
            keywordId
        }
    }
`;

export const FILTER_FREETEXTS_BY_KEYWORD_ID = gql`
    query FilterFreeTextsByKeywordId($keywordId: UUID!) {
        filterFreeTextsByKeywordId(keywordId: $keywordId) {
            id
            text
            keywordId
        }
    }
`;

export const GET_FREETEXT = gql`
    query Get($id: UUID!) {
        freeText(id: $id) {
            id
            text
            keywordId
        }
    }
`;

export const UPDATE_FREETEXT = gql`
    mutation Update($freeText: FreeTextInput!) {
        updateFreeText(freeText: $freeText) {
            id
            text
            keywordId
        }
    }
`;

export const ADD_FREETEXT = gql`
    mutation Save($freeText: FreeTextInput!) {
        addFreeText(freeText: $freeText) {
            id
            text
            keywordId
        }
    }
`;

export const REMOVE_FREETEXTS = gql`
    mutation Remove($ids: [UUID!]!) {
        removeFreeTexts(ids: $ids)
    }
`;
