import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Activity } from '../../entities/Activity/Activity';
import { BaseService, GraphQLService } from '../BaseService';
import { Service } from '../Service';
import { ADD_ACTIVITY, GET_ACTIVITY, GET_ALL_ACTIVITIES, REMOVE_ACTIVITIES, UPDATE_ACTIVITY } from './ActivitiesSchema';

export class ActivitiesService implements Service<Activity> {
    constructor(client: ApolloClient<NormalizedCacheObject>) {
        this.baseClient = new BaseService(
            GET_ACTIVITY,
            GET_ALL_ACTIVITIES,
            ADD_ACTIVITY,
            UPDATE_ACTIVITY,
            REMOVE_ACTIVITIES,
            client
        );
    }

    private readonly baseClient: GraphQLService<Activity>;

    public getAll = () =>
        this.baseClient.getAll('allActivities')
            .then(activities => activities.map(activity => ({
                ...activity,
                dateTime: new Date(activity.dateTime)
            } as Activity)));

    public get = (id: string) =>
        this.baseClient.get(id, 'activity')
            .then(activity => !!activity
                ? ({
                    ...activity,
                    dateTime: new Date(activity.dateTime)
                } as Activity)
                : null);

    public add = (activity: Activity) =>
        this.baseClient.add(activity, 'addActivity', { activity })
            .then(activity =>
                !!activity
                    ? ({
                        ...activity,
                        dateTime: new Date(activity.dateTime)
                    } as Activity)
                    : null
            );

    public update = (activity: Activity) =>
        // TODO: Warum 2x Activity rein???
        this.baseClient.update(activity, 'updateActivity', { activity })
            .then(activity => !!activity
                ? ({
                    ...activity,
                    dateTime: new Date(activity.dateTime)
                } as Activity)
                : null);

    public remove = (ids: string[]) =>
        this.baseClient.remove(ids, 'removeActivities');
}
