import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { bool } from 'yup';
import { UpdateRowProp } from '../../../common/DataGrid/addOrUpdateRow';
import { Digit, OfflineDigit } from '../../../entities/Digit/Digit';
import { ServicesContext } from '../../../graphql/contexts/ServicesProvider';
import EditDialogForm from '../../EditDialogForm/EditDialogForm';
import { DIGIT_VALIDATION_SCHEMA } from './validationSchema';
import { Field } from 'formik';
import { TextField } from 'formik-mui';

interface DigitsFormProps extends UpdateRowProp<Digit> {
    id?: string;
    activityId: string;
    offlineAvailable?: boolean;
    handleClose: () => void;
    entityName: { singular: string; plural: string; };
    nextDigitNumber: number;
}

function DigitForm(props: DigitsFormProps) {
    // services
    const { digitsService } = useContext(ServicesContext).services;

    // states
    const [initialValue, setInitialValue] = useState<OfflineDigit>({
        id: uuidv4(),
        activityId: props.activityId,
        number: props.nextDigitNumber,
        dateTime: new Date(),
        offlineAvailable: props.offlineAvailable ?? false
    });

    // load data for entity if entity exists
    useEffect(() => {
        if (!!props.id) {
            digitsService.get(props.id).then(digit => setInitialValue({
                ...digit,
                offlineAvailable: props.offlineAvailable ?? false
            }));
        }
    }, [digitsService, props.id, props.offlineAvailable])

    return (
        <EditDialogForm<Digit | OfflineDigit>
            id={props.id}
            entityName={props.entityName}
            handleClose={props.handleClose}
            service={digitsService}
            updateRow={props.updateRow}
            initialValues={initialValue}
            validationSchema={DIGIT_VALIDATION_SCHEMA}
        >
            {({ values, errors, touched, isSubmitting }) =>
                <>
                    <Field
                        component={TextField}
                        label='Stellennummer *'
                        name='number'
                        type='number'
                        error={touched.number && Boolean(errors.number)}
                        helperText={touched.number && errors.number}
                        fullWidth
                        autoFocus
                        InputProps={{ inputProps: { min: 1 } }}
                        InputLabelProps={{ shrink: !!values.number }}
                    />
                    <Field
                        component={TextField}
                        label='Bemerkung'
                        name='comment'
                        fullWidth
                        InputLabelProps={{ shrink: !!values.comment }}
                    />
                    <Button
                        startIcon={<AddIcon />}
                        color='secondary'
                        variant='contained'
                        type='submit'
                    >
                        {props.entityName.singular} speichern
                    </Button>
                </>
            }
        </EditDialogForm>
    );
}

export default DigitForm;
