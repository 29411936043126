import { Table } from 'dexie';
import { Optional } from '../common/Optional/Optional';
import { BaseEntity } from '../entities/BaseEntity';
import { CachedEntity } from '../entities/CachedEntity';
import { SyncOperation } from '../graphql/SyncOperations';
import { BaseRepository } from './BaseRepository';

export class CachedBaseRepository<T extends BaseEntity> extends BaseRepository<CachedEntity<T>> {
    constructor(readonly table: Table<CachedEntity<T>, string>) {
        super(table);
    }

    public updateEntityOperations = (entity: T, syncOperation: SyncOperation) => {
        this.getEntityIfAvailable(entity.id)
            .then(a => a.match({
                some: it => {
                    const newCachedEntity = {
                        id: it.id,
                        entityOperations: [...it.entityOperations, { ...entity, syncOperation }]
                    } as CachedEntity<T>;
                    this.update(newCachedEntity);
                },
                none: () => {
                    this.add({
                        id: entity.id,
                        entityOperations: [{ ...entity, syncOperation }]
                    } as CachedEntity<T>);
                }
            }));
    }

    private getEntityIfAvailable = (id: string): Promise<Optional<CachedEntity<T>>> =>
        this.get(id).then(entity => {
            if (!!entity) {
                return new Optional(entity);
            }
            return new Optional<CachedEntity<T>>(null);
        });
}
