import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import React, { forwardRef, useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import { GlobalSnackbarContext } from './contexts/GlobalSnackbarProvider';

function GlobalSnackbar() {
    const snackbarContext = useContext(GlobalSnackbarContext);

    const handleSnackbarClose = () => snackbarContext.setSnackbar({ open: false });

    const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
        props,
        ref,
    ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            open={snackbarContext.open}
            onClose={handleSnackbarClose}
            autoHideDuration={6000}
        >
            <Alert
                onClose={handleSnackbarClose}
                severity={snackbarContext.severity}
                action={
                    <IconButton
                        size="small"
                        color="inherit"
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            >
                {snackbarContext.message}
            </Alert>
        </Snackbar>
    );
}

export default GlobalSnackbar;
