import { TextField } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import React, { useContext } from 'react';
import { updateEntities } from '../../../common/DataGrid/addOrUpdateRow';
import { OfflinePosition } from '../../../entities/Position/Position';
import { ServicesContext } from '../../../graphql/contexts/ServicesProvider';
import { DigitalPositionCardContext } from '../contexts/DigitalPositionCardProvider';
import { StyledKeywordInput } from './KeywordInput.style';

function KeywordInput(params: GridRenderCellParams) {
    // contexts
    const { positions, setPositions, availableKeywords } = useContext(DigitalPositionCardContext);

    // services
    const { positionsService } = useContext(ServicesContext).services;

    // vars
    const position = params.row as OfflinePosition;

    // functions
    // TODO: Langfristig nicht notwendig, weil nur aus vorhandenen Keywords auswählen.
    const updateOrCreateKeyword = (keywordName: string) => {
        // Keyword already exists?
        const searchedKeyword = availableKeywords.find(it => it.name === keywordName);

        positionsService
            .update({
                ...position,
                keyword: searchedKeyword
            })
            .then(newPosition => !!newPosition && setPositions(updateEntities(
                { ...newPosition, offlineAvailable: position.offlineAvailable },
                positions))
            );
    }

    return (
        <StyledKeywordInput
            options={availableKeywords.sort((a, b) => a.name.localeCompare(b.name)).map(it => it.name)}
            renderInput={(params) => <TextField {...params} />}
            onChange={(_, value) => updateOrCreateKeyword(value as string)}
            value={position.keyword?.name ?? ''}
        />
    );
}

export default KeywordInput;
