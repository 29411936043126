import styled from 'styled-components';

export const StyledHeader = styled.div`
  grid-column: 1 / 2;
  grid-row: 1 / 2;

  .MuiAppBar-root {
    > .MuiToolbar-root {
      justify-content: space-between;

      > .wrapper {
          display: flex;
          gap: 1rem;
          
          > .menu {
              margin-left: -.5rem;
          }
          
          > .logo {
              display: flex;

              > img {
                  margin-right: 15px;
                  height: 40px;
              }

              > .MuiTypography-root {
                  > sup {
                      font-size: .75rem;
                      font-weight: bold;
                      opacity: .5;
                  }
              }
          }  
      }

      .user-menu {
        display: flex;
        justify-content: center;
        align-items: center;
        
        > .separator {
          height: 40px;
          width: 1px;
          margin: 0 15px;
          background-color: gray;
        }
      }

    }
  }
`;
