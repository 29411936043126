export {};

declare global {
    export interface Date {
        toGermanLocaleString(): string;
        toGermanLocaleStringWithTime(): string;
    }
}

// eslint-disable-next-line no-extend-native
Date.prototype.toGermanLocaleString = function () {
    return this.toLocaleDateString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });
};

// eslint-disable-next-line no-extend-native
Date.prototype.toGermanLocaleStringWithTime = function () {
    return this.toGermanLocaleString() + ' ' + this.toLocaleTimeString('de-DE', {
        hour: '2-digit',
        minute: '2-digit'
    });
};
