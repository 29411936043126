import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Tooltip } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import '../../../common/lang/dates';
import { Keyword } from '../../../entities/Keyword/Keyword';

export const FreeTextColumns = (
    handleEditClick: (id?: string) => void,
    keywords: ReadonlyArray<Keyword>
): GridColDef[] =>
    [
        {
            field: 'id',
            hide: true,
        },
        {
            field: 'text',
            headerName: 'Freitext',
            flex: 1
        },
        {
            field: 'keywordId',
            headerName: 'Arbeitsschritt',
            flex: 1,
            renderCell: (params: GridCellParams) => keywords.find(it => it.id === params.row.keywordId)?.name
        },
        {
            field: 'configButtons',
            headerName: ' ',
            width: 110,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: GridCellParams) => {
                return <>
                    <Tooltip title={'Bearbeiten'}>
                        <IconButton>
                            <EditIcon onClick={() => handleEditClick(params.row.id)} />
                        </IconButton>
                    </Tooltip>
                </>
            },
            align: 'center'
        }
    ];
