import { Optional } from '../../common/Optional/Optional';
import { CustomNetworkInformation, InternetConnection } from '../../entities/InternetConnection/InternetConnection';
import { BaseRepository } from '../BaseRepository';
import DocumentaRhenaniaDB from '../DocumentaRhenaniaDB';
import { v4 as uuidv4 } from 'uuid';

export class InternetConnectionRepository extends BaseRepository<InternetConnection> {
    constructor(private readonly context: DocumentaRhenaniaDB) {
        super(context.internetConnection);
    }

    public async partialUpdate(partialInternetConnection: Partial<InternetConnection>) {
        const internetConnection = (await this.all())[0];

        if (!!internetConnection) {
            const updatedInternetConnection: InternetConnection = {
                ...internetConnection,
                ...partialInternetConnection,
                updatedAt: new Date()
            }
            this.update(updatedInternetConnection);
        } else {
            this.add({
                id: uuidv4(),
                connectionType: (navigator.connection as CustomNetworkInformation).effectiveType,
                status: navigator.onLine ? 'online' : 'offline',
                updatedAt: new Date()
            })
        }
    }

    public async getCurrentConnections() {
        return this.context.internetConnection.toArray();
    }

    public getLastConnection = async (): Promise<Optional<InternetConnection>> => {
        const internetConnections = await this.getCurrentConnections();

        if (internetConnections.length === 0) return new Optional<InternetConnection>(null);
        return new Optional(internetConnections[0]);
    }

    public async isConnectionAvailable(): Promise<boolean> {
        const connection = (await this.getCurrentConnections())[0];
        return connection.status === 'online';
    }
}
