import React, { useState } from "react";
import { StyledPositionDialog } from "../../PositionDialog.style";
import { Autocomplete, DialogContent, TextField } from "@mui/material";
import { PositionDialogButtons } from "../../PositionDialogButtons";
import { Maybe } from "../../../Maybe";
import { NUMBER_FIELD_XS_WIDTH } from "../../constants";

type ZeichnungOption = 'Planum' | 'Profil' | 'Freitext';
const ZEICHNUNG_OPTIONS: ReadonlyArray<ZeichnungOption> = ['Planum', 'Profil', 'Freitext'];

interface PositionDialog {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    zeichnung: ReadonlyArray<Maybe<string>>;
    setZeichnung: (zeichnung: ReadonlyArray<Maybe<string>>) => void;
}

const ZeichnungPositionDialog = (props: PositionDialog) => {
    const onDialogClose = () => {
        props.setIsOpen(false);
    };

    return (
        <StyledPositionDialog open={props.isOpen} onClose={onDialogClose}>
            <DialogContent>
                <Autocomplete
                    size={'small'}
                    options={ZEICHNUNG_OPTIONS}
                    renderInput={(params) => <TextField {...params} label={'Zeichnung von'} />}
                    onChange={(_, value) => props.setZeichnung([value])}
                    sx={{ width: 220, opacity: props.zeichnung[0] === 'Freitext' ? .5 : 1 }}
                />
                {FormInputRenderer(props.zeichnung, props.setZeichnung)}
            </DialogContent>
            <PositionDialogButtons setIsOpen={props.setIsOpen} />
        </StyledPositionDialog>
    );
};

const FormInputRenderer = (zeichnung: ReadonlyArray<Maybe<string>>, setZeichnung: (zeichnung: ReadonlyArray<Maybe<string>>) => void) => {
    type autocompleteOption = '.1:10.' | '.1:20.' | 'Freitext';
    const autocompleteOptions: ReadonlyArray<autocompleteOption> = ['.1:10.', '.1:20.', 'Freitext'];
    const autocompleteJsx = <Autocomplete
        size={'small'}
        options={autocompleteOptions}
        renderInput={(params) => <TextField {...params} label={''} />}
        onChange={(_, value) => setZeichnung([...zeichnung.slice(0, 2), value, ...zeichnung.slice(3)])}
        sx={{ width: 200, opacity: zeichnung[2] === 'Freitext' ? .5 : 1 }}
    />;

    type autocompleteOption2 = 'mit' | 'unter' | '/' | 'Freitext';
    const autocompleteOptions2: ReadonlyArray<autocompleteOption2> = ['mit', 'unter', '/', 'Freitext'];
    const autocomplete2Jsx = <Autocomplete
        size={'small'}
        options={autocompleteOptions2}
        renderInput={(params) => <TextField {...params} label={''} />}
        value={zeichnung[6]}
        onChange={(_, value) => setZeichnung([...zeichnung.slice(0, 6), value === '/' ? undefined : value, ...(value !== '/' ? zeichnung.slice(7) : [])])}
        sx={{ width: 200, opacity: zeichnung[6] === 'Freitext' ? .5 : 1 }}
    />;

    switch (zeichnung[0]) {
        case 'Planum':
        case 'Profil':
            return (
                <div className={'zeichnung'}>
                    <div>
                        <TextField
                            size={'small'}
                            sx={{ width: NUMBER_FIELD_XS_WIDTH }}
                            label={''}
                            value={zeichnung[1]}
                            onChange={e => setZeichnung([zeichnung[0], e.target.value, ...zeichnung.slice(2)])}
                        />
                        <span>Maßstab</span>
                        {autocompleteJsx}
                        {zeichnung[2] === 'Freitext' && <TextField
                            size={'small'}
                            sx={{ width: 200 }}
                            label={''}
                            value={zeichnung[3]}
                            onChange={e => setZeichnung([...zeichnung.slice(0, 3), e.target.value, ...zeichnung.slice(4)])}
                        />}
                        <span>siehe Zeichenblatt-Nr.</span>
                        <TextField
                            size={'small'}
                            sx={{ width: NUMBER_FIELD_XS_WIDTH }}
                            label={''}
                            value={zeichnung[3]}
                            onChange={e => setZeichnung([...zeichnung.slice(0, 3), undefined, e.target.value, ...zeichnung.slice(5)])}
                        />
                    </div>
                    <div>
                        <span>Zeichner*in</span>
                        <TextField
                            size={'small'}
                            sx={{ width: 200 }}
                            label={''}
                            value={zeichnung[5]}
                            onChange={e => setZeichnung([...zeichnung.slice(0, 5), e.target.value, ...zeichnung.slice(6)])}
                        />
                        {autocomplete2Jsx}
                        {(zeichnung[6] === 'mit' || zeichnung[6] === 'unter') &&
                            <TextField
                                size={'small'}
                                sx={{ width: 200 }}
                                label={''}
                                value={zeichnung[7]}
                                onChange={e => setZeichnung([...zeichnung.slice(0, 7), e.target.value, ...zeichnung.slice(8)])}
                            />}
                        {zeichnung[6] === 'Freitext' && <TextField
                            size={'small'}
                            sx={{ width: 200 }}
                            label={''}
                            value={zeichnung[7]}
                            onChange={e => setZeichnung([...zeichnung.slice(0, 7), e.target.value, ...zeichnung.slice(8)])}
                        />}
                    </div>
                </div>
            );
        // case 'Profil':
        //     return (
        //         <div className={'zeichnung'}>
        //             <div>
        //                 <TextField size={'small'} sx={{ width: NUMBER_FIELD_XS_WIDTH }} label={''} />
        //                 <span>Maßstab</span>
        //                 {autocompleteJsx}
        //                 {autocomplete === 'Freitext' && <TextField size={'small'} sx={{ width: 200 }} label={''} />}
        //                 <span>siehe Zeichenblatt-Nr.</span>
        //                 <TextField size={'small'} sx={{ width: NUMBER_FIELD_XS_WIDTH }} label={''} />
        //             </div>
        //             <div>
        //                 <span>Zeichner*in</span>
        //                 <TextField size={'small'} sx={{ width: 200 }} label={''} />
        //                 {autocomplete2Jsx}
        //                 {(autocomplete2 === 'mit' || autocomplete2 === 'unter') &&
        //                     <TextField size={'small'} sx={{ width: 200 }} label={''} />}
        //                 {autocomplete2 === 'Freitext' && <TextField size={'small'} sx={{ width: 200 }} label={''} />}
        //             </div>
        //         </div>
        //     );
        case 'Freitext':
            return (
                <>
                    <TextField size={'small'} sx={{ width: 700 }} label={''} />
                </>
            );
    }
}

export { ZeichnungPositionDialog };
