import { Card, CardHeader, styled as muiStyled } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const StyledBasePage = styled.section`
  padding: 15px;
  height: 100%;
`;

const StyledCard = muiStyled(Card)`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 15px;
`;

interface BasePageProps {
    title: string;
    children: JSX.Element;
}

function BasePage(props: BasePageProps) {
    return (
        <StyledBasePage>
            <StyledCard>
                <CardHeader title={props.title} />
                {props.children}
            </StyledCard>
        </StyledBasePage>
    );
}

export default BasePage;
