import Dexie from 'dexie';
import { Activity, OfflineActivity } from '../entities/Activity/Activity';
import { CachedActivity } from '../entities/Activity/CachedActivity';
import { CachedDigit } from '../entities/Digit/CachedDigit';
import { Digit, OfflineDigit } from '../entities/Digit/Digit';
import { CachedFreeText } from '../entities/FreeText/CachedFreeText';
import { FreeText, OfflineFreeText } from '../entities/FreeText/FreeText';
import { CachedKeyword } from '../entities/Keyword/CachedKeyword';
import { Keyword, OfflineKeyword } from '../entities/Keyword/Keyword';
import { InternetConnection } from '../entities/InternetConnection/InternetConnection';
import { CachedPosition } from '../entities/Position/CachedPosition';
import { OfflinePosition, Position } from '../entities/Position/Position';

class DocumentaRhenaniaDB extends Dexie {
    constructor() {
        super('DocumentaRhenaniaDB');
        this.version(5).stores({
            activities: '&id,datetime,&number,operation,comment,offlineAvailable',
            digits: '&id,&[activityId+number],comment,datetime,offlineAvailable',
            positions: '&id,&[digitId+number],datetime,keyword,freeTexts,offlineAvailable',
            keywords: '&id,name,isCsvExport,offlineAvailable',
            freeTexts: '&id,text,keywordId,offlineAvailable',
            internetConnection: '&id,connectionType,status,updatedAt',

            // Cached
            cachedActivities: '&id,*entityOperations',
            cachedDigits: '&id,*entityOperations',
            cachedPositions: '&id,*entityOperations',
            cachedKeywords: '&id,*entityOperations',
            cachedFreeTexts: '&id,*entityOperations'
        });

        this.activities = this.table('activities');
        this.digits = this.table('digits');
        this.positions = this.table('positions');
        this.keywords = this.table('keywords');
        this.freeTexts = this.table('freeTexts');
        this.internetConnection = this.table('internetConnection');

        // Cached
        this.cachedActivities = this.table('cachedActivities');
        this.cachedDigits = this.table('cachedDigits');
        this.cachedPositions = this.table('cachedPositions');
        this.cachedKeywords = this.table('cachedKeywords');
        this.cachedFreeTexts = this.table('cachedFreeTexts');
    }

    activities: Dexie.Table<Activity | OfflineActivity, string>;
    digits: Dexie.Table<Digit | OfflineDigit, string>;
    positions: Dexie.Table<Position | OfflinePosition, string>;
    keywords: Dexie.Table<Keyword | OfflineKeyword, string>;
    freeTexts: Dexie.Table<FreeText | OfflineFreeText, string>;
    internetConnection: Dexie.Table<InternetConnection, string>;

    // Cached
    cachedActivities: Dexie.Table<CachedActivity, string>;
    cachedDigits: Dexie.Table<CachedDigit, string>;
    cachedPositions: Dexie.Table<CachedPosition, string>;
    cachedKeywords: Dexie.Table<CachedKeyword, string>;
    cachedFreeTexts: Dexie.Table<CachedFreeText, string>;
}

export default DocumentaRhenaniaDB;
