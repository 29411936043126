import styled from "styled-components";

const StyledStellenkarte = styled.div<{ height: string; }>`
    display: flex;
    flex-direction: column;
    gap: .5rem;
    height: ${props => props.height};
    max-height: ${props => props.height};
    overflow-y: auto;
`;

export {StyledStellenkarte};
