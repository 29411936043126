import {Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tab} from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import ChatIcon from '@mui/icons-material/Chat';
import GroupIcon from '@mui/icons-material/Group';
import {Tabs} from '@mui/material';
import Paper from '@mui/material/Paper';
import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {StyledBottomNavigation} from './BottomNavigation.style';
import {ChevronLeft} from "@mui/icons-material";

interface BottomNavigationProps {
    isNavigationOpen: boolean;
    setIsNavigationOpen: (open: boolean) => void;
}

function BottomNavigation(props: BottomNavigationProps) {
    const history = useHistory();

    const pushTo = (route: string) => {
        history.push(route);
        props.setIsNavigationOpen(false);
    }

    return (
        <StyledBottomNavigation open={props.isNavigationOpen} onClose={() => props.setIsNavigationOpen(false)}>
                <ListItem className={'close-item'} onClick={() => props.setIsNavigationOpen(false)}>
                    <ListItemButton>
                        <ListItemIcon>
                            <ChevronLeft/>
                        </ListItemIcon>
                    </ListItemButton>
                </ListItem>
                <Divider/>
                <List>
                    <ListItem onClick={() => pushTo('/activities')}>
                        <ListItemButton>
                            <ListItemIcon><BuildIcon/></ListItemIcon>
                            <ListItemText>Aktivitäten</ListItemText>
                        </ListItemButton>
                    </ListItem>
                </List>

            {/*<Paper square className='paper-nav'>*/}
            {/*    <Tabs*/}
            {/*        value={value}*/}
            {/*        onChange={handleChange}*/}
            {/*        variant='fullWidth'*/}
            {/*        indicatorColor='secondary'*/}
            {/*        textColor='secondary'*/}
            {/*        aria-label='Navigation'*/}
            {/*    >*/}
            {/*        <Tab value={0} icon={<BuildIcon />} label={'Aktivitäten'} component={Link} to='/activities' />*/}
            {/*        <Tab value={1} icon={<KeyboardIcon />} label={'Arbeitsschritte'} component={Link} to='/keywords' />*/}
            {/*        <Tab value={2} icon={<ChatIcon />} label={'Freitexte'} component={Link} to='/freetexts' />*/}
            {/*    </Tabs>*/}
            {/*</Paper>*/}
        </StyledBottomNavigation>
    );
}

export default BottomNavigation;
