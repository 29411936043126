import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';

interface DeleteDialogProps {
    open: boolean;
    handleConfirm: () => void;
    handleDecline: () => void;
    title: string;
    content: JSX.Element|  JSX.Element[] | string;
    confirmText: string;
    declineText: string;
}

function DeleteDialog(props: DeleteDialogProps) {
    return (
        <Dialog
            open={props.open}
            onClose={props.handleDecline}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleDecline} color="secondary" autoFocus>
                    {props.declineText}
                </Button>
                <Button onClick={props.handleConfirm} color="primary">
                    {props.confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteDialog;
