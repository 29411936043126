import {ApolloClient, ApolloLink, HttpLink, InMemoryCache} from '@apollo/client';
import {onError} from '@apollo/client/link/error';

export const GraphQLClient = (token: string) => new ApolloClient({
    link: ApolloLink.from([errorLink, httpLink(token)]),
    cache: new InMemoryCache({addTypename: false}),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'no-cache'
        },
        query: {
            fetchPolicy: 'no-cache',
        },
        mutate: {
            fetchPolicy: 'no-cache'
        }
    },
});

const httpLink = (token: string) => {
    const graphQLEndpoint = window.location.hostname.includes('localhost')
        ? process.env.REACT_APP_GQL_ENDPOINT
        : "https://rhenania-api.kng.link/graphql";
    console.log(`%c🛜 GraphQL endpoint: %c${graphQLEndpoint}`, 'color: #57b9ff;', 'font-weight: bold;');
    console.log(`Aus dokploy-env folgendes gelesen: `, process.env.REACT_APP_GQL_ENDPOINT);

    return new HttpLink({
        uri: graphQLEndpoint,
        headers: {
            authorization: `Bearer ${token}`
        },
    });
}

const errorLink = onError(({graphQLErrors}) => {
    if (graphQLErrors) {
        for (let error of graphQLErrors) {
            switch (error.extensions.code) {
                case 'AUTH_NOT_AUTHENTICATED':
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                    break;
            }
        }
    }
});
