import {useState} from "react";
import {StyledPosition} from "../Position.style";
import {IconButton} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {ZeichnungPositionDialog} from "./Dialog/ZeichnungPositionDialog";
import { Maybe } from "../../Maybe";

interface ZeichnungPositionProps {
    nummer: number;
}

const ZeichnungPosition = (props: ZeichnungPositionProps): JSX.Element => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [zeichnung, setZeichnung] = useState<ReadonlyArray<Maybe<string>>>([]);

    return (
        <StyledPosition>
            <div className={'details'}>
                <span className={'nummer'}>{props.nummer}</span>
                <span>Zeichnung</span>
            </div>
            <div className={'text'}>
                <span>
                    {zeichnung[0] ? `von ${zeichnung[0]} ` : ''}
                    {zeichnung[1] ?? ''}
                    {zeichnung[2] ? ` Maßstab ${zeichnung[2]}` : ''}
                    {zeichnung[3] ? ` siehe Zeichenblatt-Nr. ${zeichnung[3]}` : ''}
                    {zeichnung[4] ? `, Zeichner*in ${zeichnung[4]}` : ''}
                    {zeichnung[5] ? ` ${zeichnung[5]}` : ''}
                    {zeichnung[6] ? ` ${zeichnung[6]}` : ''}
                    {zeichnung[7] ? ` ${zeichnung[7]}` : ''}
                </span>
            </div>
            <div className={'buttons'}>
                <IconButton color={'primary'} onClick={() => setIsDialogOpen(true)}>
                    <EditIcon/>
                </IconButton>
            </div>

            <ZeichnungPositionDialog
                isOpen={isDialogOpen}
                setIsOpen={setIsDialogOpen}
                zeichnung={zeichnung}
                setZeichnung={setZeichnung}
            />
        </StyledPosition>
    );
};

export {ZeichnungPosition};
