import { createContext, Dispatch, useReducer } from 'react';
import { FreeText } from '../../../entities/FreeText/FreeText';
import { Keyword } from '../../../entities/Keyword/Keyword';
import { OfflinePosition, Position } from '../../../entities/Position/Position';

interface DigitalPositionCardState {
    positions: Array<OfflinePosition>;
    availableKeywords: Array<Keyword>;
    availableFreeTexts: Array<FreeText>;
}

interface WritableDigitalPositionCardState extends DigitalPositionCardState {
    setDigitalPositionCard: Dispatch<Partial<DigitalPositionCardState>>;
    setPositions: (positions: Array<OfflinePosition>) => void;
}

const DigitalPositionCardContext = createContext<WritableDigitalPositionCardState>(null!);

interface DigitalPositionCardProps {
    children: JSX.Element | JSX.Element[];
}

const DigitalPositionCardProvider = (props: DigitalPositionCardProps) => {
    const [digitalPositionCard, setDigitalPositionCard] = useReducer(
        (state: DigitalPositionCardState, newState: Partial<DigitalPositionCardState>) => ({ ...state, ...newState }),
        {
            positions: new Array<OfflinePosition>(),
            availableKeywords: new Array<Keyword>(),
            availableFreeTexts: new Array<FreeText>()
        }
    );

    return (
        <DigitalPositionCardContext.Provider value={{
            ...digitalPositionCard,
            setDigitalPositionCard,
            setPositions: (positions: OfflinePosition[]) => setDigitalPositionCard({ positions })
        }}>
            {props.children}
        </DigitalPositionCardContext.Provider>
    );
}

export { DigitalPositionCardProvider, DigitalPositionCardContext }
