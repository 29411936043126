import { DialogContent, TextField } from "@mui/material";
import { Maybe } from "../../../Maybe";
import { StyledPositionDialog } from "../../PositionDialog.style";
import { PositionDialogButtons } from "../../PositionDialogButtons";

interface PositionDialog {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    anlageProfilPosition: Maybe<[Maybe<string>, Maybe<string>]>;
    setAnlageProfilPosition: (anlageProfilPosition: Maybe<[Maybe<string>, Maybe<string>]>) => void;
}

const AnlageProfilPositionDialog = (props: PositionDialog) => {
    const onDialogClose = () => {
        props.setIsOpen(false);
    };

    return (
        <StyledPositionDialog open={props.isOpen} onClose={onDialogClose}>
            <DialogContent>
                <TextField size={'small'} label={''} value={props.anlageProfilPosition?.[0]} onChange={e => props.setAnlageProfilPosition([e.target.value, props.anlageProfilPosition?.[1]])} />
                <TextField size={'small'} label={''} value={props.anlageProfilPosition?.[1]} onChange={e => props.setAnlageProfilPosition([props.anlageProfilPosition?.[0], e.target.value])} />
            </DialogContent>
            <PositionDialogButtons setIsOpen={props.setIsOpen} />
        </StyledPositionDialog>
    );
};

export { AnlageProfilPositionDialog };

