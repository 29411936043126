import { StyledPosition } from "../Position.style";
import { OfflinePosition } from "../../../../entities/Position/Position";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useMemo, useState } from "react";
import { ErstePositionDialog } from "./Dialog/ErstePositionDialog";
import { Maybe } from "../../Maybe";

interface PositionProps extends OfflinePosition {
}

const ErstePosition = (props: PositionProps): JSX.Element => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [satzteile, setSatzteile] = useState<ReadonlyArray<Maybe<string>>>([]);

    const zusammengesetzteSatzteile = useMemo(() => {

        const d = satzteile
            .join(' ')
            .replaceAll('Freitext', '');
        console.log(d)
        return d;
    },
        [satzteile]
    );

    return (
        <StyledPosition>
            <div className={'details'}>
                <span className={'nummer'}>1.</span>
                <span>Definition</span>
            </div>
            <div className={'text'}>
                <span>{`${zusammengesetzteSatzteile}${zusammengesetzteSatzteile.endsWith('.') ? '' : '.'}`}</span>
            </div>
            <div className={'buttons'}>
                <IconButton color={'primary'} onClick={() => setIsDialogOpen(true)}>
                    <EditIcon />
                </IconButton>
            </div>

            <ErstePositionDialog
                isOpen={isDialogOpen}
                setIsOpen={setIsDialogOpen}
                setSatzteile={setSatzteile}
            />
        </StyledPosition>
    );
};

export { ErstePosition };
