import {AppBar, IconButton, Toolbar, Typography} from '@mui/material';
import React, {useContext} from 'react';
import {InternetConnectionContext} from '../../repository/contexts/InternetConnectionProvider';
import {StyledHeader} from './Header.style';
import Logo from '../../images/arthemus_logo.svg';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {ChevronRight} from "@mui/icons-material";

interface HeaderProps {
    isNavigationOpen?: boolean;
    setNavigationOpen: (open: boolean) => void;
}

function Header(props: HeaderProps) {
    // services
    const {hasInternetConnection} = useContext(InternetConnectionContext);

    return (
        <StyledHeader>
            <AppBar position='static'>
                <Toolbar>
                    <div className={'wrapper'}>
                        <div className={'menu'}>
                            <IconButton onClick={() => props.setNavigationOpen(!props.isNavigationOpen)}>
                                <ChevronRight/>
                            </IconButton>
                        </div>
                        <div className='logo'>
                            <img src={Logo} alt='Logo'/>
                            <Typography variant='h6'>
                                documenta rhenania <sup>v0.1 (Augustus)</sup>
                            </Typography>
                        </div>
                    </div>
                    <div className="user-menu">
                        <span className='separator'/>
                        <CloudOffIcon
                            fontSize='large'
                            style={{color: 'indianred', display: !hasInternetConnection ? 'block' : 'none'}}
                        />
                        <CloudQueueIcon
                            fontSize='large'
                            style={{color: 'green', display: hasInternetConnection ? 'block' : 'none'}}
                        />
                    </div>
                </Toolbar>
            </AppBar>
        </StyledHeader>
    );
}

export default Header;
