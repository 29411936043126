import React, { useContext, useEffect, useState } from 'react';
import { updateOrAddRow } from '../../common/DataGrid/addOrUpdateRow';
import { Keyword } from '../../entities/Keyword/Keyword';
import { ServicesContext } from '../../graphql/contexts/ServicesProvider';
import DataGridWithActions from '../DataGridWithActions/DataGridWithActions';
import EditDialog from '../EditDialog/EditDialog';
import KeywordForm from './KeywordForm/KeywordForm';
import { KeywordsColumns } from './models/KeywordsColumns';

interface KeywordsDataGridProps {
    entityName: { singular: string; plural: string; };
}

function KeywordsDataGrid(props: KeywordsDataGridProps) {
    const { keywordsService } = useContext(ServicesContext).services;
    const [keywords, setKeywords] = useState<Keyword[] | undefined>();

    useEffect(() => {
        keywordsService.getAll().then(keywordsResponse => setKeywords(keywordsResponse));
    }, [keywordsService])

    return (
        <DataGridWithActions<Keyword>
            entityName={props.entityName}
            sortModel={[{ field: 'name', sort: 'asc' }]}
            columns={KeywordsColumns}
            rows={keywords}
            setRows={setKeywords}
            handleRemove={keywordsService.remove}
        >
            {({ id, open, handleClose }) =>
                <EditDialog
                    id={id}
                    open={open}
                    handleClose={handleClose}
                    entityName={props.entityName.singular}
                    description={{
                        new: 'Um einen neuen ' + props.entityName.singular +
                            ' anzulegen, fülle bitte alle Pflichtfelder aus und speichere Deine Eingabe.',
                        edit: 'Um einen ' + props.entityName.singular +
                            ' zu bearbeiten, bearbeite die untenstehenden Felder und speichere Deine Eingabe.'
                    }}
                >
                    <KeywordForm
                        keywordId={id}
                        handleClose={handleClose}
                        updateRow={(data) => !!data && updateOrAddRow(
                            data,
                            keywords,
                            setKeywords
                        )}
                        entityName={props.entityName}
                    />
                </EditDialog>
            }
        </DataGridWithActions>
    );
}

export default KeywordsDataGrid;
