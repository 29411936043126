import { Card, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import DigitsDataGrid from '../../components/DigitsDataGrid/DigitsDataGrid';
import { Activity as ActivityEntity } from '../../entities/Activity/Activity';
import { ServicesContext } from '../../graphql/contexts/ServicesProvider';
import BasePage from '../BasePage/BasePage';
import { Params } from '../params/Params';
import '../../common/lang/dates';
import { StyledActivity } from './Activity.style';

function Activity() {
    const { activityId } = useParams<Params>();
    const history = useHistory();

    const { activitiesService } = useContext(ServicesContext).services;
    const [activity, setActivity] = useState<ActivityEntity | undefined>();

    useEffect(() => {
        if (!!activityId) {
            activitiesService.get(activityId).then(activityResponse => !!activityResponse
                ? setActivity(activityResponse)
                : history.push('/activities')
            );
        }
    }, [activityId, activitiesService, history]);

    const entityName = { singular: 'Stelle', plural: 'Stellen' };

    return !!activity ?
        (
            <BasePage title={'Aktivität ' + (activity.number)}>
                <StyledActivity>
                    <Card className='description'>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            {activity.dateTime.toGermanLocaleString()}
                        </Typography>
                        <Typography variant="h5" component="div">
                            {activity.operation}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            {activity.comment}
                        </Typography>
                    </Card>
                    <DigitsDataGrid activityId={activity.id} entityName={entityName} />
                </StyledActivity>
            </BasePage>
        )
        :
        <></>;
}

export default Activity;
