import {useState} from "react";
import {StyledPosition} from "../Position.style";
import {IconButton} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {TechnischeBemerkungPositionDialog} from "./Dialog/TechnischeBemerkungPositionDialog";
import { Maybe } from "../../Maybe";

interface TechnischeBemerkungPositionProps {
    nummer: number;
}

const TechnischeBemerkungPosition = (props: TechnischeBemerkungPositionProps): JSX.Element => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [technischeBemerkung, setTechnsicheBemerkung] = useState<Maybe<string>>();

    return (
        <StyledPosition>
            <div className={'details'}>
                <span className={'nummer'}>{props.nummer}</span>
                <span>Technische Bemerkung</span>
            </div>
            <div className={'text'}>
                <span>
                    {technischeBemerkung}
                </span>
            </div>
            <div className={'buttons'}>
                <IconButton color={'primary'} onClick={() => setIsDialogOpen(true)}>
                    <EditIcon/>
                </IconButton>
            </div>

            <TechnischeBemerkungPositionDialog
                isOpen={isDialogOpen}
                setIsOpen={setIsDialogOpen}
                technischeBemerkung={technischeBemerkung}
                setTechnischeBemerkung={setTechnsicheBemerkung}
            />
        </StyledPosition>
    );
};

export {TechnischeBemerkungPosition};
