import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Keyword } from '../../entities/Keyword/Keyword';
import { BaseService, GraphQLService } from '../BaseService';
import { Service } from '../Service';
import { ADD_KEYWORD, GET_ALL_KEYWORDS, GET_KEYWORD, REMOVE_KEYWORDS, UPDATE_KEYWORD } from './KeywordsSchemas';

export class KeywordsService implements Service<Keyword> {
    constructor(client: ApolloClient<NormalizedCacheObject>) {
        this.baseClient = new BaseService(
            GET_KEYWORD,
            GET_ALL_KEYWORDS,
            ADD_KEYWORD,
            UPDATE_KEYWORD,
            REMOVE_KEYWORDS,
            client
        );
    }

    private readonly baseClient: GraphQLService<Keyword>;

    public getAll = () =>
        this.baseClient.getAll('allKeywords');

    public get = (id: string) =>
        this.baseClient.get(id, 'keyword');

    public add = (keyword: Keyword) =>
        this.baseClient.add(keyword, 'addKeyword', { keyword })
            .then(keyword => keyword ?? null);

    public update = (keyword: Keyword) =>
        // TODO: Warum 2x Keyword rein???
        this.baseClient.update(keyword, 'updateKeyword', { keyword })
            .then(keyword => keyword ?? null);

    public remove = (ids: string[]) =>
        this.baseClient.remove(ids, 'removeKeywords');
}
