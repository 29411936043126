import { gql } from '@apollo/client';

export const GET_ALL_KEYWORDS = gql`
    query GetAllKeywords {
        allKeywords {
            id
            name
            isCsvExport
        }
    }
`;

export const GET_KEYWORD = gql`
    query GetKeyword($id: UUID!) {
        keyword(id: $id) {
            id
            name
            isCsvExport
        }
    }
`;

export const UPDATE_KEYWORD = gql`
    mutation UpdateKeyword($keyword: KeywordInput!) {
        updateKeyword(keyword: $keyword) {
            id
            name
            isCsvExport
        }
    }
`;

export const ADD_KEYWORD = gql`
    mutation AddKeyword($keyword: KeywordInput!) {
        addKeyword(keyword: $keyword) {
            id
            name
            isCsvExport
        }
    }
`;

export const REMOVE_KEYWORDS = gql`
    mutation RemoveKeywords($ids: [UUID!]!) {
        removeKeywords(ids: $ids)
    }
`;
