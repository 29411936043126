import { BaseEntity } from '../../entities/BaseEntity';

export function removeRows<T extends BaseEntity>(
    rows: T[],
    idsToDelete: string[]
): T[] {
    let rowsCopy: T[] = [...rows];
    idsToDelete.forEach(id => {
        rowsCopy = rowsCopy.filter(row => row.id !== id);
    })
    return rowsCopy;
}
