import { IconButton, Tooltip } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import EditIcon from '@mui/icons-material/Edit';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';

export const DigitColumns = (
    handleEditClick: (id?: string) => void,
    redirect?: (activityId: string) => void
): GridColDef[] =>
    [
        {
            field: 'id',
            hide: true
        },
        {
            field: 'number',
            headerName: 'Stellennummer',
            flex: 3.5,
        },
        {
            field: 'comment',
            headerName: 'Bemerkung',
            flex: 3.5,
            sortable: false
        },
        {
            field: '',
            headerName: ' ',
            width: 110,
            filterable: false,
            disableColumnMenu: true,
            sortable: false,
            align: 'center',
            renderCell: (params: GridCellParams) => {
                return <>
                    {!!redirect &&
                    <Tooltip title={'Stelle öffnen'}>
                        <IconButton>
                            <FolderIcon onClick={() => redirect!(params.row.number)} />
                        </IconButton>
                    </Tooltip>}
                    <Tooltip title={'Bearbeiten'}>
                        <IconButton>
                            <EditIcon onClick={() => handleEditClick(params.row.id)} />
                        </IconButton>
                    </Tooltip>
                </>
            }
        }
    ];
