import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { GET_CONNECTION } from './CheckConnectionSchemas';

type ReturnType = { connection: boolean };

export class CheckConnectionService {
    constructor(private readonly client: ApolloClient<NormalizedCacheObject>) {}

    public get = () => this.client.query<ReturnType>({ query: GET_CONNECTION })
        .then(it => it.data.connection);
}
