import { useEffect } from "react";
import { Maybe } from "../../../Maybe";

interface ArbeitsbereichProps {
    addToSatzteile: (values: ReadonlyArray<{ index: number; satzteil: Maybe<string>; }>) => void;
    satzteile: ReadonlyArray<Maybe<string>>;
}

const Arbeitsbereich = (props: ArbeitsbereichProps) => {
    useEffect(() => {
        props.addToSatzteile([{ index: 4, satzteil: 'definiert.' }]);
    }, [props.addToSatzteile]);

    return <span>{props.satzteile[4]}</span>;
}

export { Arbeitsbereich };