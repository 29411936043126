import { gql } from '@apollo/client';

export const GET_ALL_POSITIONS = gql`
    query GetAllPositions {
        allPositions {
            id
            digitId
            number
            dateTime
        }
    }
`;

export const GET_POSITION = gql`
    query GetPosition($id: UUID!) {
        position(id: $id) {
            id
            digitId
            number
            dateTime
        }
    }
`;

export const FILTER_POSITIONS_BY_DIGIT_ID = gql`
    query FilterPositionsByDigitId($digitId: UUID!) {
        filterPositionsByDigitId(digitId: $digitId) {
            id
            digitId
            number
            dateTime
            keyword {
                id
                name
                isCsvExport
            }
            freeTexts {
                id
                text
            }
        }
    }
`;

export const UPDATE_POSITION = gql`
    mutation UpdatePosition($position: PositionInput!) {
        updatePosition(position: $position) {
            id
            digitId
            number
            dateTime
            freeTexts {
                id
                text
                keywordId
            }
            keyword {
                id
                name
                isCsvExport
            }
        }
    }
`;

export const ADD_POSITION = gql`
    mutation AddPosition($position: PositionInput!) {
        addPosition(position: $position) {
            id
            digitId
            number
            dateTime
            freeTexts {
                id
                text
                keywordId
            }
            keyword {
                id
                name
                isCsvExport
            }
        }
    }
`;

export const REMOVE_POSITION = gql`
    mutation RemovePosition($ids: [UUID!]!) {
        removePositions(ids: $ids)
    }
`;
