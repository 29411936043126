import {useState} from "react";
import {StyledPosition} from "../Position.style";
import {IconButton} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {ErneutAufgedecktPositionDialog} from "./Dialog/ErneutAufgedecktPositionDialog";
import { Maybe } from "../../Maybe";

interface FundePositionProps {
    nummer: number;
}

const ErneutAufgedecktPosition = (props: FundePositionProps): JSX.Element => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [erneutAufgedeckt, setErneutAufgedeckt] = useState<ReadonlyArray<Maybe<string>>>([]);

    return (
        <StyledPosition>
            <div className={'details'}>
                <span className={'nummer'}>{props.nummer}.</span>
                <span>Erneut aufgedeckt</span>
            </div>
            <div className={'text'}>
                <span>{erneutAufgedeckt.length > 0 && 'in'} {erneutAufgedeckt.join(' ')}</span>
            </div>
            <div className={'buttons'}>
                <IconButton color={'primary'} onClick={() => setIsDialogOpen(true)}>
                    <EditIcon/>
                </IconButton>
            </div>

            <ErneutAufgedecktPositionDialog
                isOpen={isDialogOpen}
                setIsOpen={setIsDialogOpen}
                erneutAufgedeckt={erneutAufgedeckt}
                setErneutAufgedeckt={setErneutAufgedeckt}
            />
        </StyledPosition>
    );
};

export {ErneutAufgedecktPosition};
