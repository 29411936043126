import { DialogContent, TextField } from "@mui/material";
import { Maybe } from "../../../Maybe";
import { StyledPositionDialog } from "../../PositionDialog.style";
import { PositionDialogButtons } from "../../PositionDialogButtons";

interface PositionDialog {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    strukturen: ReadonlyArray<Maybe<string>>;
    setStrukturen: (strukturen: ReadonlyArray<Maybe<string>>) => void;
}

const StrukturenErkanntPositionDialog = (props: PositionDialog) => {
    const onDialogClose = () => {
        props.setIsOpen(false);
    };

    return (
        <StyledPositionDialog open={props.isOpen} onClose={onDialogClose}>
            <DialogContent>
                <span>Strukturen erkannt und St.</span>
                <TextField
                    size={'small'}
                    sx={{ width: 200 }}
                    label={''}
                    value={props.strukturen[0]}
                    onChange={e => props.setStrukturen([e.target.value, props.strukturen[1]])}
                />
                <span>in Pl.</span>
                <TextField
                    size={'small'}
                    sx={{ width: 200 }}
                    label={''}
                    value={props.strukturen[1]}
                    onChange={e => props.setStrukturen([props.strukturen[0], e.target.value])}
                />
                <span>vergeben</span>
            </DialogContent>
            <PositionDialogButtons setIsOpen={props.setIsOpen} />
        </StyledPositionDialog>
    );
};

export { StrukturenErkanntPositionDialog };

