import { useState } from "react";
import { StyledPosition } from "../Position.style";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { FotoPositionDialog } from "./Dialog/FotoPositionDialog";
import { Maybe } from "../../Maybe";

interface FotoPositionProps {
    nummer: number;
}

const FotoPosition = (props: FotoPositionProps): JSX.Element => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [foto, setFoto] = useState<ReadonlyArray<Maybe<string>>>([]);

    const freitextRaus = foto.filter(f => f !== 'Freitext' && f !== '/');

    return (
        <StyledPosition>
            <div className={'details'}>
                <span className={'nummer'}>{props.nummer}</span>
                <span>Foto</span>
            </div>
            <div className={'text'}>
                <span>
                    {freitextRaus.length === 1 && freitextRaus[0]}
                    {freitextRaus.length > 1
                        ? `von ${freitextRaus[0] ? freitextRaus[0] + ' gg. ' : ''} ${freitextRaus[1] ?? ''} ${freitextRaus[2] ?? ''} ${freitextRaus[3] ?? ''} ${freitextRaus[4] ?? ''}`
                        : ''
                    }
                </span>
            </div>
            <div className={'buttons'}>
                <IconButton color={'primary'} onClick={() => setIsDialogOpen(true)}>
                    <EditIcon />
                </IconButton>
            </div>

            <FotoPositionDialog
                isOpen={isDialogOpen}
                setIsOpen={setIsDialogOpen}
                foto={foto}
                setFoto={setFoto}
            />
        </StyledPosition>
    );
};

export { FotoPosition };
