import styled from "styled-components";

const StyledEinschluesseGapText = styled.div`
    > .MuiFormGroup-root {
        > .checkbox-wrapper {
            display: flex;
            
            > .MuiAutocomplete-root {
                width: 200px;
            }
            
            > .MuiFormGroup-root {
                width: 100%;
                
                > .wrapper {
                    display: flex;
                    justify-content: flex-start;
                    
                    > .MuiFormControlLabel-root {
                        width: 125px;
                    }
                    
                    > .MuiAutocomplete-root {
                        width: 100%;
                    }
                }
            }
        }
    }
`;

export {StyledEinschluesseGapText};
