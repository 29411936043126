import {Card, styled} from "@mui/material";

const StyledPosition = styled(Card)`
    min-height: 56px;
    max-height: 56px;
    padding: .5rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    > .details {
        min-width: 200px;
        display: flex;
        align-items: center;
        gap: 1rem;

        > .nummer {
            font-weight: bold;
        }
    }

    > .text {
        color: #7c7c7c;
        width: 100%;
    }

    > .buttons {
        display: flex;
        gap: 0.5rem;
    }
`;

export {StyledPosition};
