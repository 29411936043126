import { Table } from 'dexie';
import { BaseEntity } from '../entities/BaseEntity';
import { validate as validateUuid } from 'uuid';

export abstract class BaseRepository<T extends BaseEntity> {
    protected table: Table<T, string>;

    protected constructor(table: Table<T, string>) {
        this.table = table;
    }

    public get = (id: string) => this.table.get(id);

    public all = () => this.table.toArray();

    public filter = (expression: (it: T) => boolean) => this.table.filter(expression).toArray();

    public add = async (entity: T): Promise<T> => {
        const id = await this.table.add(entity)
        if (validateUuid(id) && id === entity.id) {
            return entity;
        } else {
            console.error(
                'Returned ID is not a valid UUID. Something went wrong when saving to IndexedDB. Returned ID: ' + id
            )
            throw new Error('Returned ID is not a valid UUID. Something went wrong when saving to IndexedDB.');
        }
    };

    public update = (entity: T) => this.table.update(entity.id, entity);

    public delete = (id: string) => this.table.delete(id);

    public deleteRange = (ids: string[]) => this.table.bulkDelete(ids);
}
