import { Autocomplete, TextField } from "@mui/material";
import { Maybe } from "../../../Maybe";

interface FarbeGapTextProps {
    setFarbe: (farbe: Maybe<Array<string>>) => void;
    farbe: Maybe<Array<string>>;
}

const FarbeGapText = (props: FarbeGapTextProps) => {
    const FARBE_OPTIONS = ['hell', 'dunkel', 'braun', 'grau', 'schwarz', 'rötlich', 'orange', 'fleckig']

    return (
        <Autocomplete
            multiple
            freeSolo
            size={'small'}
            options={FARBE_OPTIONS}
            renderInput={(params) => <TextField {...params} label={'Farbe'}/>}
            value={props.farbe ?? []}
            onChange={(_, value) => props.setFarbe(value)}
            sx={{width: 700}}
        />
    );
}

export { FarbeGapText };

