import * as yup from 'yup';

export const ACTIVITY_VALIDATION_SCHEMA = yup.object({
    id: yup.string().uuid(),
    dateTime: yup.date()
        .typeError('Bitte gib das Datum in folgendem Format an: TT.MM.JJJJ')
        .required('Bitte gib ein Datum ein.'),
    number: yup.string().required('Bitte gib eine Aktivitätsnummer an.'),
    operation: yup.string().optional().notRequired(),
    comment: yup.string().optional().notRequired()
});
