import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Activities from './pages/Activities/Activities';
import Activity from './pages/Activity/Activity';
import Digit from './pages/Digit/Digit';
import FreeTexts from './pages/FreeTexts/FreeTexts';
import Keywords from './pages/Keywords/Keywords';
import Login from './pages/Login/Login';
import {Digit2} from "./pages/Digit2/Digit2";

function AppRoutes() {
    return (
        <>
            <Route
                exact
                path='/activities'
                render={() => <Activities />}
            />
            <Route
                exact
                path='/activities/:activityId'
                render={() => <Activity />}
            />
            <Route
                exact
                path='/activities/:activityId/digits/:digitNumber'
                render={() => <Digit />}
            />
            <Route
                exact
                path='/activities/:activityId/digits2/:digitNumber'
                render={() => <Digit2 />}
            />

            <Route
                exact
                path='/keywords'
                render={() => <Keywords />}
            />

            <Route
                exact
                path='/freetexts'
                render={() => <FreeTexts />}
            />

            {/*<Route exact path='*' render={() => <Redirect to={'/activities222'} />} />*/}
        </>
    );
}

export default AppRoutes;
