import { Checkbox } from "@mui/material";
import { useState } from "react";
import { OfflinePosition } from "../../../../entities/Position/Position";
import { StyledPosition } from "../Position.style";

interface PositionProps extends OfflinePosition {
}

const DrittePosition = (props: PositionProps): JSX.Element => {
    const [checked, setChecked] = useState(false);

    return (
        <StyledPosition>
            <div className={'details'}>
                <span className={'nummer'}>3.</span>
                <span>Einmessung</span>
                <Checkbox checked={checked} onChange={e => setChecked(e.target.checked)}/>
            </div>
        </StyledPosition>
    );
};

export { DrittePosition };

