import { ApolloClient, DocumentNode, NormalizedCacheObject } from '@apollo/client';

type variableKey<T> = { [key: string]: T };

export class BaseService<T> implements GraphQLService<T> {
    constructor(
        private readonly getSchema: DocumentNode,
        private readonly getAllSchema: DocumentNode,
        private readonly addSchema: DocumentNode,
        private readonly updateSchema: DocumentNode,
        private readonly removeSchema: DocumentNode,
        private readonly client: ApolloClient<NormalizedCacheObject>
    ) { }

    public get = (id: string, key: string) =>
        this.client
            .query<{ [d: string]: T }, { id: string }>({ query: this.getSchema, variables: { id } })
            .then(it => (it.data as any)[key] as T);

    public getAll = (key: string) =>
        this.client
            .query<{ [d: string]: T[] }>({ query: this.getAllSchema })
            .then(it => (it.data as any)[key] as T[]);

    public add = (entity: T, key: string, variables: variableKey<T>) =>
        this.client
            .mutate<{ [d: string]: T[] }, typeof variables>({ mutation: this.addSchema, variables })
            .then(it => !!it.data ? (it.data as any)[key] as T : null);

    public update = (entity: T, key: string, variables: variableKey<T>) =>
        this.client
            .mutate<{ [d: string]: T[] }, typeof variables>({ mutation: this.updateSchema, variables })
            .then(it => !!it.data ? (it.data as any)[key] as T : null);

    public remove = (ids: string[], key: string) =>
        this.client
            .mutate<{ [d: string]: boolean }, { ids: string[] }>({ mutation: this.removeSchema, variables: { ids } })
            .then(it => (it.data as any)[key] as boolean);
}

export interface GraphQLService<T> {
    get: (id: string, key: string) => Promise<T>;
    getAll: (key: string) => Promise<T[]>;
    add: (entity: T, key: string, variables: variableKey<T>) => Promise<T | null>;
    update: (entity: T, key: string, variables: variableKey<T>) => Promise<T | null>;
    remove: (id: string[], key: string) => Promise<boolean>;
}
