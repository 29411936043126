import { Dialog, styled } from '@mui/material';

const StyledSyncDialog = styled(Dialog)`
  .icon-wrapper {
    width: 100%;
    display: grid;
    place-items: center;
    margin-top: 20px;
    margin-bottom: 10px;

    > svg {
      animation: spin 2s linear infinite;
      font-size: 3.5rem;
    }
  }
`;

export default StyledSyncDialog;
