import React, { useContext, useEffect, useState } from 'react';
import { updateOrAddRow } from '../../common/DataGrid/addOrUpdateRow';
import { FreeText } from '../../entities/FreeText/FreeText';
import { Keyword } from '../../entities/Keyword/Keyword';
import { ServicesContext } from '../../graphql/contexts/ServicesProvider';
import DataGridWithActions from '../DataGridWithActions/DataGridWithActions';
import EditDialog from '../EditDialog/EditDialog';
import FreeTextForm from './FreeTextForm/FreeTextForm';
import { FreeTextColumns } from './models/FreeTextColumns';

interface FreeTextsDataGridProps {
    entityName: { singular: string; plural: string; };
}

function FreeTextsDataGrid(props: FreeTextsDataGridProps) {
    const { freeTextsService, keywordsService } = useContext(ServicesContext).services;

    const [rows, setRows] = useState<FreeText[] | undefined>();
    const [keywords, setKeywords] = useState<Keyword[]>([]);

    useEffect(() => {
        Promise
            .all([
                keywordsService.getAll(),
                freeTextsService.getAllKeywordFreeTexts()
            ])
            .then(response => {
                const [keywordsReponse, freeTextsResponse] = response;
                setKeywords(keywordsReponse);
                setRows(freeTextsResponse);
            })
    }, [keywordsService, freeTextsService])

    return (
        <DataGridWithActions<FreeText>
            entityName={props.entityName}
            sortModel={[{ field: 'text', sort: 'asc' }]}
            columns={(handleEdit) => FreeTextColumns(handleEdit, keywords)}
            rows={rows}
            setRows={setRows}
            handleRemove={freeTextsService.remove}
        >
            {({ id, open, handleClose }) =>
                <EditDialog
                    id={id}
                    open={open}
                    handleClose={handleClose}
                    entityName={props.entityName.singular}
                    description={{
                        new: 'Um einen neuen ' + props.entityName.singular +
                            ' anzulegen, fülle bitte alle Pflichtfelder aus und speichere Deine Eingabe.',
                        edit: 'Um einen ' + props.entityName.singular +
                            ' zu bearbeiten, bearbeite die untenstehenden Felder und speichere Deine Eingabe.'
                    }}
                >
                    <FreeTextForm
                        freeText={rows?.find(it => it.id === id)}
                        keywords={keywords}
                        handleClose={handleClose}
                        updateRow={(data, method) => !!data && updateOrAddRow(
                            data,
                            rows,
                            setRows
                        )}
                        entityName={props.entityName}
                    />
                </EditDialog>
            }
        </DataGridWithActions>
    );
}

export default FreeTextsDataGrid;
