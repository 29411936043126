import { TextField } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import React, { useContext } from 'react';
import { updateEntities } from '../../../common/DataGrid/addOrUpdateRow';
import { OfflinePosition, Position } from '../../../entities/Position/Position';
import { ServicesContext } from '../../../graphql/contexts/ServicesProvider';
import { GlobalSnackbarContext } from '../../Snackbar/contexts/GlobalSnackbarProvider';
import { DigitalPositionCardContext } from '../contexts/DigitalPositionCardProvider';

function NumberInput(params: GridRenderCellParams) {
    // contexts
    const { setSnackbar } = useContext(GlobalSnackbarContext);
    const { positions, setPositions } = useContext(DigitalPositionCardContext);

    // services
    const { positionsService } = useContext(ServicesContext).services;

    // vars
    const position = params.row as OfflinePosition;

    // functions
    const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const newNumber = parseInt(event.target.value);
        if (!isNewNumberPossible(newNumber)) {
            event.preventDefault();
            setSnackbar({ open: true, severity: 'error', message: 'Diese Nummer ist bereits vergeben.' });
            return;
        }
        positionsService
            .update({ ...position, number: newNumber })
            .then(newPosition => !!newPosition && setPositions(updateEntities(
                { ...newPosition, offlineAvailable: position.offlineAvailable },
                positions))
            );
    };

    const isNewNumberPossible = (newNumber: number) => !positions.find(position => position.number === newNumber);

    return (
        <TextField
            type='number'
            onChange={onChange}
            value={position.number}
            fullWidth
        />
    );
}

export default NumberInput;
